import React from "react";
import cx from "clsx";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { useTextInputStyles } from "../TextInput/TextInput.styles";

export type DimensionValue = {
  length?: string;
  width?: string;
  height?: string;
};

export type FormItemDimensionsProps = {
  value?: DimensionValue;
  onChange?: (value: DimensionValue) => void;
} & Omit<JSX.IntrinsicElements["fieldset"], "onChange">;

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const defaultGetValue = (event: Event) => event.target.value;

const FormItemDimensions = ({
  value,
  onChange,
  ...props
}: FormItemDimensionsProps) => {
  const textInput = useTextInputStyles();
  const makeHandleChange = (
    key: keyof DimensionValue,
    getValue: (event: Event) => string = defaultGetValue
  ) => (event: Event) => {
    const nextValue = { [key]: getValue(event) };
    onChange?.(value ? { ...value, ...nextValue } : nextValue);
  };
  return (
    <fieldset
      {...props}
      className={cx(
        "MuiFormControl-root",
        textInput.formControl,
        props.className
      )}
    >
      <FormLabel component="legend">Dimensions Per Item</FormLabel>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <OutlinedInput
            inputProps={{
              "aria-label": "Length",
            }}
            classes={{
              root: cx(textInput.outlinedRoot),
              input: cx(textInput.outlinedInput),
            }}
            placeholder="Length"
            value={value?.length}
            onChange={makeHandleChange("length")}
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            inputProps={{
              "aria-label": "Width",
            }}
            classes={{
              root: cx(textInput.outlinedRoot),
              input: cx(textInput.outlinedInput),
            }}
            placeholder="Width"
            value={value?.width}
            onChange={makeHandleChange("width")}
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            inputProps={{
              "aria-label": "Height",
            }}
            classes={{
              root: cx(textInput.outlinedRoot),
              input: cx(textInput.outlinedInput),
            }}
            placeholder="Height"
            value={value?.height}
            onChange={makeHandleChange("height")}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default FormItemDimensions;
