export const PositiveInt = (value: string) => {
  let numberValue = Number(value);
  const result = {
    isValid: false,
    numberValue,
    error: "Accept integer > 0",
  };
  if (/^\d+$/.test(value) && numberValue > 0) {
    result.isValid = true;
    result.error = "";
  }
  return result;
};

export const Integer = (value: string) => {
  let numberValue = Number(value);
  const result = {
    isValid: false,
    numberValue,
    error: "Accept only ±integer without decimal",
  };
  if (/^-?\d+$/.test(value)) {
    result.isValid = true;
    result.error = "";
  }
  return result;
};

export const PositiveFloat = (value: string) => {
  let numberValue = Number(value);
  const result = {
    isValid: false,
    numberValue,
    error: "Accept only number > 0",
  };
  if (value === ".") {
    result.numberValue = 0;
    result.isValid = true;
    result.error = "";
  }
  if (/^(\.?\d+|\d+\.?\d*)$/.test(value) && numberValue > 0) {
    result.isValid = true;
    result.error = "";
  }
  return result;
};

export const Float = (value: string) => {
  let numberValue = Number(value);
  const result = {
    isValid: false,
    numberValue,
    error: "Accept only ±number (eg, 1024.5)",
  };
  if (value === "." || value === "-") {
    result.numberValue = 0;
    result.isValid = true;
    result.error = "";
  }
  if (/^(-?\.?\d+|-?\d+\.?\d*)$/.test(value)) {
    result.isValid = true;
    result.error = "";
  }
  return result;
};

export default {
  Integer,
  PositiveInt,
  Float,
  PositiveFloat,
};
