import React, { PropsWithChildren } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "../theme/color";

const useStyles = makeStyles(
  () => ({
    root: {
      display: "flex",
      alignItems: "flex-start",
      "&:nth-of-type(even)": {
        backgroundColor: "#F7F8F9",
      },
      "&$error": {
        backgroundColor: red["100"],
      },
    },
    error: {},
  }),
  { name: "MuiSummaryRow" }
);

export type SummaryRowProps = {
  error?: boolean;
} & JSX.IntrinsicElements["div"];

const SummaryRow = ({
  children,
  className,
  error,
  ...props
}: PropsWithChildren<SummaryRowProps>) => {
  const styles = useStyles();
  return (
    <div
      className={cx(styles.root, error && styles.error, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default SummaryRow;
