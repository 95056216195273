import React, { PropsWithChildren, ReactElement, useContext } from "react";
import useAutocomplete, {
  UseAutocompleteProps,
} from "@material-ui/lab/useAutocomplete";
import { ComboBoxContext, AnchorContext } from "./ComboBoxContext";
import { useComboBoxStyles } from "./comboBox.styles";
import {
  ComboAnchor,
  ComboFormControl,
  ComboLabel,
  ComboInput,
  ComboList,
  ComboGroup,
  ComboOption,
  ComboAddMore,
} from "./ComboBoxSubcomponents";

function ComboBox<
  T,
  Skippable extends boolean = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  children,
  renderOptions,
  renderGroupedOptions,
  useStyles = useComboBoxStyles,
  options,
  getOptionLabel,
  ...props
}: PropsWithChildren<
  Omit<
    UseAutocompleteProps<T, false, DisableClearable, FreeSolo>,
    "groupBy" | "multiple" | "options" | "getOptionLabel"
  > & { useStyles?: typeof useComboBoxStyles } & (
      | {
          options: Skippable extends true ? [null, ...T[]] : T[];
          getOptionLabel: (
            option: Skippable extends true ? T | null : T
          ) => string;
          groupBy: (option: T) => string;
          renderGroupedOptions: (
            groupedOptions: {
              key: number;
              index: number;
              group: string;
              options: Skippable extends true ? [null, ...T[]] : T[];
            }[]
          ) => ReactElement | null;
          renderOptions?: never;
        }
      | {
          options: Skippable extends true ? [null, ...T[]] : T[];
          getOptionLabel: (
            option: Skippable extends true ? T | null : T
          ) => string;
          groupBy?: never;
          renderGroupedOptions?: never;
          renderOptions: (
            options: Skippable extends true ? [null, ...T[]] : T[]
          ) => ReactElement | null;
        }
    )
>) {
  if (!getOptionLabel) {
    throw new Error('"getOptionLabel" is required');
  }
  const {
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    anchorEl,
    setAnchorEl,
    popupOpen,
  } = useAutocomplete({
    openOnFocus: true,
    // Make TypeScript happy
    options: options as T[],
    // Make TypeScript happy
    getOptionLabel: getOptionLabel as (option: T) => string,
    ...props,
    multiple: false,
    componentName: "ComboBox",
  });

  const comboAnchor = useContext(AnchorContext);
  return (
    <ComboBoxContext.Provider
      value={{
        anchorEl: comboAnchor ?? anchorEl,
        setAnchorEl,
        popupOpen,
        getRootProps,
        getInputProps,
        getInputLabelProps,
        getListboxProps,
        getOptionProps,
        useStyles,
      }}
    >
      {children}
      {"groupBy" in props
        ? /* @ts-ignore : this should be a fix from material-ui */
          renderGroupedOptions?.(groupedOptions)
        : /* @ts-ignore : this should be a fix from material-ui */
          renderOptions?.(groupedOptions)}
    </ComboBoxContext.Provider>
  );
}

ComboBox.Anchor = ComboAnchor;
ComboBox.FormControl = ComboFormControl;
ComboBox.Label = ComboLabel;
ComboBox.Input = ComboInput;
ComboBox.List = ComboList;
ComboBox.Group = ComboGroup;
ComboBox.Option = ComboOption;
ComboBox.AddMore = ComboAddMore;

export default ComboBox;
