import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import MuiButtonGroup, {
  ButtonGroupTypeMap,
  ButtonGroupProps as MuiButtonGroupProps,
} from "@material-ui/core/ButtonGroup";
import { red, green } from "../theme/color";

interface ExtraButtonGroupProps {
  state?: "error" | "success";
}

export type ButtonGroupProps = ExtraButtonGroupProps & MuiButtonGroupProps;

const useStyles = makeStyles(
  (theme) => ({
    error: {
      "&:not(.Por-paper) .MuiButton-root": {
        color: `${theme.palette.error.main} !important`,
        backgroundColor: "transparent !important",
        borderColor: `${theme.palette.error.main} !important`,
        "&:hover": {
          backgroundColor: `${red[100]} !important`,
        },
      },
      "&:is(.Por-paper) .MuiButton-root": {
        color: `${theme.palette.error.main} !important`,
        "&:hover": {
          backgroundColor: `${red[100]} !important`,
        },
        "&.Por-selected, .Ptx-selected": {
          borderColor: `${theme.palette.error.main} !important`,
          backgroundColor: `${red[100]} !important`,
        },
      },
    },
    success: {
      "&:not(.Por-paper) .MuiButton-root": {
        color: `${theme.palette.success.main} !important`,
        borderColor: `${theme.palette.success.main} !important`,
        backgroundColor: "transparent !important",
        "&:hover": {
          backgroundColor: `${green[100]} !important`,
        },
      },
      "&:is(.Por-paper) .MuiButton-root": {
        color: `${theme.palette.success.main} !important`,
        "&:hover": {
          backgroundColor: `${green[100]} !important`,
        },
        "&.Por-selected, .Ptx-selected": {
          borderColor: `${theme.palette.success.main} !important`,
          backgroundColor: `${green[100]} !important`,
        },
      },
    },
  }),
  { name: "PorButonGroup" }
);

const Button = React.forwardRef(function Button(
  { children, disabled, state, ...props },
  ref
) {
  const classes = useStyles(props);
  return (
    <MuiButtonGroup
      ref={ref}
      {...props}
      classes={{
        ...props.classes,
        root: cx(state && classes[state], props.classes?.root),
      }}
    >
      {children}
    </MuiButtonGroup>
  );
}) as OverridableComponent<ButtonGroupTypeMap<ExtraButtonGroupProps>>;

export default Button;
