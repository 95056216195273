import * as React from "react";

const SvgForwardersPref = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={53} height={53} {...props}>
    <g transform="translate(-14410 -8728)">
      <circle
        data-name="Ellipse 256"
        cx={26.5}
        cy={26.5}
        r={26.5}
        transform="translate(14410 8728)"
        fill="#e9eefe"
      />
      <path
        data-name="Icon material-playlist-add-check"
        d="M14439 8752h-18v3h18Zm0-6h-18v3h18Zm-18 15h12v-3h-12Zm29.25-6.75 2.25 2.25-10.485 10.5-6.765-6.75 2.25-2.25 4.515 4.5Z"
        fill="#265df1"
      />
    </g>
  </svg>
);

export default SvgForwardersPref;
