import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  MuiThemeProvider,
  StylesProvider,
  createGenerateClassName,
  Theme,
} from "@material-ui/core/styles";
import { StylesOptions } from "@material-ui/styles/StylesProvider";
import { GenerateClassNameOptions } from "@material-ui/styles/createGenerateClassName";
import { lightTheme } from "./lightTheme";

export type PortexThemeProps = {
  children: React.ReactNode;
  theme?: Theme;
  generateClassNameOptions?: GenerateClassNameOptions;
} & StylesOptions;

const generateClassName = (options: GenerateClassNameOptions) =>
  createGenerateClassName(options);

export const PortexTheme = ({
  children,
  theme,
  generateClassNameOptions,
  ...props
}: PortexThemeProps) => (
  <StylesProvider
    {...(generateClassNameOptions && {
      generateClassName: generateClassName(generateClassNameOptions),
    })}
    {...props}
  >
    <MuiThemeProvider theme={theme || lightTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  </StylesProvider>
);
