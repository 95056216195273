import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export type UseSelectableRowOptions<T> = {
  selectedItems?: T[];
  disabledItems?: T[];
  displayedItems?: T[];
  loose?: boolean;
};

export const useSelectableRow = <T>(
  items: T[],
  identifier: (item: T, selected: T) => boolean,
  options: Partial<UseSelectableRowOptions<T>> = {}
) => {
  const [selectedItems, setSelectedItems] = useState<T[]>(
    options?.selectedItems ?? []
  );
  useDeepCompareEffect(() => {
    if (!options.loose) {
      setSelectedItems(options?.selectedItems ?? []);
    }
  }, [options?.selectedItems ?? []]);

  const isAllSelected = options.displayedItems?.length
    ? options.displayedItems.every((item) =>
        selectedItems.find((selected) => identifier(item, selected))
      )
    : items.length === selectedItems.length;
  const displayedItemsHasSelected = options.displayedItems?.some((displayed) =>
    selectedItems.find((selected) => identifier(displayed, selected))
  );

  return {
    isAllSelected,
    selectedItems,
    setSelectedItems,
    unselectedItems: items.filter((item) => {
      return !selectedItems.find((selected) => identifier(item, selected));
    }),
    onToggleAll: (deselected?: boolean) => {
      const shouldDeselect =
        deselected ?? displayedItemsHasSelected ?? isAllSelected;

      if (shouldDeselect) {
        setSelectedItems(
          options.displayedItems?.length
            ? selectedItems.filter(
                (item) =>
                  !options.displayedItems
                    ?.filter(
                      (item) =>
                        !options.disabledItems?.find((disabled) =>
                          identifier(item, disabled)
                        )
                    )
                    ?.find((displayed) => identifier(item, displayed))
              )
            : []
        );
      } else {
        setSelectedItems(
          options.displayedItems
            ? [
                ...options.displayedItems.filter(
                  (item) =>
                    !options.disabledItems?.find((disabled) =>
                      identifier(item, disabled)
                    )
                ),
                ...selectedItems.filter(
                  (item) =>
                    !options.displayedItems?.find((displayed) =>
                      identifier(item, displayed)
                    )
                ),
              ]
            : items
        );
      }
    },
    onToggle: (item: T) => {
      setSelectedItems((prevState) => {
        const newItems = prevState.filter(
          (selected) => !identifier(item, selected)
        );
        if (newItems.length === prevState.length) {
          newItems.push(item);
        }
        return newItems;
      });
    },
    checkSelected: (item: T) =>
      !!selectedItems.find((selected) => identifier(item, selected)),
    checkDisabled: (item: T) =>
      !!options?.disabledItems?.find((selected) => identifier(item, selected)),
  };
};
