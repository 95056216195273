import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17.682 6.168"
      fontSize="1.5rem"
      color="#fff"
      {...props}
    >
      <path
        d="M12.13 0v3.906H0V0zm1.645.411a3.349 3.349 0 012.249 2.673 1.254 1.254 0 011.324.835h-4.807V1.85h.822a.412.412 0 00.039-.822h-.861V.411zm.822 1.234h-.411v1.234h1.131a1.678 1.678 0 00-.717-1.234zm-2.069 2.673v.013h4.915a2.905 2.905 0 01.019.386c.012.421.222.424.218.63h-.848a1.026 1.026 0 00-2.011 0H11.9a.99.99 0 00-1-.822 1.038 1.038 0 00-1.009.822H9.64a1.1 1.1 0 00-1-.822 1.038 1.038 0 00-1.009.822H4.908a1.053 1.053 0 00-1-.822 1.038 1.038 0 00-1.009.822h-.252a1.075 1.075 0 00-1-.822 1.038 1.038 0 00-1.009.822h-.63S0 4.729 0 4.318zm3.3.617a.617.617 0 11-.617.617.617.617 0 01.62-.618zm-4.934 0a.617.617 0 11-.617.617.617.617 0 01.623-.618zm-2.262 0a.617.617 0 11-.617.617.617.617 0 01.62-.618zm-4.729 0a.617.617 0 11-.617.617.617.617 0 01.62-.618zm-2.262 0a.617.617 0 11-.617.617.617.617 0 01.62-.618z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
