import React, { ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box, { BoxProps } from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Done from "@material-ui/icons/Done";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:first-child)": {
      marginLeft: 16,
    },
  },
  circular: {
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid",
    borderColor: palette.error.main,
  },
  done: {
    backgroundColor: palette.success.main,
    border: "unset",
  },
}));

export type TodoProps = {
  done?: boolean;
  title?: ReactNode;
  subtitle?: ReactNode;
} & BoxProps;

const Todo = ({ done, title, subtitle, className, ...props }: TodoProps) => {
  const styles = useStyles();
  return (
    <Box className={cx(styles.root, className)} {...props}>
      <div className={cx(styles.circular, done && styles.done)}>
        {done && <Done fontSize={"large"} htmlColor={"#fff"} />}
      </div>
      {(title || subtitle) && (
        <div>
          {title && (
            <Typography gutterBottom>
              <b>{title}</b>
            </Typography>
          )}
          {subtitle && <Typography variant={"body2"}>{subtitle}</Typography>}
        </div>
      )}
    </Box>
  );
};

export default Todo;
