import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      viewBox="0 0 36 36"
      color="#265df1"
      {...props}
    >
      <g>
        <g>
          <circle cx={18} cy={18} r={18} fill="currentColor" />
        </g>
        <g fill="#fff" transform="translate(-8.581 -7.581)">
          <path d="M16.759 20.586h1.2v2.551h.848v-1.189h1.2v-3.116h.857v3.116h1.586v1.456h-.634v2.233h17.613l-2.586 4.047H17.452a2.587 2.587 0 01-2.586-2.587v-1.46h1.894z" />
          <path d="M24.764 21.146h3.038v1.624h-3.038z" />
          <path d="M28.363 21.146h3.043v1.624h-3.043z" />
          <path d="M31.963 21.146h3.042v1.624h-3.042z" />
          <path d="M24.764 23.444h3.038v1.624h-3.038z" />
          <path d="M28.363 23.444h3.043v1.624h-3.043z" />
          <path d="M31.963 23.444h3.042v1.624h-3.042z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
