import React, { forwardRef, useState, PropsWithChildren } from "react";
import cx from "clsx";
import ListSubheader, {
  ListSubheaderProps,
} from "@material-ui/core/ListSubheader";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormLabel, { FormLabelProps } from "@material-ui/core/FormLabel";
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import Popper from "@material-ui/core/Popper";
import Link, { LinkProps } from "@material-ui/core/Link";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { AnchorContext, useComboBoxCtx } from "./ComboBoxContext";
import { useComboBoxStyles } from "./comboBox.styles";
import { CustomColor } from "../constant";

export const ComboFormControl = (props: FormControlProps) => {
  const { getRootProps, setAnchorEl, useStyles } = useComboBoxCtx();
  const classes = useStyles();
  return (
    <FormControl
      {...props}
      ref={setAnchorEl}
      className={cx(classes.comboBox, props.className)}
      {...getRootProps()}
    />
  );
};

export const ComboLabel = (props: FormLabelProps) => {
  const { getInputLabelProps } = useComboBoxCtx();
  return <FormLabel {...props} {...getInputLabelProps()} />;
};

export const ComboAnchor = ({
  children,
  className,
  ...props
}: PropsWithChildren<JSX.IntrinsicElements["div"]>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const classes = useComboBoxStyles();
  return (
    <div className={cx(classes.anchor, className)} {...props} ref={setAnchorEl}>
      <AnchorContext.Provider value={anchorEl}>
        {children}
      </AnchorContext.Provider>
    </div>
  );
};

const ClearIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </SvgIcon>
);

export const ComboInput = forwardRef<
  HTMLInputElement,
  InputBaseProps & { onClear?: IconButtonProps["onClick"] }
>(({ onClear, ...props }, ref) => {
  const { getInputProps, useStyles } = useComboBoxCtx();
  const classes = useStyles();
  const inputProps = { ...props.inputProps, ...getInputProps() };
  return (
    <InputBase
      ref={ref}
      startAdornment={
        <div
          className={cx(classes.dot, inputProps?.value && classes.dotCircular)}
        />
      }
      {...(onClear && {
        endAdornment: (
          <IconButton
            size="small"
            className={classes.clear}
            onClick={(event) => {
              event.stopPropagation();
              onClear(event);
            }}
          >
            <ClearIcon />
          </IconButton>
        ),
      })}
      {...props}
      className={cx(classes.inputBase, props?.className)}
      classes={{
        ...props?.classes,
        input: cx(classes.input, props?.classes?.input),
      }}
      inputProps={inputProps}
    />
  );
});

export const ComboList = ({ children }: PropsWithChildren<{}>) => {
  const { popupOpen, anchorEl, getListboxProps, useStyles } = useComboBoxCtx();
  const classes = useStyles();
  if (popupOpen && anchorEl) {
    return (
      <Popper
        open
        anchorEl={anchorEl}
        role="presentation"
        className={classes.popper}
        style={{ width: anchorEl?.clientWidth }}
      >
        <ul className={cx(classes.listBox)} {...getListboxProps()}>
          {children}
        </ul>
      </Popper>
    );
  }
  return null;
};

export const ComboGroup = ({
  children,
  label,
  ListSubheaderProps,
  ...props
}: PropsWithChildren<
  {
    label: string;
    ListSubheaderProps?: ListSubheaderProps;
  } & JSX.IntrinsicElements["li"]
>) => {
  const { useStyles } = useComboBoxCtx();
  const classes = useStyles();
  return (
    <li {...props}>
      {/* @ts-ignore not sure why ts complain */}
      <ListSubheader
        component="div"
        {...ListSubheaderProps}
        className={cx(classes.groupLabel, ListSubheaderProps?.className)}
      >
        {label}
      </ListSubheader>
      <ul className={classes.groupUl}>{children}</ul>
    </li>
  );
};

export const ComboOption = ({
  children,
  index,
  option,
}: PropsWithChildren<{ option: any; index: number }>) => {
  const { getOptionProps, useStyles } = useComboBoxCtx();
  const classes = useStyles();
  const optionProps = getOptionProps({ option, index });
  return (
    <li className={classes.option} {...optionProps}>
      {children}
    </li>
  );
};

export const ComboAddMore = ({
  children,
  palette,
  ...props
}: PropsWithChildren<LinkProps<"button"> & { palette?: CustomColor }>) => {
  const classes = useComboBoxStyles({ palette });
  return (
    <Link
      variant="body1"
      underline="none"
      color="inherit"
      {...props}
      className={cx(classes.addMore, classes.addMorePalette, props.className)}
      component="button"
    >
      <div className={cx(classes.dot)} />
      {children}
    </Link>
  );
};
