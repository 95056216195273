import React, { ReactElement, ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { grey, portexColor } from "../theme/color";
import { CustomColor } from "../constant";

export type StatusProps = {
  palette?: CustomColor;
  light?: boolean;
  rounded?: boolean;
  uppercase?: boolean;
  startIcon?: ReactElement;
  leftText?: ReactNode;
  rightText?: ReactNode;
} & JSX.IntrinsicElements["div"];

const useStyles = makeStyles(
  () => ({
    root: {
      display: "inline-flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      "&$filled": {
        "& $startIcon": {
          marginLeft: -1,
          marginRight: 2,
        },
      },
      "&$light": {
        "& $startIcon": {
          marginLeft: -2,
          marginRight: 4,
        },
      },
    },
    filled: (props: StatusProps) => {
      let color = "#2D425A";

      if (props.light && props.palette) {
        if (props.palette === "grey") {
          color = portexColor.grey800;
        } else if (props.palette === "yellow") {
          color = "#D79700";
        } else {
          color = (portexColor as any)[`${props.palette}500`];
        }
      }

      if (!props.light && props.palette) {
        color = "#fff";
      }

      let backgroundColor: string = grey["50"];

      if (props.light && props.palette) {
        backgroundColor = (portexColor as any)[`${props.palette}100`];

        if (props.palette === "grey") {
          backgroundColor = portexColor.grey200;
        }
      }

      if (!props.light && props.palette) {
        backgroundColor = (portexColor as any)[`${props.palette}500`];
      }
      return {
        backgroundColor,
        color,
        borderRadius: 4,
        padding: "2px 4px",
        fontSize: "0.75rem",
        fontWeight: "bold",
        "& svg": {
          fontSize: 17,
        },
      };
    },
    rounded: () => ({
      fontSize: 10,
      fontWeight: "bold",
      borderRadius: 20,
      padding: "4px 8px",
      "& svg": {
        fontSize: 16,
      },
    }),
    startIcon: {},
    light: {},
    uppercase: {
      textTransform: "uppercase",
    },
    text: (props) => ({
      display: "inline-block",
      backgroundColor: props.light
        ? "rgba(255,255,255,0.36)"
        : "rgba(0,0,0,0.2)",
    }),
    leftText: (props) => ({
      borderTopLeftRadius: props.rounded ? 20 : 4,
      borderBottomLeftRadius: props.rounded ? 20 : 4,
      ...(props.rounded
        ? {
            margin: "-4px 4px -4px -8px",
            padding: "4px 6px 4px 8px",
          }
        : {
            margin: "-2px 4px -2px -4px",
            padding: "2px 6px 2px 4px",
          }),
    }),
    rightText: (props) => ({
      borderTopRightRadius: props.rounded ? 20 : 4,
      borderBottomRightRadius: props.rounded ? 20 : 4,
      ...(props.rounded
        ? {
            margin: "-4px -8px -4px 4px",
            padding: "4px 8px 4px 6px",
          }
        : {
            margin: "-2px -4px -2px 4px",
            padding: "2px 4px 2px 6px",
          }),
    }),
  }),
  { name: "Status" }
);

const Status = ({
  children,
  className,
  rounded,
  startIcon,
  uppercase = true,
  light = false,
  leftText,
  rightText,
  ...props
}: StatusProps) => {
  const classes = useStyles({ light, rounded, leftText, rightText, ...props });
  return (
    <div
      className={cx(
        classes.root,
        classes.filled,
        rounded && classes.rounded,
        uppercase && classes.uppercase,
        className
      )}
      {...props}
    >
      {leftText && (
        <span className={cx(classes.text, classes.leftText)}>{leftText}</span>
      )}
      {startIcon &&
        React.cloneElement(startIcon, {
          className: cx(classes.startIcon, startIcon.props.className),
        })}{" "}
      {children}
      {rightText && (
        <span className={cx(classes.text, classes.rightText)}>{rightText}</span>
      )}
    </div>
  );
};

export default Status;
