import React, { ReactNode, ReactNodeArray } from "react";
import cx from "clsx";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import Popper from "@material-ui/core/Popper";
import useAutocomplete, {
  UseAutocompleteProps,
} from "@material-ui/lab/useAutocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "../theme/color";

const SPACE = 24;
const useStyles = makeStyles(({ palette, breakpoints }) => ({
  comboBox: {
    padding: "0 1rem",
    position: "relative",
    "& + $comboBox": {
      marginTop: SPACE,
      "&:after": {
        content: '""',
        display: "block",
        height: SPACE,
        border: "1px dashed",
        borderColor: palette.grey["500"],
        position: "absolute",
        left: `calc(1rem + 3px)`,
        top: 0,
        transform: "translateY(-100%)",
      },
      "& $dot": {
        borderRadius: 0,
      },
    },
  },
  inputBase: {
    display: "flex",
  },
  input: {
    padding: 0,
    height: "auto",
    lineHeight: "1.25em",
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: palette.grey["900"],
    marginRight: 12,
    flexShrink: 0,
  },
  listBox: {
    margin: "4px 0",
    padding: 0,
    paddingBottom: 8,
    zIndex: 1,
    top: 8,
    left: 0,
    right: 0,
    listStyle: "none",
    backgroundColor: palette.background.paper,
    overflow: "auto",
    maxHeight: "40vh",
    border: "1px solid",
    borderColor: palette.grey["200"],
    borderRadius: 4,
  },
  option: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    paddingTop: 6,
    boxSizing: "border-box",
    outline: "0",
    WebkitTapHighlightColor: "transparent",
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    "& svg": {
      marginLeft: -4,
      marginRight: 8,
    },
    '&[aria-selected="true"]': {
      backgroundColor: blue["100"],
      fontWeight: "bold",
      color: blue["500"],
      "& *": {
        color: blue["300"],
      },
    },
    '&[data-focus="true"]:not([aria-selected="true"])': {
      backgroundColor: palette.action.hover,
    },
    "&:active": {
      fontWeight: "bold",
    },
    '&[aria-disabled="true"]': {
      opacity: palette.action.disabledOpacity,
      pointerEvents: "none",
    },
  },
  groupLabel: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: palette.grey["500"],
    lineHeight: "2.5rem",
    backgroundColor: palette.background.paper,
    letterSpacing: "0.5px",
  },
  groupUl: {
    padding: 0,
  },
  popper: {
    zIndex: 1200,
  },
}));

export type LocationPickerProps<T> = {
  anchorEl?: HTMLElement | null;
  placeholder: string;
  renderOption: (option: T) => ReactNode;
  renderGroup?: (group: string) => ReactNode;
  InputBaseProps?: InputBaseProps;
} & Omit<UseAutocompleteProps<T, false, false, false>, "multiple" | "freeSolo">;

type GroupedOption<T> = {
  index: number;
  key: string;
  group: string;
  options: T[];
};

export const LocationPicker = <T,>({
  anchorEl: anchorElProp,
  placeholder,
  renderOption = () => "",
  renderGroup: renderGroupProp = (g) => g,
  InputBaseProps,
  ...useAutocompleteProps
}: LocationPickerProps<T>) => {
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    anchorEl,
    setAnchorEl,
    popupOpen,
  } = useAutocomplete<T, false, false, false>({
    ...useAutocompleteProps,
    multiple: false,
    freeSolo: false,
    componentName: "LocationPicker",
  });
  const { groupBy } = useAutocompleteProps;
  const classes = useStyles();
  const renderGroup = (params: {
    key: string;
    group: string;
    children: ReactNodeArray;
  }) => (
    <li key={params.key}>
      <ListSubheader className={classes.groupLabel} component="div">
        {renderGroupProp(params.group)}
      </ListSubheader>
      <ul className={classes.groupUl}>{params.children}</ul>
    </li>
  );
  const renderListOption = (option: T, index: number) => {
    const optionProps = getOptionProps({ option, index });

    return (
      <li className={classes.option} {...optionProps}>
        {renderOption(option)}
      </li>
    );
  };
  return (
    <>
      <div className={classes.comboBox} ref={setAnchorEl} {...getRootProps()}>
        <InputBase
          {...InputBaseProps}
          className={cx(classes.inputBase, InputBaseProps?.className)}
          classes={{
            ...InputBaseProps?.classes,
            input: cx(classes.input, InputBaseProps?.classes?.input),
          }}
          placeholder={placeholder}
          startAdornment={<div className={cx(classes.dot)} />}
          inputProps={getInputProps()}
        />
      </div>
      {popupOpen && (anchorElProp || anchorEl) && (
        <Popper
          open
          anchorEl={anchorElProp || anchorEl}
          role="presentation"
          className={classes.popper}
          style={{ width: (anchorElProp || anchorEl)?.clientWidth }}
        >
          {groupedOptions.length > 0 && (
            <ul className={cx(classes.listBox)} {...getListboxProps()}>
              {groupedOptions.map((option, index) => {
                if (groupBy) {
                  const groupedOption = (option as unknown) as GroupedOption<T>;
                  return renderGroup({
                    key: groupedOption.key,
                    group: groupedOption.group,
                    children: groupedOption.options.map((option2, index2) =>
                      renderListOption(option2, groupedOption.index + index2)
                    ),
                  });
                }
                return renderListOption(option, index);
              })}
            </ul>
          )}
        </Popper>
      )}
    </>
  );
};
