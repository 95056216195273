import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box, { BoxProps } from "@material-ui/core/Box";
import Paper, { PaperProps } from "@material-ui/core/Paper";

const useStyles = makeStyles(
  ({ palette }) => ({
    background: {
      height: "100vh",
      padding: "2vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    paper: {
      display: "flex",
      minHeight: 0,
      flexDirection: "column",
      width: 700,
      maxWidth: "100%",
      margin: "0 auto",
    },
    head: {
      borderBottom: "1px solid",
      borderBottomColor: palette.grey["300"],
      flexShrink: 0,
    },
    scrollable: {
      flexGrow: 1,
      minHeight: 0,
      overflow: "auto",
    },
    lightContent: {
      backgroundColor: palette.grey[100],
    },
    footer: {
      borderTop: "1px solid",
      borderTopColor: palette.grey["300"],
      flexShrink: 0,
      "& button": {
        minWidth: 160,
      },
    },
    flex: {
      display: "flex",
      alignItems: "center",
      gap: 12,
    },
  }),
  { name: "AddInDialog" }
);

const Background = ({ className, ...props }: JSX.IntrinsicElements["div"]) => {
  const styles = useStyles();
  return <div className={cx(styles.background, className)} {...props} />;
};

const Container = ({ className, ...props }: PaperProps) => {
  const styles = useStyles();
  return (
    <Paper
      className={cx("Por-outlined-light", styles.paper, className)}
      {...props}
    />
  );
};

const Head = ({ className, ...props }: JSX.IntrinsicElements["div"]) => {
  const styles = useStyles();
  return <div className={cx(styles.head, className)} {...props} />;
};

const Content = ({
  className,
  scrollable,
  light,
  ...props
}: {
  scrollable?: boolean;
  light?: boolean;
} & JSX.IntrinsicElements["div"]) => {
  const styles = useStyles();
  return (
    <div
      className={cx(
        scrollable && styles.scrollable,
        light && styles.lightContent,
        className
      )}
      {...props}
    />
  );
};

const Footer = ({ className, ...props }: JSX.IntrinsicElements["div"]) => {
  const styles = useStyles();
  return <div className={cx(styles.footer, className)} {...props} />;
};

const Flex = ({ className, ...props }: BoxProps) => {
  const styles = useStyles();
  return (
    <Box
      className={cx(styles.flex, className)}
      py={1.5}
      px={{ xs: 2, md: 3 }}
      {...props}
    />
  );
};

export default {
  Background,
  Container,
  Head,
  Content,
  Footer,
  Flex,
};
