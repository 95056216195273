import React, { ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      "&:last-child": {
        "& $body": {
          borderLeftColor: "rgba(0,0,0,0)",
        },
      },
    },
    head: {
      display: "flex",
    },
    headLabel: {
      flex: 1,
      lineHeight: 2.2,
    },
    bullet: {
      flexShrink: 0,
      minWidth: 48,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: palette.grey["500"],
    },
    body: {
      marginLeft: 23,
      paddingLeft: 23,
      paddingTop: "0.25rem",
      paddingBottom: "0.5rem",
      borderLeft: "2px solid",
      borderLeftColor: palette.grey["500"],
    },
    metadata: {
      color: palette.grey["500"],
      marginTop: "0.5rem",
    },
  }),
  { name: "Activity" }
);

export type ActivityProps = JSX.IntrinsicElements["div"];

const Activity = ({ children, className, ...props }: ActivityProps) => {
  const styles = useStyles();
  return (
    <div className={cx(styles.root, className)} {...props}>
      {children}
    </div>
  );
};

export type ActivityHeadProps = {
  bullet?: ReactNode;
} & JSX.IntrinsicElements["div"];
const Head = ({ children, className, bullet, ...props }: ActivityHeadProps) => {
  const styles = useStyles();
  return (
    <div className={styles.head} {...props}>
      <div className={styles.bullet}>
        {bullet ?? <div className={styles.dot} />}
      </div>
      <Typography className={styles.headLabel}>
        <b>{children}</b>
      </Typography>
    </div>
  );
};

export type ActivityBodyProps = JSX.IntrinsicElements["div"];
const Body = ({ children, className, ...props }: ActivityBodyProps) => {
  const styles = useStyles();
  return (
    <div className={styles.body} {...props}>
      {children}
    </div>
  );
};

const Metadata = ({ className, ...props }: TypographyProps) => {
  const styles = useStyles();
  return (
    <Typography
      className={cx(styles.metadata, className)}
      variant={"body2"}
      {...props}
    />
  );
};

Activity.Head = Head;
Activity.Body = Body;
Activity.Metadata = Metadata;

export default Activity;
