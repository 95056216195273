import * as React from "react";

const SvgFastestQuoteChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={53} height={53} {...props}>
    <g transform="translate(-14573 -8658)">
      <circle
        data-name="Ellipse 260"
        cx={26.5}
        cy={26.5}
        r={26.5}
        transform="translate(14573 8658)"
        fill="#265df1"
      />
      <path
        data-name="Icon simple-codeship"
        d="M14599.527 8666.976c-1.379 0-3.4.575-3.4 1.081v3a18.488 18.488 0 0 1 3.431-.381 20.411 20.411 0 0 1 3.437.369v-2.969c0-.521-2.093-1.1-3.469-1.1Zm.031 5.373c-2.9 0-7.19 1.187-7.19 1.693v3.682a26.661 26.661 0 0 1 7.122-3.51 24.706 24.706 0 0 1 7.394 3.54v-3.713c.004-.525-4.431-1.692-7.326-1.692Zm.035 3.664a18.125 18.125 0 0 0-3.141 1.263 24.948 24.948 0 0 0-5.942 3.921 16.293 16.293 0 0 1 3.765 6.7l.052.16.057.052-.074-.052a8.571 8.571 0 0 0-5.635-2.356 8.757 8.757 0 0 0-5.949 2.6 18.229 18.229 0 0 0 .749 2.125v.023l.035.067a17.7 17.7 0 0 0 16.588 10.503 17.719 17.719 0 0 0 15.79-11.15c.139-.349.261-.718.386-1.067-2.392-1.779-6.146-3.992-7.591-3.713-1.1.211-2.392 1.8-4.049 3.384a17.132 17.132 0 0 1 4.018-7.255 22.1 22.1 0 0 0-1.763-1.448v-.017a23.322 23.322 0 0 0-7.295-3.737Zm-.014 1.273.868 4.327-.209 3.788-.628 5.616v.026l-.071-.009c-.009 0-.021-.011-.031-.014v-.038l-.7-5.6-.173-3.765Zm1.277 13.939.03.006-.2.079c.057-.033.113-.051.172-.085Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgFastestQuoteChecked;
