import { makeStyles } from "@material-ui/core/styles";

/**
 * @deprecated no longer need from >= v0.3.30
 */
export const useSidebarItemStyles = makeStyles(() => ({
  root: {
    borderRadius: 4,
    padding: "8px 16px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.05)",
    },
    "&.Ptx-selected": {
      backgroundColor: "rgba(255,255,255,0.08)",
    },
    "& + $root": {
      marginTop: 8,
    },
    "& *": {
      fontWeight: "bold",
    },
    "& svg:first-child": {
      marginRight: 20,
    },
  },
}));
