import * as React from "react";

const SvgServiceExpressChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
  >
    <g id="express-checked" transform="translate(14334 -18280)">
      <circle
        id="Ellipse_284"
        data-name="Ellipse 284"
        cx="26.5"
        cy="26.5"
        r="26.5"
        transform="translate(-14334 18280)"
        fill="#265df1"
      />
      <path
        id="Icon_ionic-ios-airplane"
        data-name="Icon ionic-ios-airplane"
        d="M24.183,13.936c-.2,0-.864.006-1.051.018l-3.789.1a.108.108,0,0,1-.1-.053l-5.68-8.506a.938.938,0,0,0-.788-.432H11.392c-.327,0-.438.327-.321.631L14,14.03a.114.114,0,0,1-.105.158l-7.146.105a.47.47,0,0,1-.385-.181L4.2,11.484a.927.927,0,0,0-.736-.356H2.483a.233.233,0,0,0-.222.309l1.162,4.011a.951.951,0,0,1,0,.695L2.261,20.154a.233.233,0,0,0,.222.309h.975a.944.944,0,0,0,.736-.356l2.2-2.668a.478.478,0,0,1,.385-.181l7.1.158a.116.116,0,0,1,.105.158l-2.925,8.342c-.117.3-.006.63.321.63H12.77a.931.931,0,0,0,.788-.432l5.686-8.5a.115.115,0,0,1,.1-.053l3.789.1c.193.012.852.018,1.051.018,2.586,0,4.221-.835,4.221-1.868S26.775,13.936,24.183,13.936Z"
        transform="translate(-14322.826 18290.695)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgServiceExpressChecked;
