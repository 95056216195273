import React, { ReactElement } from "react";
import cx from "clsx";
import Box from "@material-ui/core/Box";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, Theme, fade } from "@material-ui/core/styles";
import { CustomColor } from "../constant";
import { portexColor } from "../theme/color";

const useStyles = makeStyles<Theme, LaneRequestProps>(
  () => ({
    palette: ({ palette }) => {
      const color = (portexColor as any)[`${palette}500`];
      if (!color) return {};
      return {
        backgroundColor: (portexColor as any)[`${palette}500`],
        "&:hover": {
          backgroundColor: fade((portexColor as any)[`${palette}500`], 0.8),
        },
        "&:disabled": {
          backgroundColor: (portexColor as any)[`${palette}100`],
          color: fade((portexColor as any)[`${palette}500`], 0.72),
        },
      };
    },
  }),
  { name: "LaneRequest" }
);

export type LaneRequestProps = {
  palette?: CustomColor;
  icon?: ReactElement;
} & ButtonProps;

export const LaneRequest = ({
  palette,
  className,
  icon,
  ...props
}: LaneRequestProps) => {
  const classes = useStyles({ palette });
  return (
    <Button
      className={cx(classes.palette, "justify-start", className)}
      fullWidth
      size="large"
      color="primary"
      variant="contained"
      {...(icon && {
        startIcon: (
          <Box height={32} width={32}>
            {icon}
          </Box>
        ),
      })}
      {...props}
    />
  );
};
