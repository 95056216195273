import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.946}
      height={17.137}
      viewBox="0 0 20.946 17.137"
      {...props}
    >
      <path
        d="M1.9 0h17.142a1.9 1.9 0 011.9 1.9v13.333a1.9 1.9 0 01-1.9 1.9H1.9a1.9 1.9 0 01-1.9-1.9V1.9A1.9 1.9 0 011.9 0zm0 1.9v13.333h17.142V1.9zm3.812 11.429h1.9V7.617h-1.9zm5.712 0h-1.9V3.808h1.9zm1.9 0h1.9V6.665h-1.9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
