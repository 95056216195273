import React, { PropsWithChildren, ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(
  ({ palette }) => ({
    uploader: {
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${palette.grey[400].replace(
        "#",
        "%23"
      )}' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      backgroundColor: palette.grey[100],
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 4,
      padding: "2rem 1rem",
      "& svg": {
        color: palette.grey[500],
      },
    },
    file: {
      position: "relative",
      display: "flex",
      gap: "1rem",
      padding: "0.75rem 0.875rem",
      alignItems: "center",
      border: "1px solid",
      borderColor: palette.grey[300],
      borderRadius: 4,
      backgroundColor: palette.background.paper,
    },
    progress: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      backgroundColor: palette.background.paper,
    },
  }),
  { name: "MuiUploader" }
);

const Uploader = ({
  children,
  ...props
}: PropsWithChildren<JSX.IntrinsicElements["div"]>) => {
  const styles = useStyles();
  return (
    <div {...props} className={cx(styles.uploader, props.className)}>
      {children}
    </div>
  );
};

export type FileProps = {
  icon?: ReactNode;
  percentUpload?: number;
  disabled?: boolean;
  name: string;
  onRemove?: () => void;
} & JSX.IntrinsicElements["div"];
const File = ({
  icon = null,
  name,
  percentUpload,
  disabled,
  onRemove,
  ...props
}: FileProps) => {
  const styles = useStyles();
  return (
    <div {...props} className={cx(styles.file, props.className)}>
      {!!percentUpload && (
        <LinearProgress
          variant="determinate"
          className={styles.progress}
          value={percentUpload}
        />
      )}
      {icon}
      <div>
        <Typography>
          <b>{name}</b>
        </Typography>
        {onRemove && (
          <Link
            underline={disabled ? "none" : "hover"}
            component="button"
            variant="body2"
            color={disabled ? "textSecondary" : "error"}
            {...(!disabled && { onClick: onRemove })}
          >
            Remove
          </Link>
        )}
      </div>
    </div>
  );
};

Uploader.File = File;

export default Uploader;
