import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      color="#0045ff"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <path
        d="M500,1000a500.17,500.17,0,0,1-147.59-22.141v-255.1a268.192,268.192,0,1,0,0-439.5V234.938H237.953V925.912A503.357,503.357,0,0,1,65.432,747.461,497.718,497.718,0,0,1,0,500,503.685,503.685,0,0,1,10.158,399.231,497.263,497.263,0,0,1,85.392,220.446,501.445,501.445,0,0,1,305.377,39.293a497.441,497.441,0,0,1,93.855-29.135,504.879,504.879,0,0,1,201.535,0A497.286,497.286,0,0,1,779.555,85.392,501.466,501.466,0,0,1,960.708,305.377a497.455,497.455,0,0,1,29.134,93.854,504.87,504.87,0,0,1,0,201.534,497.27,497.27,0,0,1-75.234,178.788A501.448,501.448,0,0,1,694.623,960.707a497.437,497.437,0,0,1-93.855,29.135A503.7,503.7,0,0,1,500,1000Zm6.023-349.4A147.12,147.12,0,1,1,563.472,639,146.626,146.626,0,0,1,506.023,650.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
