import React, { ReactNode } from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Edit from "@material-ui/icons/Edit";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      padding: "12px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid",
      borderBottomColor: palette.grey["200"],
    },
  }),
  { name: "MuiSummaryHead" }
);

export type SummaryHeadProps = {
  heading: ReactNode;
  onEdit?: ButtonProps["onClick"];
};

const SummaryHead = ({ heading, onEdit }: SummaryHeadProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {typeof heading === "string" ? (
        <Typography>
          <b>{heading}</b>
        </Typography>
      ) : (
        heading
      )}
      {onEdit && (
        <Button color={"primary"} startIcon={<Edit />} onClick={onEdit}>
          Edit Details
        </Button>
      )}
    </div>
  );
};

export default SummaryHead;
