import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box, { BoxProps } from "@material-ui/core/Box";
import { CustomColor } from "../constant";
import { portexColor } from "../theme/color";
import { within } from "../utils";

export type LinearStepperProps = {
  steps: number;
  activeStep?: number;
  size?: "default" | "large";
  palette?: CustomColor;
  textHidden?: boolean;
  displayPercentage?: boolean;
  percentagePrecision?: number;
} & BoxProps;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    flexGrow: 1,
    backgroundColor: palette.grey["200"],
    borderRadius: 20,
    height: "1rem",
  },
  large: {
    height: "1.25rem",
  },
  bar: (props: Pick<LinearStepperProps, "palette">) => ({
    borderRadius: 20,
    // @ts-ignore
    backgroundColor: portexColor[`${props.palette}500`] ?? "",
  }),
  text: {
    marginLeft: 8,
    fontWeight: "bold",
  },
}));

const LinearStepper = ({
  className,
  size = "default",
  activeStep = 0,
  steps,
  palette = "blue",
  textHidden,
  displayPercentage = false,
  percentagePrecision = 0,
  ...props
}: LinearStepperProps) => {
  const classes = useStyles({ palette });
  const calculatedStep = within(0, activeStep, steps);
  return (
    <Box
      className={cx("LinearStepper", className)}
      display={"flex"}
      alignItems={"center"}
      {...props}
    >
      <LinearProgress
        variant="determinate"
        classes={{
          root: cx(classes.root, size === "large" && classes.large),
          bar: classes.bar,
        }}
        value={Math.floor(calculatedStep * 100) / steps}
      />
      {!textHidden && (
        <Typography className={classes.text}>
          {displayPercentage
            ? `${((calculatedStep * 100) / steps).toFixed(
                percentagePrecision
              )}%`
            : `${calculatedStep}/${steps}`}
        </Typography>
      )}
    </Box>
  );
};

export default LinearStepper;
