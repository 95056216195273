import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 40,
      background: "linear-gradient(to right, #16aa65, #002999 120%)",
      display: "flex",
      justifyContent: "space-between",
      padding: "0.25rem 0.75rem",
      color: "#fff",
    },
  }),
  { name: "Benchmarking" }
);

export type BenchmarkingProps = {
  lower: number | string;
  upper: number | string;
  variant?: TypographyProps["variant"];
  formatter?: (value: number | string) => string;
} & JSX.IntrinsicElements["div"];

function toDollar(value: number | string | undefined | null) {
  return `$${(value || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const Benchmarking = React.forwardRef<HTMLDivElement, BenchmarkingProps>(
  function Benchmarking(
    { className, lower, upper, variant, formatter = toDollar, ...props },
    ref
  ) {
    const classes = useStyles();
    function renderText(text: number | string) {
      return (
        <Typography
          component="span"
          color="inherit"
          variant={variant || "body2"}
        >
          <b>{formatter(text)}</b>
        </Typography>
      );
    }
    return (
      <div ref={ref} className={cx(classes.root, className)} {...props}>
        {renderText(lower)}
        {renderText(upper)}
      </div>
    );
  }
);

export default Benchmarking;
