import React, { PropsWithChildren } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText, {
  ListItemTextProps,
} from "@material-ui/core/ListItemText";
import { CompositeWithRef } from "../types";

const useStyles = makeStyles(
  ({ spacing, palette }) => ({
    nav: {
      padding: spacing(2),
      flexGrow: 1,
      minHeight: 0,
      overflow: "auto",
    },
    item: {
      textDecoration: "none !important",
      borderRadius: 4,
      padding: "8px 16px",
      color: "#fff",
      alignItems: "flex-start",
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.05)",
      },
      "&.Mui-selected": {
        backgroundColor: "rgba(255,255,255,0.08) !important",
      },
      "& + $item": {
        marginTop: 8,
      },
      "& .MuiListItemText-primary": {
        fontWeight: "bold",
      },
      "& .MuiListItemText-secondary": {
        color: palette.grey[300],
      },
      "& .MuiListItemIcon-root": {
        color: "#fff",
        minWidth: 36,
        marginTop: 2,
      },
      "& .MuiListItemText-root": {
        margin: 0,
      },
      "& .MuiListItemText-inset": {
        paddingLeft: 36,
      },
    },
  }),
  { name: "MuiSidebarNav" }
);

type SidebarNavClasses = ReturnType<typeof useStyles>;

const SidebarNavItem = React.forwardRef<
  any,
  PropsWithChildren<
    {
      inset?: boolean;
      icon?: React.ReactNode;
      description?: React.ReactNode;
      ListItemTextProps?: ListItemTextProps;
      component?: React.ElementType;
      href?: string;
      target?: string;
      rel?: string;
      to?: string;
    } & Omit<ListItemProps<"div">, "button">
  >
>(
  (
    {
      children,
      description,
      className,
      icon,
      inset,
      ListItemTextProps,
      ...props
    },
    ref
  ) => {
    const classes = useStyles(props);
    return (
      <ListItem
        ref={ref}
        {...props}
        button
        className={cx(classes.item, className)}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          secondary={description}
          inset={inset}
          {...ListItemTextProps}
        >
          {children}
        </ListItemText>
      </ListItem>
    );
  }
);

type SidebarNavProps = JSX.IntrinsicElements["nav"] & {
  classes?: Partial<SidebarNavClasses>;
};

const SidebarNav = React.forwardRef(function SidebarNav(
  { className, children, ...props },
  ref
) {
  const classes = useStyles(props);
  return (
    <nav ref={ref} className={cx(classes.nav, className)}>
      {children}
    </nav>
  );
}) as CompositeWithRef<
  HTMLAnchorElement,
  SidebarNavProps,
  { Item: typeof SidebarNavItem }
>;

SidebarNav.Item = SidebarNavItem;

export default SidebarNav;
