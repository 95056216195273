import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      viewBox="0 0 36 36"
      color="#16aa65"
      {...props}
    >
      <g data-name="Group 3995">
        <g transform="translate(-9.29 -7.05)">
          <circle
            data-name="Ellipse 32"
            cx={18}
            cy={18}
            r={18}
            transform="translate(9 7)"
            fill="currentColor"
          />
        </g>
        <path
          d="M22.206 13.234v5.391H5.467v-5.391zm2.27.567c.567 0 2.549 1.135 3.1 3.689a1.731 1.731 0 011.826 1.153h-6.628v-2.855h1.135a.568.568 0 00.053-1.135h-1.188v-.851zm1.135 1.7h-.567v1.7h1.56a2.315 2.315 0 00-.993-1.697zm-2.855 3.689v.018h6.783a4.01 4.01 0 01.027.532c.017.581.306.585.3.869h-1.169a1.416 1.416 0 00-2.775 0h-4.035a1.366 1.366 0 00-1.383-1.135 1.433 1.433 0 00-1.392 1.135h-.345a1.515 1.515 0 00-1.383-1.135 1.433 1.433 0 00-1.392 1.135H12.24a1.453 1.453 0 00-1.383-1.135 1.433 1.433 0 00-1.39 1.137h-.346a1.483 1.483 0 00-1.383-1.135 1.433 1.433 0 00-1.392 1.135h-.871s-.008-.851-.008-1.419zm4.557.851a.851.851 0 11-.851.851.851.851 0 01.851-.848zm-6.809 0a.851.851 0 11-.851.851.851.851 0 01.851-.848zm-3.121 0a.851.851 0 11-.851.851.851.851 0 01.851-.848zm-6.526 0a.851.851 0 11-.851.851.851.851 0 01.851-.848zm-3.121 0a.851.851 0 11-.851.851.851.851 0 01.851-.848z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
