import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "clsx";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: "inline-flex",
      alignItems: "center",
    },
    button: {
      borderRadius: 40,
      padding: "4px 0",
      height: 32,
      minWidth: 32,
      "&.Mui-disabled": {
        backgroundColor: palette.grey[500],
        color: palette.common.white,
      },
    },
    input: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      border: "1px solid",
      borderColor: palette.grey[500],
      minHeight: 36,
      minWidth: 52,
      padding: "4px 0.5rem",
      margin: "0 0.5rem",
      boxSizing: "border-box",
      fontSize: "1rem",
      backgroundColor: palette.common.white,
      "&:hover": {
        borderColor: palette.grey[700],
      },
    },
  }),
  { name: "MuiCounter" }
);

type ContextValue = {
  min: number;
  max: number;
  value: number | "";
  setValue: React.Dispatch<React.SetStateAction<number | "">>;
};

const Ctx = React.createContext<ContextValue | undefined>(undefined);

const useCounter = () => {
  const ctx = useContext(Ctx);
  if (!ctx) {
    throw new Error("Cannot call useCounter outside <Counter />");
  }
  return ctx;
};

export type CounterProps = RootProps & {
  decrementIcon: React.ReactNode;
  incrementIcon: React.ReactNode;
};

const Counter = ({
  decrementIcon,
  incrementIcon,
  ...rootProps
}: CounterProps) => {
  return (
    <Counter.Root {...rootProps}>
      <Counter.Decrement>{decrementIcon}</Counter.Decrement>
      <Counter.Input />
      <Counter.Increment>{incrementIcon}</Counter.Increment>
    </Counter.Root>
  );
};

type RootProps = {
  min?: number;
  max?: number;
  initialValue?: number;
  value?: number;
  onChange?: (value: number) => void;
};

const Root = ({
  min = 0,
  max = Infinity,
  initialValue,
  value,
  onChange,
  children,
  ...props
}: PropsWithChildren<
  RootProps & Omit<JSX.IntrinsicElements["div"], "onChange">
>) => {
  const [num, setNum] = useState(initialValue ?? value ?? ("" as const));
  const styles = useStyles();
  useEffect(() => {
    if (initialValue === undefined && value !== num) {
      setNum(value ?? "");
    }
    // only update the state if value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, initialValue]);
  return (
    <Ctx.Provider value={{ value: num, setValue: setNum, min, max }}>
      <div {...props} className={cx(styles.root, props.className)}>
        {children}
      </div>
    </Ctx.Provider>
  );
};

const Decrement = ({ classes, ...props }: ButtonProps) => {
  const { setValue, min, value } = useCounter();
  const styles = useStyles();
  return (
    <Button
      color="primary"
      variant="contained"
      disabled={value! <= min}
      {...props}
      classes={{ ...classes, root: cx(styles.button, classes?.root) }}
      onClick={(event) => {
        setValue((c) => {
          if (typeof c === "number") {
            if (c - 1 >= min) return c - 1;
          }
          return c;
        });
        props.onClick?.(event);
      }}
    />
  );
};

const Increment = ({ classes, ...props }: ButtonProps) => {
  const { setValue, max, value } = useCounter();
  const styles = useStyles();
  return (
    <Button
      color="primary"
      variant="contained"
      disabled={value! >= max}
      {...props}
      classes={{ ...classes, root: cx(styles.button, classes?.root) }}
      onClick={(event) => {
        setValue((c) => {
          if (typeof c === "number") {
            if (c + 1 <= max) return c + 1;
          }
          if (c === "") return 1;
          return c;
        });
        props.onClick?.(event);
      }}
    />
  );
};

const Input = (props: JSX.IntrinsicElements["span"]) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const styles = useStyles();
  const { value, setValue, min, max } = useCounter();
  useEffect(() => {
    if (ref.current) {
      ref.current.textContent = `${value}`;
    }
  });
  return (
    <span
      {...props}
      ref={ref}
      role="textbox"
      contentEditable
      className={cx(styles.input, props.className)}
      onInput={(event) => {
        if (event.currentTarget.innerText) {
          const num = +event.currentTarget.innerText;
          if (Number.isInteger(num) && num >= min && num <= max) {
            setValue(num);
          } else {
            if (ref.current) {
              ref.current.textContent = `${value}`;
            }
          }
        }
        props.onInput?.(event);
      }}
    />
  );
};

Counter.Root = Root;
Counter.Decrement = Decrement;
Counter.Input = Input;
Counter.Increment = Increment;

export default Counter;
