import React, { ReactNode, useRef } from "react";
import cx from "clsx";
import { fade, makeStyles } from "@material-ui/core/styles";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { red } from "../theme/color";
import { SvgBackspaceTwoTone, SvgKeyboardArrowDown } from "../PortexIcons";

const useStyles = makeStyles(
  ({ palette, spacing }) => ({
    control: {
      boxShadow: `inset 0 0 0 1px ${red["500"]}`,
      backgroundColor: red["100"],
      padding: "0.375rem 0.75rem",
      paddingRight: "0.5rem",
      borderRadius: 4,
      cursor: "pointer",
      justifyContent: "center",
      "& svg": {
        color: red[500],
      },
      "&:focus-visible": {
        boxShadow: `inset 0 0 0 2px ${red["500"]}`,
        outline: "none",
      },
    },
    active: {},
    selected: {
      boxShadow: `inset 0 0 0 2px ${red["500"]}`,
    },
    controlLabel: {
      marginLeft: -4,
    },
    label: {
      fontWeight: "bold",
      color: red["500"],
      marginLeft: 8,
      marginRight: 4,
    },
    checkbox: {
      padding: 0,
      color: red["500"],
      "&:hover": {
        backgroundColor: red["100"],
      },
      "& svg": {
        fontSize: 30,
      },
      "&.Mui-checked, &.Mui-checked:not(.Mui-disabled) + *": {
        color: red["500"],
      },
    },
    clear: {
      padding: spacing(0.5),
      margin: spacing(-0.5, 0),
    },
    paper: {
      marginTop: 12,
      border: "1px solid",
      borderColor: palette.grey["200"],
      boxShadow: `0 0 12px 0 ${fade(palette.grey["700"], 0.12)}`,
    },
    item: {
      fontSize: "0.875rem",
    },
  }),
  { name: "QuoteUrgency" }
);

export type QuoteUrgencyProps<T> = {
  active?: boolean;
  hasCheckbox?: boolean;
  dropdownIcon?: ReactNode;
  clearIcon?: ReactNode;
  data: T[];
  value?: string;
  onChange?: (id: string, item: T) => void;
  onClear?: () => void;
  getLabel?: (value?: string) => string;
} & Pick<FormControlProps, "style" | "className">;

const QuoteUrgency = <T extends { id: string; label: React.ReactNode }>({
  className,
  value,
  data,
  active = false,
  onChange,
  onClear,
  hasCheckbox,
  dropdownIcon = <SvgKeyboardArrowDown fontSize="small" />,
  clearIcon = <SvgBackspaceTwoTone fontSize="small" />,
  getLabel = () => "Mark Quote Urgent",
  ...props
}: QuoteUrgencyProps<T>) => {
  const classes = useStyles();
  const node = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const selected = !!data.find(({ id }) => id === value);
  return (
    <>
      <FormControl
        component="button"
        id="quote-urgency-button"
        aria-controls="quote-urgency-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        {...props}
        className={cx(
          classes.control,
          active && classes.active,
          open && classes.active,
          selected && classes.selected,
          className
        )}
        ref={node}
        onClick={handleClickListItem}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "ArrowDown" && !anchorEl) {
            setAnchorEl(node.current);
          }
        }}
      >
        <Box
          className={classes.controlLabel}
          display={"flex"}
          alignItems={"center"}
        >
          {hasCheckbox && (
            <Checkbox
              className={classes.checkbox}
              checked={selected}
              onClick={(event) => {
                if (selected) {
                  event.stopPropagation();
                  onClear?.();
                  if (node.current) {
                    node.current.focus();
                  }
                }
              }}
            />
          )}
          <Typography className={classes.label}>{getLabel(value)}</Typography>
          {!hasCheckbox && clearIcon && selected && (
            <IconButton
              className={classes.clear}
              onClick={(event) => {
                event.stopPropagation();
                onClear?.();
                if (node.current) {
                  node.current.focus();
                }
              }}
            >
              {clearIcon}
            </IconButton>
          )}
          {(!clearIcon || (clearIcon && !selected)) && dropdownIcon}
        </Box>
      </FormControl>
      <Menu
        id="quote-urgency-menu"
        aria-labelledby="quote-urgency-button"
        open={open}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            minWidth: node.current?.clientWidth,
            maxHeight: 36 * 5,
          },
          className: classes.paper,
        }}
      >
        {data.map((item) => (
          <MenuItem
            className={classes.item}
            onClick={() => {
              onChange?.(item.id, item);
              setAnchorEl(null);
              if (node.current) {
                node.current.focus();
              }
            }}
            key={item.id}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default QuoteUrgency;
