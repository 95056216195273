import React, { ComponentType, PropsWithChildren, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar, { AppBarProps } from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  SvgPortexLogo,
  SvgKeyboardArrowLeft,
  SvgKeyboardArrowRight,
  SvgPortexMiniLogo,
  SvgPortexIcon,
} from "../PortexIcons";
import SidebarNav from "./SidebarNav";

interface LayoutContextValue {
  sidebar: {
    width: number;
    collapsedWidth: number;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

// TODO: just to prevent webapp crash in old version, will be removed later
const defaultValue: LayoutContextValue = {
  sidebar: {
    width: 240,
    collapsedWidth: 80,
    isCollapsed: false,
    setIsCollapsed: () => {},
  },
};

const LayoutContext = React.createContext<LayoutContextValue | undefined>(
  undefined
);

export const useLayoutContext = () => {
  const ctx = React.useContext(LayoutContext);
  return ctx || defaultValue;
};

type Dict = {
  [k: string]: any;
};
export type LayoutMenu = {
  icon?: ReactNode;
  component?: ComponentType;
  label: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
} & Dict;
export type LayoutProps<T> = {
  headerTitle?: ReactNode;
  sidebarWidth?: number | string;
  menus: T[];
  getActiveItem: (item: T) => boolean;
  renderMenuHeader?: () => ReactNode;
  renderMenuBottom?: () => ReactNode;
  renderAppHeader?: () => ReactNode;
  onClickLogo?: React.MouseEventHandler<SVGSVGElement>;
  AppBarStyle?: React.CSSProperties;
  mainStyle?: React.CSSProperties;
};

const useSidebarToggleStyles = makeStyles((theme) => ({
  action: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.24)",
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[700],
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

const useStyles = makeStyles(
  () => ({
    offsetLeft: ({ sidebarWidth }: { sidebarWidth: number | string }) => ({
      marginLeft: sidebarWidth,
    }),
  }),
  { name: "Layout" }
);

const LayoutSidebar = ({
  onClickLogo,
  enableToggle = false,
  newIcon = false,
  ...props
}: DrawerProps & { enableToggle?: boolean; newIcon?: boolean } & Pick<
    LayoutProps<unknown>,
    "sidebarWidth" | "onClickLogo"
  >) => {
  const { sidebar } = useLayoutContext();
  return (
    <Drawer
      variant={"permanent"}
      PaperProps={{
        ...props.PaperProps,
        style: { ...props.PaperProps?.style, overflow: "initial" },
      }}
      {...props}
    >
      <Box
        bgcolor={"grey.900"}
        height={"100%"}
        width={sidebar.isCollapsed ? sidebar.collapsedWidth : sidebar.width}
        overflow={sidebar.isCollapsed ? "hidden" : "auto"}
        style={{ transition: "width 0.3s" }}
      >
        <Box
          height="100%"
          width={sidebar.width}
          display={"flex"}
          flexDirection={"column"}
          color="#fff"
        >
          <Box
            display="flex"
            py={1}
            style={{
              transform: sidebar.isCollapsed
                ? "translate(8px)"
                : "translate(-40px)",
              transition: "0.4s",
            }}
          >
            {newIcon ? (
              <SvgPortexMiniLogo
                style={{
                  marginRight: 38,
                  marginLeft: 6,
                  marginTop: 6,
                  marginBottom: 6,
                  visibility: sidebar.isCollapsed ? "visible" : "hidden",
                  ...(onClickLogo ? { cursor: "pointer" } : {}),
                }}
                onClick={onClickLogo}
              />
            ) : (
              <SvgPortexIcon
                style={{
                  marginRight: 32,
                  visibility: sidebar.isCollapsed ? "visible" : "hidden",
                  ...(onClickLogo ? { cursor: "pointer" } : {}),
                }}
                {...(onClickLogo ? { onClick: onClickLogo } : {})}
              />
            )}
            <SvgPortexLogo
              color={"#fff"}
              style={{
                margin: "auto",
                ...(onClickLogo ? { cursor: "pointer" } : {}),
              }}
              {...(onClickLogo ? { onClick: onClickLogo } : {})}
            />
          </Box>
          {props.children}
        </Box>
      </Box>
      {enableToggle && <SidebarToggle />}
    </Drawer>
  );
};

const LayoutAppBar = ({
  headerTitle,
  ...props
}: AppBarProps & Pick<LayoutProps<unknown>, "headerTitle">) => {
  const { sidebar } = useLayoutContext();
  return (
    <AppBar
      color={"default"}
      position={"sticky"}
      {...props}
      style={{
        ...props.style,
        marginLeft: sidebar.isCollapsed
          ? sidebar.collapsedWidth
          : sidebar.width,
        transition: "margin 0.3s",
      }}
    >
      {headerTitle ? (
        <Box px={2.5} py={1.5} display={"flex"} alignItems={"center"}>
          <Typography>
            <strong>{headerTitle}</strong>
          </Typography>
        </Box>
      ) : null}
      {props.children}
    </AppBar>
  );
};

const SidebarToggle = () => {
  const { sidebar } = useLayoutContext();
  const classes = useSidebarToggleStyles();
  return (
    <Box position="absolute" right={-12} bottom={72}>
      <Tooltip title={sidebar.isCollapsed ? "Open" : "Close"}>
        <IconButton
          size="small"
          className={classes.action}
          onClick={() => sidebar.setIsCollapsed((bool) => !bool)}
        >
          {sidebar.isCollapsed ? (
            <SvgKeyboardArrowRight />
          ) : (
            <SvgKeyboardArrowLeft />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const LayoutMain = (props: JSX.IntrinsicElements["main"]) => {
  const { sidebar } = useLayoutContext();
  return (
    <main
      {...props}
      style={{
        ...props.style,
        marginLeft: sidebar.isCollapsed
          ? sidebar.collapsedWidth
          : sidebar.width,
        transition: "margin 0.3s",
      }}
    />
  );
};

export interface LayoutRootProps {
  sidebar?: {
    width?: number;
    collapsedWidth?: number;
  };
}

const Root = ({
  children,
  sidebar = {},
}: React.PropsWithChildren<LayoutRootProps>) => {
  const { width = 240, collapsedWidth = 64 } = sidebar;
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  return (
    <LayoutContext.Provider
      value={{
        sidebar: { width, collapsedWidth, isCollapsed, setIsCollapsed },
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

/**
 * use composition instead, will be removed in v1.0
 */
const Layout = <T extends LayoutMenu>({
  headerTitle,
  sidebarWidth = 240,
  menus,
  getActiveItem,
  children,
  renderMenuHeader,
  renderMenuBottom,
  renderAppHeader,
  onClickLogo,
  AppBarStyle,
  mainStyle,
}: PropsWithChildren<LayoutProps<T>>) => {
  const layoutStyles = useStyles({ sidebarWidth });
  return (
    <Root>
      <LayoutSidebar sidebarWidth={sidebarWidth} onClickLogo={onClickLogo}>
        {renderMenuHeader?.()}
        <SidebarNav>
          {menus.map((menuItem) => (
            <SidebarNav.Item
              key={menuItem.label}
              selected={getActiveItem(menuItem)}
              {...menuItem}
            >
              {menuItem.label}
            </SidebarNav.Item>
          ))}
        </SidebarNav>
        {renderMenuBottom?.()}
      </LayoutSidebar>
      <LayoutAppBar
        className={layoutStyles.offsetLeft}
        headerTitle={headerTitle}
        style={AppBarStyle}
      >
        {renderAppHeader?.()}
      </LayoutAppBar>
      <main className={layoutStyles.offsetLeft} style={{ ...mainStyle }}>
        {children}
      </main>
    </Root>
  );
};

Layout.Root = Root;
Layout.Main = LayoutMain;
Layout.Sidebar = LayoutSidebar;
Layout.AppBar = LayoutAppBar;

export default Layout;
