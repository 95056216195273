import React, {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  SetStateAction,
} from "react";
import TableHead, { TableHeadProps } from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow, { TableRowProps } from "@material-ui/core/TableRow";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

import { useSearchable } from "./useSearchable";
import { useSelectableRow } from "./useSelectableRow";
import TextInput, { TextInputProps } from "../TextInput/TextInput";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SvgUnchecked } from "../PortexIcons";

const useStyles = makeStyles(() => ({
  disabled: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
      cursor: "not-allowed",
      "& .MuiSvgIcon-root .innerBox": {
        fill: "#d3d9de",
      },
    },
  },
}));

type Columns = Array<
  string | { title: React.ReactNode; TableCellProps?: TableCellProps }
>;
type UI = {
  ui: ReactNode;
  TableCellProps?: TableCellProps;
  TypographyProps?: TypographyProps;
};
type BodyConfig<T> = {
  [k: number]: (item: T, rowIndex: number) => ReactNode | UI;
};
export type TableRendererAPI<T> = {
  setSelectedItems: React.Dispatch<SetStateAction<T[]>>;
  toggle: (item: T) => void;
  toggleAll: (deselected: boolean) => void;
};
type Config<T> = {
  selectable?: boolean;
  selectedItems?: T[];
  disabledItems?: T[];
  loose?: boolean;
  ref?: MutableRefObject<TableRendererAPI<T> | undefined>;
  searchable?: boolean;
  itemIdentifier?: (item: T, selected: T) => boolean;
  keywordIdentifier?: (item: T, keyword: string) => boolean;
};

const isUI = (result: ReactNode | UI): result is UI => {
  return !!result && typeof result === "object" && "ui" in result;
};

export const useTableRenderer = <T,>(
  columns: Columns,
  data: T[],
  config: Config<T> = {}
) => {
  if (config?.selectable && !config?.itemIdentifier) {
    throw new Error('Selectable feature needs "itemIdentifier" to work');
  }

  if (config?.searchable && !config?.keywordIdentifier) {
    throw new Error('Search feature needs "keywordIdentifier" to work');
  }

  const { ref } = config;

  const { result, keyword, onInputChange, onClear } = useSearchable(
    data,
    config?.keywordIdentifier ?? (() => true)
  );
  const classes = useStyles();

  const {
    selectedItems,
    setSelectedItems,
    isAllSelected,
    onToggle,
    onToggleAll,
    checkSelected,
    checkDisabled,
  } = useSelectableRow(data, config?.itemIdentifier ?? (() => false), {
    selectedItems: config.selectedItems,
    disabledItems: config.disabledItems,
    displayedItems: result,
    loose: config.loose,
  });

  if (ref && "current" in ref) {
    ref.current = {
      setSelectedItems,
      toggle: onToggle,
      toggleAll: onToggleAll,
    };
  }

  const renderCell = (
    result: ReactNode,
    TableCellProps?: TableCellProps & { key?: string },
    TypographyProps?: TypographyProps
  ) => {
    return (
      <TableCell {...TableCellProps}>
        {typeof result === "string" || typeof result === "number" ? (
          <Typography variant="subtitle1" {...TypographyProps}>
            {result}
          </Typography>
        ) : (
          result
        )}
      </TableCell>
    );
  };

  return {
    selectedItems,
    setSelectedItems,
    isAllSelected,
    onToggle,
    onToggleAll,
    checkSelected,
    displayedItems: result,
    keyword,
    renderHead: (
      props: TableHeadProps = {},
      options: {
        onToggleAllClick?: () => void;
        renderBottom?: () => ReactNode;
      } = {}
    ) => (
      <TableHead {...props}>
        <TableRow>
          {config?.selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectedItems.length > 0 && !isAllSelected}
                checked={isAllSelected}
                inputProps={{ "aria-label": "select all" }}
                onChange={() => {
                  onToggleAll();
                  options?.onToggleAllClick?.();
                }}
              />
            </TableCell>
          )}
          {columns.map((head, index) =>
            typeof head === "string" ? (
              <TableCell key={index}>{head}</TableCell>
            ) : (
              <TableCell key={index} {...head.TableCellProps}>
                {head.title}
              </TableCell>
            )
          )}
        </TableRow>
        {options.renderBottom?.()}
      </TableHead>
    ),
    renderBody: (
      columnMapping: BodyConfig<T>,
      options: {
        onRowClick?: (item: T, index: number) => void;
        TableRowProps?: Omit<TableRowProps, "selected" | "onClick">;
        checkboxVerticalAlign?: CSSProperties["verticalAlign"];
        renderTop?: () => ReactNode;
      } = {}
    ) => (
      <TableBody>
        {options.renderTop?.()}
        {result.map((item, rowIndex) => {
          const selected = checkSelected(item);
          const disabled = checkDisabled(item);

          return (
            <TableRow
              key={rowIndex}
              hover={config?.selectable}
              {...options?.TableRowProps}
              selected={selected}
              onClick={() => {
                if (disabled) return;

                config?.selectable && onToggle(item);
                options?.onRowClick?.(item, rowIndex);
              }}
            >
              {config?.selectable && (
                <TableCell
                  padding="checkbox"
                  style={{ verticalAlign: options.checkboxVerticalAlign }}
                >
                  {disabled ? (
                    <Checkbox
                      checked={selected}
                      disabled={disabled}
                      className={classes.disabled}
                      icon={<SvgUnchecked width="50px" height="50px" />}
                    />
                  ) : (
                    <Checkbox checked={selected} />
                  )}
                </TableCell>
              )}
              {columns.map((head, colIndex) => {
                const result = columnMapping[colIndex]?.(item, rowIndex);
                if (isUI(result)) {
                  return renderCell(
                    result.ui,
                    { ...result.TableCellProps, key: colIndex.toString() },
                    result.TypographyProps
                  );
                }
                return renderCell(result, { key: colIndex.toString() });
              })}
            </TableRow>
          );
        })}
      </TableBody>
    ),
    renderBothSubtitle: (subtitle1: ReactNode, subtitle2: ReactNode) => (
      <>
        <Typography variant="subtitle1">{subtitle1}</Typography>
        <Typography variant="subtitle2">{subtitle2}</Typography>
      </>
    ),
    renderSearchInput: (
      props?: Omit<TextInputProps, "onClear" | "value" | "onChange">
    ) => (
      <TextInput
        placeholder="Search"
        search
        {...props}
        value={keyword}
        onChange={onInputChange}
        onClear={onClear}
      />
    ),
  };
};
