import React from "react";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import SvgFlatbed from "../PortexIcons/SvgFlatbed";
import SvgDryVan from "../PortexIcons/SvgDryVan";
import SvgSnowflake from "../PortexIcons/SvgSnowflake";
import SvgIcon from "@material-ui/core/SvgIcon";
import Icon from "../Icon/Icon";
import SelectInput, { SelectInputProps } from "../SelectInput/SelectInput";

import { CustomColor } from "../constant";

enum TruckTrailerType {
  Flatbed = "FLATBED",
  DryVan = "DRY_VAN",
  Reefer = "REEFER",
}
export type TruckTrailerValue = `${TruckTrailerType}`;

const defaultOptions = [
  {
    id: TruckTrailerType.Flatbed,
    label: "Flatbed",
    icon: SvgFlatbed,
    palette: "yellow" as const,
  },
  {
    id: TruckTrailerType.DryVan,
    label: "Dry Van",
    icon: SvgDryVan,
    palette: "orange" as const,
  },
  {
    id: TruckTrailerType.Reefer,
    label: "Reefer",
    icon: SvgSnowflake,
    palette: "blue" as const,
  },
];

function FormTrailerType<
  ID = TruckTrailerValue,
  T extends {
    id: ID;
    label: string;
    icon?: typeof SvgIcon;
    palette?: CustomColor;
  } = {
    id: ID;
    label: string;
  }
>({
  ui,
  options = (defaultOptions as unknown) as T[],
  value,
  onChange,
  SelectProps,
}: {
  ui: "select" | "list";
  options?: Array<T>;
  value?: ID;
  onChange?: (id: ID, option: T) => void;
  SelectProps?: Omit<SelectInputProps, "value" | "onChange">;
}) {
  if (ui === "list") {
    return (
      <>
        <Box px={2} py={1}>
          <Typography variant={"subtitle1"}>Trailer Type</Typography>
        </Box>
        {options.map((option, index) => {
          const element = (
            <ListItem button onClick={() => onChange?.(option.id, option)}>
              {option.icon && (
                <Icon
                  as={option.icon}
                  variant="filled"
                  palette={option.palette}
                  style={{ marginRight: 4 }}
                />
              )}
              &nbsp; &nbsp;
              <Typography>{option.label}</Typography>
            </ListItem>
          );
          return (
            <React.Fragment key={(option.id as unknown) as string}>
              {index % 2 === 0 ? (
                <Box bgcolor={"grey.50"}>{element}</Box>
              ) : (
                element
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }
  if (ui === "select") {
    return (
      <SelectInput
        label={"Trailer Type"}
        margin={"normal"}
        fullWidth
        {...SelectProps}
        value={value ?? ""}
        onChange={(event) => {
          const result = options.find(({ id }) => id === event.target.value)!;
          onChange?.(result.id, result);
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={(option.id as unknown) as string}
            value={(option.id as unknown) as string}
          >
            {option.icon && (
              <Icon
                as={option.icon}
                variant={"filled"}
                palette={option.palette}
                style={{ marginRight: 16 }}
              />
            )}
            {option.label}
          </MenuItem>
        ))}
      </SelectInput>
    );
  }
  return null;
}

export default FormTrailerType;
