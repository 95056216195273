import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      viewBox="0 0 36 36"
      color="#7e71c3"
      {...props}
    >
      <g data-name="Group 3994">
        <g>
          <circle cx={18} cy={18} r={18} fill="currentColor" />
        </g>
        <path
          d="M25.151 19.067h-4.12l-8.457-4.771h-2.168l4.77 4.771h-4.554l-2.386-2.386h-1.3l1.518 3.9s5.2 1.518 7.806 1.518h8.891c1.3 0 3.036-.867 3.036-1.518s-1.735-1.514-3.036-1.514z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
