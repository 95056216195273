import * as React from "react";

const SvgServiceStandardChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
  >
    <g id="standard-checked" transform="translate(14334 -18206)">
      <circle
        id="Ellipse_283"
        data-name="Ellipse 283"
        cx="26.5"
        cy="26.5"
        r="26.5"
        transform="translate(-14334 18206)"
        fill="#265df1"
      />
      <path
        id="Icon_material-playlist-add-check"
        data-name="Icon material-playlist-add-check"
        d="M21,15H3v3H21Zm0-6H3v3H21ZM3,24H15V21H3Zm29.25-6.75L34.5,19.5,24.015,30,17.25,23.25,19.5,21l4.515,4.5Z"
        transform="translate(-14326 18213)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgServiceStandardChecked;
