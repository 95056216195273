import React from "react";
import cx from "clsx";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export type FormPalletizedProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
} & Omit<FormControlProps, "onChange">;

const FormPalletized = ({ value, onChange, ...props }: FormPalletizedProps) => (
  <FormControl margin="normal" fullWidth {...props}>
    <FormLabel>Pallets and Weights</FormLabel>
    <ButtonGroup fullWidth color={"primary"}>
      <Button
        className={cx(typeof value === "boolean" && value && "Ptx-selected")}
        onClick={() => onChange?.(true)}
      >
        Palletized
      </Button>
      <Button
        className={cx(typeof value === "boolean" && !value && "Ptx-selected")}
        onClick={() => onChange?.(false)}
      >
        Floor loaded
      </Button>
    </ButtonGroup>
  </FormControl>
);

export default FormPalletized;
