import React from "react";
import cx from "clsx";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

enum WeightInfoType {
  total = "total",
  perUnit = "perUnit",
}
export type WeightInfoValue = `${WeightInfoType}`;

export type FormWeightInfoProps = {
  value?: WeightInfoValue;
  onChange?: (value: WeightInfoValue) => void;
} & Omit<FormControlProps, "onChange">;

const FormWeightType = ({ value, onChange, ...props }: FormWeightInfoProps) => (
  <FormControl fullWidth margin={"normal"} {...props}>
    <FormLabel>Weight Information</FormLabel>
    <ButtonGroup fullWidth color="primary">
      <Button
        className={cx(value === WeightInfoType.total && "Ptx-selected")}
        onClick={() => onChange?.(WeightInfoType.total)}
      >
        Total Weight
      </Button>
      <Button
        className={cx(value === WeightInfoType.perUnit && "Ptx-selected")}
        onClick={() => onChange?.(WeightInfoType.perUnit)}
      >
        Weight Per Unit
      </Button>
    </ButtonGroup>
  </FormControl>
);

export default FormWeightType;
