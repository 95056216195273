import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Edit from "@material-ui/icons/Edit";

export type LaneSummaryProps = {
  laneNumber: number;
  route: string;
  reference: string;
  pickup: {
    location?: string;
    date?: string;
    time?: string;
  };
  delivery: {
    location?: string;
    date?: string;
    time?: string;
  };
  mode?: string;
  trailer?: string;
  truckAmount?: number;
  packaging?: string;
  palletAmount?: number;
  weight?: string;
  commodities?: string[];
  onEdit?: ButtonProps["onClick"];
};

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      borderRadius: 4,
      border: "1px solid",
      borderColor: palette.grey["300"],
      "& > *:nth-child(even)": {
        backgroundColor: palette.grey["100"],
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      padding: 8,
      paddingLeft: 12,
      borderBottom: "1px solid",
      borderBottomColor: palette.grey["300"],
      "& > *:not(:first-child)": {
        marginLeft: 12,
      },
      "& > *:last-child": {
        marginLeft: "auto",
      },
    },
    row: {
      "& .MuiTypography-root": {
        lineHeight: 1.75,
      },
    },
  }),
  { name: "LaneSummary" }
);

const LaneSummary = ({
  laneNumber,
  route,
  reference,
  pickup,
  delivery,
  mode,
  trailer,
  truckAmount,
  // palletAmount,
  packaging,
  weight,
  commodities,
  onEdit,
}: LaneSummaryProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Chip label={`Lane ${laneNumber || ""}`} />
        <Typography variant={"subtitle1"}>{route}</Typography>
        <Typography>{reference}</Typography>
        <Button color={"primary"} startIcon={<Edit />} onClick={onEdit}>
          Edit Details
        </Button>
      </div>
      <Box p={2}>
        <Grid container className={classes.row}>
          <Grid item sm={3}>
            <Typography variant={"subtitle1"}>Pickup</Typography>
            <Typography variant={"subtitle1"}>Date</Typography>
            <Typography variant={"subtitle1"}>Time</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography>{pickup.location ?? "-"}</Typography>
            <Typography>{pickup.date ?? "-"}</Typography>
            <Typography>{pickup.time ?? "-"}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant={"subtitle1"}>Delivery</Typography>
            <Typography variant={"subtitle1"}>Date</Typography>
            <Typography variant={"subtitle1"}>Time</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography>{delivery.location ?? "-"}</Typography>
            <Typography>{delivery.date ?? "-"}</Typography>
            <Typography>{delivery.time ?? "-"}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <Grid container className={classes.row}>
          <Grid item sm={3}>
            <Typography variant={"subtitle1"}>Mode Type</Typography>
            <Typography variant={"subtitle1"}>Trailer Type</Typography>
            <Typography variant={"subtitle1"}>No. of Trucks</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography>{mode ?? "-"}</Typography>
            <Typography>{trailer ?? "-"}</Typography>
            <Typography>{truckAmount ?? "-"}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant={"subtitle1"}>Item Packaging</Typography>
            {/* <Typography variant={"subtitle1"}>No. of Pallets</Typography> */}
            <Typography variant={"subtitle1"}>Weight</Typography>
            <Typography variant={"subtitle1"}>Commodities</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography>{packaging ?? "-"}</Typography>
            {/* <Typography>{palletAmount ?? "-"}</Typography> */}
            <Typography>{weight ?? "-"}</Typography>
            <Typography>{commodities?.join(", ") ?? "-"}</Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default LaneSummary;
