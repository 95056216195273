import React from "react";
import cx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { green, red, yellow } from "../theme/color";
import { Tooltip } from "..";

const useStyles = makeStyles((theme) => ({
  scale: {
    height: 10,
    position: "relative",
    display: "flex",
  },
  low: {
    height: "100%",
    width: "calc(20% - 1px)",
    backgroundColor: green[500],
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    marginRight: 1,
  },
  average: {
    height: "100%",
    width: "calc(60% - 2px)",
    margin: "0 1px",
    backgroundColor: yellow[500],
  },
  high: {
    height: "100%",
    width: "calc(20% - 1px)",
    backgroundColor: red[500],
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginLeft: 1,
  },
  thumb: {
    height: 24,
    width: 24,
    borderRadius: 40,
    backgroundColor: theme.palette.background.paper,
    border: "3px solid",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    borderColor: yellow[500],
    boxShadow: "0 2px 8px 0 rgba(0,0,0,0.2)",
  },
  greenThumb: {
    borderColor: green[500],
  },
  redThumb: {
    borderColor: red[500],
  },
  label: {
    ...theme.typography.body2,
    fontWeight: "bold",
  },
}));

export type IndustryAverageProps = {
  lower: number;
  upper: number;
  tooltipLabel?: React.ReactNode;
  value: number;
  formatter?: (input: number) => string;
} & Omit<JSX.IntrinsicElements["div"], "ref">;

function dollarFormatter(value: number) {
  return `$${(value || "0").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const IndustryAverage = React.forwardRef<HTMLDivElement, IndustryAverageProps>(
  function IndustryAverage(
    {
      lower,
      upper,
      tooltipLabel,
      value,
      formatter = dollarFormatter,
      ...props
    },
    ref
  ) {
    const classes = useStyles();
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        padding: "0.5rem",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid",
        borderColor: theme.palette.grey[200],
        borderRadius: 4,
        fontSize: "0.875rem",
        color: "rgba(0, 0, 0, 0.87)",
      },
      arrow: {
        "&::before": {
          borderWidth: "0 1px 1px 0",
          borderStyle: "solid",
          borderColor: theme.palette.grey[200],
          backgroundColor: theme.palette.background.paper,
        },
      },
    }))(Tooltip);
    const percentage = ((value - lower) * 100) / (upper - lower);
    return (
      <div ref={ref} {...props}>
        <div className={classes.scale}>
          <div className={classes.low}></div>
          <div className={classes.average}></div>
          <div className={classes.high}></div>
          <HtmlTooltip
            title={<React.Fragment>{tooltipLabel}</React.Fragment>}
            placement="top"
            arrow
          >
            <div
              className={cx(classes.thumb, {
                [classes.greenThumb]: percentage <= 20,
                [classes.redThumb]: percentage >= 80,
              })}
              style={{ left: `clamp(12px, ${percentage}%, 100% - 12px)` }}
            />
          </HtmlTooltip>
        </div>
        <Box display="flex" justifyContent="space-between" mt={0.75}>
          <div className={classes.label}>{formatter(lower)}</div>
          <div className={classes.label}>{formatter(upper)}</div>
        </Box>
      </div>
    );
  }
);

export default IndustryAverage;
