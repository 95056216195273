import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.164}
      height={16.125}
      viewBox="0 0 11.164 16.125"
      color="#e25050"
      {...props}
    >
      <path
        data-name="Icon ionic-ios-flame"
        d="M4.19 0C5.314 5.217-.039 5.113 0 10.365c.031 4.3 4.589 5.76 5.6 5.76a5.916 5.916 0 005.547-5.76C11.425 6.241 7.981 2.302 4.19 0zm2.88 13.749a1.535 1.535 0 01-2.961 0 2.628 2.628 0 01-.093-.678A6.683 6.683 0 015.59 9.648a6.7 6.7 0 011.566 3.423 2.439 2.439 0 01-.086.678z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
