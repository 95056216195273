import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.063"
      height="7.835"
      viewBox="0 0 24.063 7.835"
      color="#265df1"
      {...props}
    >
      <g
        id="svg-truck-ltl-blue"
        data-name="Group 6418"
        transform="translate(-9.981 -25.779)"
      >
        <path
          id="np_truck_1157396_000000"
          d="M25.746,37.034c.56,0,2.514,1.119,3.06,3.637a1.707,1.707,0,0,1,1.8,1.137h-6.54V38.993h1.119a.56.56,0,0,0,.052-1.119H24.067v-.839Zm1.119,1.679h-.56v1.679h1.539A2.283,2.283,0,0,0,26.865,38.713ZM24.05,42.351v.017h6.689a3.955,3.955,0,0,1,.026.525c.016.573.3.577.3.857H29.908a1.4,1.4,0,0,0-2.737,0H23.193a1.348,1.348,0,0,0-1.364-1.119,1.413,1.413,0,0,0-1.373,1.119h-.341a1.494,1.494,0,0,0-1.364-1.119,1.413,1.413,0,0,0-1.373,1.119h-3.7a1.433,1.433,0,0,0-1.364-1.119,1.413,1.413,0,0,0-1.373,1.119H10.6A1.462,1.462,0,0,0,9.238,42.63,1.413,1.413,0,0,0,7.865,43.75H7.008S7,42.91,7,42.351Zm4.494.839a.839.839,0,1,1-.839.839A.84.84,0,0,1,28.544,43.19Zm-6.715,0a.839.839,0,1,1-.839.839A.84.84,0,0,1,21.829,43.19Zm-3.078,0a.839.839,0,1,1-.839.839A.84.84,0,0,1,18.751,43.19Zm-6.435,0a.839.839,0,1,1-.839.839A.84.84,0,0,1,12.316,43.19Zm-3.078,0a.839.839,0,1,1-.839.839A.84.84,0,0,1,9.238,43.19Z"
          transform="translate(2.982 -11.255)"
          fill="currentColor"
        />
        <path
          id="Path_3109"
          data-name="Path 3109"
          d="M41.262,46.16h4.847v2.589H41.262Z"
          transform="translate(-31.28 -18.347)"
          fill="currentColor"
        />
        <path
          id="Path_3110"
          data-name="Path 3110"
          d="M53.957,46.16h4.849v2.589H53.957Z"
          transform="translate(-38.236 -18.347)"
          fill="currentColor"
        />
        <path
          id="Path_3111"
          data-name="Path 3111"
          d="M66.652,46.16H71.5v2.589H66.652Z"
          transform="translate(-45.193 -18.347)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
