import React, {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "clsx";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import CheckBoxOutlineBlankRounded from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import CheckBoxRounded from "@material-ui/icons/CheckBoxRounded";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import Dropdown, { DropdownRef } from "../Dropdown/Dropdown";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  ({ palette }) => ({
    empty: {
      "& .MuiTypography-root": {
        color: palette.text.secondary,
      },
    },
    checkbox: {
      marginRight: 8,
    },
    children: {
      "& .MuiList-root": {
        paddingBottom: 0,
      },
    },
  }),
  { name: "DropdownFilter" }
);

export type DropdownFilterProps = {
  placeholder: string;
  options: { label: ReactNode; value: number | string }[];
  value: (string | number)[];
  multiple?: boolean;
  closedAfterSubmit?: boolean;
  onChange?: (value: (string | number)[]) => void;
  onSubmit?: (value: (string | number)[]) => void;
  submitText?: string;
  children?: ComponentProps<typeof Dropdown>["children"];
  PaperProps?: ComponentProps<typeof Dropdown>["PaperProps"];
  showSelectAllOption?: boolean;
  selectAllLabel?: ReactNode;
};

const SELECT_ALL = "__select_all__";

const DropdownFilter = ({
  placeholder,
  multiple,
  value,
  options,
  onChange,
  onSubmit,
  submitText,
  children,
  closedAfterSubmit,
  PaperProps,
  showSelectAllOption = false,
  selectAllLabel = "Select All",
}: DropdownFilterProps) => {
  const styles = useStyles();
  const dropdownRef = useRef<DropdownRef>();
  const [internal, setInternal] = useState(value);
  const renderMarker = (selected: boolean) => {
    if (selected) {
      const common = { color: "primary", className: styles.checkbox } as const;
      return multiple ? (
        <CheckBoxRounded {...common} />
      ) : (
        <RadioButtonChecked {...common} />
      );
    }
    const common = { color: "action", className: styles.checkbox } as const;
    return multiple ? (
      <CheckBoxOutlineBlankRounded {...common} />
    ) : (
      <RadioButtonUnchecked {...common} />
    );
  };

  const handleClose = useCallback(() => {
    if (dropdownRef.current?.close) {
      return dropdownRef.current?.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef.current?.close]);

  const dropdownOptions = useMemo<typeof options>(() => {
    if (!showSelectAllOption || !multiple) return options;

    return [{ value: SELECT_ALL, label: selectAllLabel }, ...options];
  }, [multiple, options, selectAllLabel, showSelectAllOption]);

  return (
    <Dropdown
      className={cx({
        [styles.empty]: !value.length,
      })}
      dropdownRef={dropdownRef}
      placeholder={placeholder}
      onOpen={() => setInternal(value)}
      PaperProps={{
        ...PaperProps,
        className: cx(
          {
            [styles.children]: !!children,
          },
          PaperProps?.className
        ),
      }}
    >
      {dropdownOptions.map((item) => (
        <MenuItem
          key={item.value}
          onClick={() => {
            if (internal.includes(item.value)) {
              setInternal((array) => {
                let next: (string | number)[] = [];

                if (multiple && item.value === SELECT_ALL) {
                  next = [];
                } else {
                  next = multiple
                    ? array
                        .filter((value) => value !== item.value)
                        .filter((value) => value !== SELECT_ALL)
                    : [];
                }

                onChange?.(next.filter((o) => o !== SELECT_ALL));
                return next;
              });
            } else {
              setInternal((array) => {
                let next: (string | number)[] = [];

                if (multiple && item.value === SELECT_ALL) {
                  next = options.map((o) => o.value);
                  next.push(SELECT_ALL);

                  // handle case where selecting all should actually empty the array
                  if (
                    !array.includes(SELECT_ALL) &&
                    array.length === options.length
                  ) {
                    next = [];
                  }
                } else {
                  next = multiple ? [...array, item.value] : [item.value];
                }

                onChange?.(next.filter((o) => o !== SELECT_ALL));
                return next;
              });
            }
          }}
        >
          {internal.includes(item.value) ||
          (!internal.includes(SELECT_ALL) && internal.length === options.length)
            ? renderMarker(true)
            : renderMarker(false)}
          {typeof item.label === "string" ? (
            <Typography>{item.label}</Typography>
          ) : (
            item.label
          )}
        </MenuItem>
      ))}
      {typeof children === "function"
        ? children({ onClose: handleClose })
        : children}
      {onSubmit ? (
        <Box px={2} py={1}>
          <Button
            variant={"contained"}
            color={"primary"}
            fullWidth
            onClick={() => {
              onSubmit(internal.filter((i) => i !== SELECT_ALL));
              if (dropdownRef?.current && closedAfterSubmit) {
                dropdownRef.current.close();
              }
            }}
          >
            {submitText || "Update"}
          </Button>
        </Box>
      ) : null}
    </Dropdown>
  );
};

export default DropdownFilter;
