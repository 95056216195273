import React from "react";
import { createMuiTheme, fade, Theme } from "@material-ui/core/styles";
import { portexColor, grey, purple, red, blue, green } from "./color";
import Info from "@material-ui/icons/Info";
import { RadioIcon, RadioIconChecked } from "../RadioGroup/RadioIcon";
import SvgKeyboardArrowRight from "../PortexIcons/SvgKeyboardArrowRight";

declare module "@material-ui/core/styles/createTypography" {
  interface TypographyOptions {
    prehead: TypographyStyleOptions;
  }

  interface Typography {
    prehead: TypographyStyle;
  }
}

const theme = createMuiTheme();
const { breakpoints } = theme;

const primary = {
  ...blue,
  dark: "#001dca",
  main: portexColor.blue500,
  light: "#6d71ff",
};
const secondary = {
  ...purple,
  dark: "#4200c6",
  main: portexColor.purple500,
  light: "#b95bff",
};

const getPlaceholderVisible = (theme: Theme) => {
  const light = theme.palette.type === "light";
  return {
    opacity: `${light ? 0.38 : 0.5} !important`,
  };
};

const placeholderVisible = getPlaceholderVisible(theme);

export const lightTheme = createMuiTheme({
  palette: {
    primary,
    secondary,
    text: {
      secondary: grey[500],
    },
    info: {
      dark: "#005fbe",
      main: portexColor.cyan500,
      light: "#65baff",
    },
    error: {
      dark: "#aa1627",
      main: portexColor.red500,
      light: "#ff827c",
    },
    success: {
      dark: "#007a3a",
      main: portexColor.green500,
      light: "#5bdd93",
    },
    warning: {
      dark: "#c78f00",
      main: portexColor.yellow500,
      light: "#fff154",
    },
    grey,
    background: {
      default: "#F5F6F7",
    },
  },
  shadows: [
    "none",
    "0px 1px 1px -1px rgba(141, 153, 168,0.16),0px 0px 1px 0px rgba(141, 153, 168,0.11),0px 0px 3px 0px rgba(141, 153, 168,0.08)",
    "0px 2px 1px -2px rgba(141, 153, 168,0.16),0px 1px 2px 0px rgba(141, 153, 168,0.11),0px 0px 5px 0px rgba(141, 153, 168,0.08)",
    "0px 2px 3px -2px rgba(141, 153, 168,0.16),0px 2px 4px 0px rgba(141, 153, 168,0.11),0px 0px 8px 0px rgba(141, 153, 168,0.08)",
    "0px 1px 4px -1px rgba(141, 153, 168,0.16),0px 3px 5px 0px rgba(141, 153, 168,0.11),0px 0px 10px 0px rgba(141, 153, 168,0.08)",
    "0px 2px 5px -1px rgba(141, 153, 168,0.16),0px 4px 8px 0px rgba(141, 153, 168,0.11),0px 0px 14px 0px rgba(141, 153, 168,0.08)",
    "0px 2px 5px -1px rgba(141, 153, 168,0.16),0px 5px 10px 0px rgba(141, 153, 168,0.11),0px 0px 18px 0px rgba(141, 153, 168,0.08)",
    "0px 3px 5px -2px rgba(141, 153, 168,0.16),0px 6px 10px 1px rgba(141, 153, 168,0.11),0px 1px 16px 1px rgba(141, 153, 168,0.08)",
    "0px 4px 5px -3px rgba(141, 153, 168,0.16),0px 7px 10px 1px rgba(141, 153, 168,0.11),0px 2px 14px 2px rgba(141, 153, 168,0.08)",
    "0px 4px 6px -3px rgba(141, 153, 168,0.16),0px 8px 12px 1px rgba(141, 153, 168,0.11),0px 2px 16px 2px rgba(141, 153, 168,0.08)",
    "0px 5px 6px -3px rgba(141, 153, 168,0.16),0px 9px 14px 1px rgba(141, 153, 168,0.11),0px 3px 18px 3px rgba(141, 153, 168,0.08)",
    "0px 5px 7px -4px rgba(141, 153, 168,0.16),0px 10px 15px 1px rgba(141, 153, 168,0.11),0px 3px 20px 3px rgba(141, 153, 168,0.08)",
    "0px 6px 8px -4px rgba(141, 153, 168,0.16),0px 11px 17px 2px rgba(141, 153, 168,0.11),0px 4px 22px 4px rgba(141, 153, 168,0.08)",
    "0px 6px 8px -4px rgba(141, 153, 168,0.16),0px 12px 19px 2px rgba(141, 153, 168,0.11),0px 4px 24px 4px rgba(141, 153, 168,0.08)",
    "0px 6px 9px -4px rgba(141, 153, 168,0.16),0px 13px 21px 2px rgba(141, 153, 168,0.11),0px 4px 26px 4px rgba(141, 153, 168,0.08)",
    "0px 7px 9px -5px rgba(141, 153, 168,0.16),0px 14px 22px 2px rgba(141, 153, 168,0.11),0px 5px 28px 5px rgba(141, 153, 168,0.08)",
    "0px 7px 10px -5px rgba(141, 153, 168,0.16),0px 15px 24px 2px rgba(141, 153, 168,0.11),0px 5px 30px 5px rgba(141, 153, 168,0.08)",
    "0px 7px 11px -5px rgba(141, 153, 168,0.16),0px 16px 26px 2px rgba(141, 153, 168,0.11),0px 5px 32px 5px rgba(141, 153, 168,0.08)",
    "0px 8px 11px -5px rgba(141, 153, 168,0.16),0px 17px 28px 2px rgba(141, 153, 168,0.11),0px 6px 34px 6px rgba(141, 153, 168,0.08)",
    "0px 8px 12px -6px rgba(141, 153, 168,0.16),0px 18px 29px 2px rgba(141, 153, 168,0.11),0px 6px 36px 6px rgba(141, 153, 168,0.08)",
    "0px 9px 13px -6px rgba(141, 153, 168,0.16),0px 19px 31px 3px rgba(141, 153, 168,0.11),0px 7px 38px 7px rgba(141, 153, 168,0.08)",
    "0px 9px 13px -6px rgba(141, 153, 168,0.16),0px 20px 33px 3px rgba(141, 153, 168,0.11),0px 7px 40px 7px rgba(141, 153, 168,0.08)",
    "0px 9px 14px -6px rgba(141, 153, 168,0.16),0px 21px 35px 3px rgba(141, 153, 168,0.11),0px 7px 42px 7px rgba(141, 153, 168,0.08)",
    "0px 10px 14px -7px rgba(141, 153, 168,0.16),0px 22px 36px 3px rgba(141, 153, 168,0.11),0px 8px 44px 8px rgba(141, 153, 168,0.08)",
    "0px 10px 15px -7px rgba(141, 153, 168,0.16),0px 23px 38px 3px rgba(141, 153, 168,0.11),0px 8px 46px 8px rgba(141, 153, 168,0.08)",
  ],
  typography: {
    fontFamily:
      '"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;',
    button: {
      fontSize: "1rem",
      fontWeight: 700,
      textTransform: "unset",
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 400,
      color: grey["800"],
    },
    overline: {
      color: "#2D425A",
      fontSize: "0.875rem",
      letterSpacing: "0.42",
    },
    caption: {
      fontWeight: "bold",
      letterSpacing: "1.2px",
    },
    prehead: {
      lineHeight: 1.5,
      fontSize: "0.875rem",
      color: grey[500],
      textTransform: "uppercase",
      fontWeight: "bold",
    },
  },
  props: {
    // @ts-ignore, MuiAlert is in @material-ui/lab
    MuiAlert: {
      iconMapping: {
        error: <Info />,
      },
    },
    MuiAppBar: {
      color: "default",
      position: "sticky",
    },
    MuiAccordion: {
      square: true,
      elevation: 0,
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiBreadcrumbs: {
      separator: <SvgKeyboardArrowRight />,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiDivider: {
      light: true,
    },
    MuiFormControl: {
      variant: "outlined",
    },
    MuiRadio: {
      color: "primary",
      icon: <RadioIcon />,
      checkedIcon: <RadioIconChecked />,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiTabs: {
      indicatorColor: "primary",
    },
    MuiTab: {
      disableTouchRipple: true,
    },
    MuiTextField: {
      variant: "outlined",
    },
    MuiPagination: {
      shape: "rounded",
      variant: "outlined",
    },
  },
  overrides: {
    // @ts-ignore, MuiAlert is in @material-ui/lab
    MuiAlert: {
      message: {
        fontSize: "1rem",
      },
      icon: {
        opacity: 1,
        marginLeft: -4,
        marginRight: 8,
      },
      outlinedError: {
        backgroundColor: red["100"],
        "& .MuiAlert-message": {
          color: red["500"],
          fontWeight: "bold",
        },
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        backgroundColor: "unset",
        "& .MuiPickersBasePicker-pickerView": {
          boxShadow: `inset 0 0 0 1px ${grey[100]}`,
        },
        "@media (max-width: 320px)": {
          minWidth: 268,
        },
      },
    },
    MuiPickersBasePicker: {
      container: {
        "& *": {
          fontWeight: "bold",
        },
      },
      pickerView: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        minHeight: "initial",
        paddingBottom: 16,
        paddingTop: 4,
        "@media (max-width: 320px)": {
          minWidth: 268,
          "& .MuiPickersDay-day": {
            width: 32,
            height: 32,
          },
          "& .MuiPickersCalendarHeader-dayLabel": {
            width: 32,
          },
          "& .MuiPickersCalendar-transitionContainer": {
            minHeight: 196,
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        order: 1,
        "& > *": {
          textAlign: "left",
          paddingLeft: 20,
        },
      },
      iconButton: {
        "&:first-of-type": {
          order: 2,
        },
        "&:not(:first-of-type)": {
          order: 3,
          marginRight: 8,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {},
      current: {
        backgroundColor: blue["100"],
      },
    },
    MuiPaginationItem: {
      root: {
        fontWeight: "bold",
      },
      outlinedPrimary: {
        borderColor: "currentColor",
        color: primary.main,
        "&.Mui-selected": {
          backgroundColor: primary.main,
          color: "#fff",
        },
      },
    },
    // Core
    MuiCssBaseline: {
      // Global
      "@global": {
        body: {
          "& .recharts-cartesian-axis-line": {
            stroke: "#C1C8D0",
          },
          "& .recharts-text": {
            fill: "#121B26",
          },
        },
      },
    },
    MuiAppBar: {
      root: {
        width: "auto",
        boxShadow: `0 1px 0 ${grey["200"]}`,
      },
      colorDefault: {
        backgroundColor: "#fff",
      },
    },
    MuiAvatar: {
      colorDefault: {
        color: "#2D425A",
        backgroundColor: "#DBDFE4",
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: "0 1px 0 0 rgba(0,0,0,0.08), 0 -1px 0 0 rgba(0,0,0,0.08)",
        "&.Mui-expanded": {
          margin: 0,
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.background.paper,
        },
        "&:before": {
          opacity: 0,
        },
        "&.MuiPaper-outlined": {
          boxShadow: "none",
          "&:not(:first-of-type)": {
            marginTop: -1,
          },
          "& .MuiAccordionSummary-root": {
            transition: "0.3s",
            "&.Mui-expanded": {
              boxShadow: `0 1px 0 0 ${grey["300"]}`,
            },
          },
        },
        "&.Por-cozy": {
          "& .MuiAccordionSummary-root": {
            padding: "0 1.25rem",
          },
          "& .MuiAccordionSummary-content": {
            margin: "1.5rem 0",
          },
          "& .MuiAccordionDetails-root": {
            padding: "1.75rem 1.5rem 2rem",
          },
        },
      },
      rounded: {
        "&:last-of-type .MuiAccordionDetails-root": {
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        "&.Mui-expanded": {
          minHeight: 48,
        },
      },
      content: {
        maxWidth: "100%",
        alignItems: "center",
        "&.Mui-expanded": {
          margin: "12px 0",
        },
      },
      expandIcon: {
        padding: 4,
        transform: "rotate(-90deg)",
        "&.Mui-expanded": {
          transform: "rotate(0deg)",
        },
        "& svg": {
          fontSize: "2rem",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        "&.Por-dim": {
          backgroundColor: grey[50],
          boxShadow: `inset 0 1px ${grey[100]}`,
          padding: "1rem",
          flexDirection: "column",
        },
      },
    },
    MuiBreadcrumbs: {
      separator: {
        minWidth: 24,
        textAlign: "center",
        marginLeft: "initial",
        marginRight: "initial",
      },
    },
    MuiButtonGroup: {
      root: {
        "&.Por-paper": {
          backgroundColor: theme.palette.background.paper,
          // Ptx- is deprecated, use Por- instead
          "& .MuiButton-root:not(.Por-selected, .Ptx-selected)": {
            color: theme.palette.text.primary,
            fontWeight: "bold",
            borderColor: grey["200"],
            "&:hover": {
              backgroundColor: grey["50"],
              borderColor: grey["300"],
            },
          },
        },
      },
      grouped: {
        paddingTop: 11,
        paddingBottom: 11,
        lineHeight: 1.15,
        fontWeight: "normal",
        "&:hover": {
          zIndex: 1,
        },
        // Ptx- is deprecated, use Por- instead
        "&.Ptx-selected, &.Por-selected": {
          zIndex: 1,
          fontWeight: "bold",
          backgroundColor: blue["100"],
          color: primary.main,
          "&.MuiButton-outlinedSecondary": {
            backgroundColor: purple["100"],
            color: secondary.main,
          },
        },
        "&.MuiButton-sizeLarge": {
          paddingTop: 9,
          paddingBottom: 9,
          lineHeight: theme.typography.button.lineHeight,
        },
      },
      groupedOutlinedHorizontal: {
        "&:not(:last-child)": {
          borderRightColor: "",
        },
      },
      groupedOutlinedVertical: {
        "&:not(:last-child)": {
          borderBottomColor: "auto", // auto is not valid but to override the default theme
        },
      },
    },
    MuiButton: {
      root: {
        padding: "6px 12px",
        "&.justify-start": {
          justifyContent: "flex-start",
        },
        "&.Por-bg-red": {
          backgroundColor: red["500"],
          color: "#fff",
          "&:hover": {
            backgroundColor: fade(red["500"], 0.8),
          },
        },
        "&.Por-bg-green": {
          backgroundColor: green["500"],
          color: "#fff",
          "&:hover": {
            backgroundColor: fade(green["500"], 0.8),
          },
        },
        "&.Por-paper": {
          backgroundColor: theme.palette.background.paper,
          color: grey[500],
          "&:hover": {
            color: red[500],
            backgroundColor: red[100],
          },
        },
      },
      startIcon: {
        marginRight: 4,
        "& .MuiChip-root": {
          margin: "0 4px",
        },
      },
      text: {
        padding: "6px 12px",
      },
      sizeLarge: {
        fontSize: "1rem",
        padding: "10px 16px",
        "& $startIcon": {
          marginRight: "0.75rem",
          marginLeft: 0,
        },
      },
      outlined: {
        padding: "6px 12px",
        transition: theme.transitions.create(["box-shadow", "border"], {
          duration: theme.transitions.duration.short,
        }),
        "&.Por-bg-white": {
          backgroundColor: theme.palette.common.white,
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: portexColor.blue300,
        },
        "&$disabled": {
          backgroundColor: portexColor.blue100,
          color: portexColor.blue300,
        },
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: portexColor.purple300,
        },
        "&$disabled": {
          backgroundColor: portexColor.purple100,
          color: portexColor.purple300,
        },
      },
      outlinedPrimary: {
        "&:hover": {
          backgroundColor: portexColor.blue500,
          color: "#fff",
        },
        "&$disabled": {
          borderColor: portexColor.blue300,
          color: portexColor.blue300,
        },
      },
      outlinedSecondary: {
        "&:hover": {
          backgroundColor: portexColor.purple500,
          color: "#fff",
        },
        "&$disabled": {
          borderColor: portexColor.purple300,
          color: portexColor.purple300,
        },
      },
      disabled: {},
    },
    MuiCardMedia: {
      root: {
        backgroundColor: grey[50],
        "&.Por-ratio-16-9": {
          paddingBottom: "56.25%",
        },
        "&.Por-ratio-4-3": {
          paddingBottom: "75%",
        },
      },
    },
    MuiCheckbox: {
      root: {
        "&.Por-dense": {
          padding: 6,
        },
      },
      colorPrimary: {
        "&.Mui-checked:not(.Mui-disabled) + *": {
          color: primary.main,
          "& a": {
            color: grey[900],
          },
        },
      },
      colorSecondary: {
        "&.Mui-checked:not(.Mui-disabled) + *": {
          color: secondary.main,
          "& a": {
            color: grey[900],
          },
        },
      },
    },
    MuiChip: {
      root: {
        height: 28,
        borderRadius: 4,
        fontSize: "1rem",
        fontWeight: "bold",
        color: grey["800"],
        backgroundColor: grey["100"],
        "&.Por-pilled": {
          borderRadius: 20,
        },
        "&.Por-light": {
          backgroundColor: grey["50"],
          color: grey["500"],
        },
        "&.Por-dimGrey": {
          // TODO verify with Tash about the bgcolor, do we need both grey[50] (Por-light) and grey[100]?          backgroundColor: grey["100"],
          backgroundColor: grey[100],
          color: grey[500],
        },
        "&.Por-lightGreen": {
          backgroundColor: green[100],
          color: green[500],
        },
        "&.Por-lightPurple": {
          backgroundColor: purple[100],
          color: purple[500],
        },
        "&.Por-lightBlue": {
          backgroundColor: blue[100],
          color: blue[500],
        },
        "&.Por-air": {
          backgroundColor: "#7E71C3",
          color: "#fff",
        },
      },
      sizeSmall: {
        fontSize: "0.875rem",
      },
      label: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      clickable: {
        height: 32,
        borderRadius: 20,
        minWidth: 76,
        border: "1px solid",
        borderColor: "rgba(0,0,0,0)",
        fontSize: "0.875rem",
        "& .MuiChip-label": {
          padding: "0 16px",
        },
      },
    },
    MuiDivider: {
      root: {
        "&.Por-my-2": {
          marginTop: 8,
          marginBottom: 8,
        },
        "&.Por-my-4": {
          marginTop: 16,
          marginBottom: 16,
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedRight: {
        borderLeft: "none",
        boxShadow: "-3px 6px 6px #00000014",
      },
      paperAnchorDockedLeft: {
        borderRight: "none",
        boxShadow: "3px 6px 6px #00000014",
      },
    },
    MuiDialogTitle: {
      root: {
        display: "flex",
        padding: "16px 20px",
        "& > *:first-child": {
          fontWeight: "bold",
          fontSize: "1.125rem",
        },
        "& .Por-close": {
          margin: "-8px -12px -8px auto",
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: "16px 20px",
        "&.Por-p-0": {
          padding: 0,
        },
      },
      dividers: {
        padding: "16px 20px",
      },
    },
    MuiDialogActions: {
      root: {
        padding: "16px 20px",
        "&.Por-justify-between": {
          justifyContent: "space-between",
        },
      },
      spacing: {
        "& > :not(:first-child)": {
          marginLeft: 16,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
    MuiMenuItem: {
      root: {
        '&[data-value=""]': {
          color: "rgba(0,0,0,0.64)",
        },
      },
    },
    MuiInputBase: {
      root: {
        lineHeight: "1.125em",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "none !important",
        position: "relative",
        marginBottom: "0.75em",
        color: theme.palette.text.primary,
        fontWeight: "bold",
        lineHeight: 1.2,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        "&:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled):hover": {
          "& $notchedOutline": {
            borderColor: grey["500"],
          },
        },
        "&.Mui-error": {
          backgroundColor: red["100"],
        },
        "&.Mui-disabled": {
          backgroundColor: grey["100"],
        },
        "&&& .MuiInputBase-input": {
          // tripple '&' to override default behaviour
          "&::-webkit-input-placeholder": placeholderVisible,
          "&::-moz-placeholder": placeholderVisible, // Firefox 19+
          "&:-ms-input-placeholder": placeholderVisible, // IE11
          "&::-ms-input-placeholder": placeholderVisible, // Edge
        },
      },
      adornedStart: {
        paddingLeft: 10,
      },
      input: {
        padding: 12,
        height: "auto",
      },
      notchedOutline: {
        borderColor: grey["300"],
        top: 0,
        "& > *": {
          display: "none",
        },
      },
    },
    MuiRadio: {
      root: {
        padding: 6,
        "&.Mui-checked + *": {
          fontWeight: "bold",
        },
        "&.Mui-disabled": {
          "& .RadioIcon-unchecked, .RadioIcon-checked": {
            borderColor: grey["500"],
          },
        },
      },
      colorPrimary: {
        "& + *": {
          color: primary.main,
        },
      },
      colorSecondary: {
        "& + *": {
          color: secondary.main,
        },
        "& .RadioIcon-unchecked, .RadioIcon-checked": {
          borderColor: secondary.main,
        },
        "& .RadioIcon-checked": {
          backgroundColor: purple["100"],
          "& .RadioIconChecked": {
            backgroundColor: purple["500"],
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        "& > .MuiTypography-root:first-child, & > .MuiFormLabel-root:not(.MuiInputLabel-root)": {
          marginBottom: "0.75em",
          fontWeight: "bold",
          lineHeight: 1.2,
          color: theme.palette.text.primary,
        },
        "&.Por-horizontal": {
          "& .MuiInputBase-formControl": {
            flexGrow: 1,
          },
          [breakpoints.up("sm")]: {
            flexDirection: "row",
            alignItems: "center",
            "& label": {
              paddingRight: 12,
              flexShrink: 0,
              flexBasis: `30%`,
              margin: `0 !important`,
            },
          },
          [breakpoints.up("md")]: {
            "& label": {
              flexBasis: `${500 / 12}%`,
            },
          },
          [breakpoints.up("lg")]: {
            "& label": {
              flexBasis: `${400 / 12}%`,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: red["500"],
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 28,
        marginLeft: -8,
        "&.Por-confirmation": {
          marginLeft: -12,
          marginRight: "unset",
          alignItems: "flex-start",
          "& .MuiCheckbox-root": {
            marginTop: -9,
          },
        },
      },
      label: {
        marginLeft: 4,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 2,
        marginRight: 0,
      },
    },
    MuiSlider: {
      root: {
        height: 8,
      },
      rail: {
        height: 8,
        backgroundColor: grey[400],
        borderRadius: 8,
      },
      track: {
        height: 8,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      thumb: {
        height: 16,
        width: 16,
        border: "2px solid #fff",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.38)",
        "&:hover": {
          boxShadow: "0 2px 8px 1px rgba(0,0,0,0.38)",
        },
        "&.MuiSlider-active, &.Mui-focusVisible": {
          boxShadow: "0 2px 8px 1px rgba(0,0,0,0.38)",
        },
      },
      valueLabel: {
        left: "calc(-50%)",
        top: -24,
        "& > span": {
          width: "auto",
          minWidth: 24,
          padding: "0 0.5rem",
          height: 24,
          backgroundColor: "#000",
          borderRadius: 4,
          transform: "none",
          "&:before": {
            content: '""',
            width: 6,
            height: 6,
            transform: "rotate(-45deg)",
            backgroundColor: "#000",
            position: "absolute",
            bottom: -3,
            left: "calc(50% - 3px)",
            borderBottomLeftRadius: 1,
          },
          "& > span": {
            transform: "none",
            fontWeight: "bold",
          },
        },
      },
      mark: {
        opacity: 0,
      },
      markActive: {
        opacity: 0,
      },
      markLabel: {
        top: 32,
        color: theme.palette.text.primary,
        fontWeight: "bold",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: "unset",
      },
    },
    MuiStep: {
      root: {
        zIndex: 1,
      },
    },
    MuiStepConnector: {
      vertical: {
        zIndex: 0,
        paddingBottom: 0,
        marginLeft: 11,
      },
      lineVertical: {
        borderLeftWidth: 2,
        borderColor: primary.main,
        minHeight: 56,
        margin: "-16px 0",
      },
    },
    MuiStepLabel: {
      labelContainer: {
        "& > *:nth-child(2)": {
          marginBottom: -8,
        },
      },
      label: {
        color: grey[900],
        fontWeight: "bold",
        "&.MuiStepLabel-active, &.MuiStepLabel-completed": {
          fontWeight: "bold",
        },
      },
      iconContainer: {
        alignSelf: "flex-start",
      },
    },
    MuiStepIcon: {
      root: {
        "& text": {
          fontWeight: "bold",
        },
        "&:not(.MuiStepIcon-active)": {
          "& circle": {
            strokeWidth: 2,
            // @ts-ignore `stroke` is a valid css property
            stroke: primary.main,
            r: 11,
            fill: blue[100],
          },
          "& text": {
            fill: primary.main,
          },
        },
      },
      completed: {
        backgroundColor: "#fff",
        borderRadius: "50%",
      },
    },
    MuiTableContainer: {
      root: {
        "&.Por-scrollBar": {
          "&::-webkit-scrollbar": {
            width: "0.875rem",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: grey["200"],
            borderRadius: 10,
            marginTop: 44,
            marginBottom: 8,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: grey["300"],
            borderRadius: 10,
          },
        },
      },
    },
    MuiTable: {
      root: {
        "&.Por-portalApp": {
          "& .MuiTableCell-root": {
            verticalAlign: "top",
          },
          "& .MuiTableCell-head": {
            backgroundColor: "#fff",
            borderBottom: "1px solid",
            borderBottomColor: "#C1C8D0",
            borderTop: "1px solid",
            borderTopColor: grey["100"],
          },
          "& .MuiTableBody-root .MuiTableRow-root": {
            backgroundColor: theme.palette.background.paper,
            "&:nth-of-type(odd)": {
              backgroundColor: "#F7F8F9",
            },
          },
          [breakpoints.up("lg")]: {
            "& .MuiTableCell-head": {
              paddingTop: "1rem",
              paddingBottom: "1rem",
            },
            "& .MuiTableCell-root:first-child": {
              paddingLeft: "2rem",
            },
            "& .MuiTableCell-root:last-child": {
              paddingRight: "2rem",
            },
          },
        },
        "&.Por-align-top .MuiTableCell-root": {
          verticalAlign: "top",
        },
        "&.Por-align-middle .MuiTableCell-root": {
          verticalAlign: "middle",
        },
        "&.Por-padding-dense .MuiTableCell-root": {
          "&:not(:first-of-type)": {
            paddingLeft: "0.5rem",
          },
          "&:not(:last-of-type)": {
            paddingRight: "0.5rem",
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-of-type(even)": {
          backgroundColor: "#F7F8F9",
        },
        "&.Mui-selected": {
          backgroundColor: blue["100"],
          "&:hover": {
            backgroundColor: blue["100"],
          },
          "& *": {
            color: primary.main,
          },
        },
      },
      head: {
        backgroundColor: grey["100"],
      },
      hover: {
        cursor: "pointer",
      },
    },
    MuiPaper: {
      root: {
        "&.Por-border-grey200": {
          border: "2px solid",
          borderColor: grey["200"],
        },
        "&.Por-border-grey300": {
          border: "2px solid",
          borderColor: grey["300"],
        },
        "&.Por-border-green": {
          border: "2px solid",
          borderColor: green["500"],
        },
        "&.Por-border-blue": {
          border: "2px solid",
          borderColor: blue["500"],
        },
        "&.Por-outlined-light": {
          border: "1px solid",
          borderColor: grey["200"],
        },
        "&.Por-outlined-base": {
          border: "1px solid",
          borderColor: grey.base,
        },
        "&.Por-bg-grey50": {
          backgroundColor: grey["50"],
        },
        "&.Por-bg-primaryLight": {
          backgroundColor: blue["100"],
        },
      },
      outlined: {
        borderColor: grey["300"],
      },
    },
    MuiPopover: {
      paper: {
        margin: "8px 0",
        boxShadow: "0px 1px 4px #00000029",
        border: "1px solid",
        borderColor: grey[300],
      },
    },
    MuiTableCell: {
      head: {
        fontSize: "0.875rem",
        textTransform: "uppercase",
        padding: "0.5rem 1rem",
        fontWeight: "bold",
        color: "#2D425A",
        verticalAlign: "middle",
      },
      root: {
        borderBottom: "none",
        verticalAlign: "baseline",
      },
      body: {
        fontSize: "1rem",
        padding: "0.75rem 1rem",
      },
      paddingCheckbox: {
        verticalAlign: "inherit",
        "& + th, & + td": {
          paddingLeft: "0.5rem",
        },
      },
      stickyHeader: {
        backgroundColor: grey["100"],
      },
      sizeSmall: {
        padding: "0.5rem 0.5rem 0.875rem",
        "&:first-child": {
          paddingLeft: "0.75rem",
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    },
    MuiTab: {
      root: {
        paddingLeft: 20,
        paddingRight: 20,
        "&.Mui-selected": {
          color: primary.main,
          "& .Por-tabBadge": {
            backgroundColor: blue["100"],
            color: blue["500"],
          },
        },
        "& .Por-tabBadge": {
          marginLeft: 6,
          padding: "0 6px",
          fontSize: "0.875rem",
          lineHeight: 1.5,
        },
        [breakpoints.up("sm")]: {
          minWidth: "auto",
          "&:not(:first-of-type)": {
            marginLeft: "0.5rem",
          },
        },
      },
      textColorInherit: {
        opacity: 1,
      },
      wrapper: {
        flexDirection: "row",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "max(14px, 0.875rem)",
        backgroundColor: "#121B26",
        fontWeight: "normal",
        [theme.breakpoints.up("md")]: {
          padding: "0.5rem 0.75rem",
        },
        "&.Por-secondary-tinted": {
          border: "1px solid",
          color: purple[500],
          backgroundColor: purple[100],
          "& .MuiTooltip-arrow": {
            color: purple[100],
            "&:before": {
              border: "1px solid",
              borderColor: purple[500],
            },
          },
        },
        "&.Por-max-w-sm": {
          maxWidth: 192,
        },
      },
      arrow: {
        color: "#121B26",
        "&.Por-force-top": {
          top: "16px !important",
        },
      },
    },
  },
});
