import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15.55 10.109"
      fontSize="1.5rem"
      color="#fff"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2.384 7.725a1.192 1.192 0 101.192 1.192 1.192 1.192 0 00-1.192-1.192zM5.949 7.725a1.192 1.192 0 101.192 1.192 1.193 1.193 0 00-1.192-1.192zM12.594 7.725a1.192 1.192 0 101.192 1.192 1.193 1.193 0 00-1.192-1.192z"
      />
      <path
        fill="currentColor"
        d="M14.356 4.769l-1.192-2.384h-2.922v4.2h-.653V0H-.004v8.347h.612a1.866 1.866 0 013.554 0h.012a1.866 1.866 0 013.554 0h3.092a1.866 1.866 0 013.553 0h1.178v-2.9z"
      />
    </svg>
  );
}

export default SvgComponent;
