import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";

import Icon, { IconProps } from "../Icon/Icon";
import SvgTruck from "../PortexIcons/SvgTruck";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 0,
      "& p:first-child": {
        flex: 1,
        textAlign: "end",
      },
      "& p:last-child": {
        flex: 1,
      },
    },
    gutter: {
      minWidth: 8,
      flexBasis: "1.5rem",
      flexShrink: 20,
    },
    line: {
      position: "relative",
      "&:before, &:after": {
        display: "block",
        content: '""',
        backgroundColor: palette.grey["500"],
        position: "absolute",
      },
    },
    dashed: {
      height: 0,
      minWidth: 64,
      flexBasis: 120,
      flexShrink: 20,
      border: "1px dashed",
      borderColor: palette.grey["500"],
      "&:before, &:after": {
        width: 12,
        height: 12,
        backgroundColor: palette.grey["500"],
        top: -5,
      },
      "&:before": {
        left: -1,
        borderRadius: 10,
      },
      "&:after": {
        right: -1,
      },
    },
    minimal: {
      height: 2,
      backgroundColor: palette.grey["300"],
      minWidth: 24,
      flexBasis: 48,
      flexShrink: 20,
      "&:before, &:after": {
        width: 8,
        height: 8,
        backgroundColor: palette.grey["500"],
        position: "absolute",
        top: -3,
        borderRadius: 10,
      },
      "&:before": {
        left: -1,
      },
      "&:after": {
        right: -1,
      },
    },
    icon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    text: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  }),
  { name: "MuiRoute" }
);

export type RouteProps = {
  pickup: string;
  delivery: string;
} & ConnectorProps &
  JSX.IntrinsicElements["div"];

type ConnectorProps = {
  iconHidden?: boolean;
  iconProps?: IconProps & { as: typeof SvgIcon };
  connectorPreset?: "dashed" | "minimal";
};

const Connector = ({
  iconHidden = true,
  className,
  iconProps,
  connectorPreset = "dashed",
  ...props
}: JSX.IntrinsicElements["div"] & ConnectorProps) => {
  const styles = useStyles();
  return (
    <div className={cx(styles.line, styles[connectorPreset])} {...props}>
      {!iconHidden && (
        <Icon
          as={SvgTruck}
          variant={"filled"}
          palette={"green"}
          {...iconProps}
          className={styles.icon}
        />
      )}
    </div>
  );
};

const Route = ({
  pickup,
  delivery,
  iconProps,
  iconHidden = false,
  connectorPreset = "dashed",
  ...props
}: RouteProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root} {...props}>
      <Typography className={styles.text}>
        <b>{pickup}</b>
      </Typography>
      <div className={styles.gutter} />
      <Connector
        iconProps={iconProps}
        iconHidden={iconHidden}
        connectorPreset={connectorPreset}
      />
      <div className={styles.gutter} />
      <Typography className={styles.text}>
        <b>{delivery}</b>
      </Typography>
    </div>
  );
};

Route.Connector = Connector;

export default Route;
