import React, { ReactNode } from "react";
import cx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid, { GridProps, GridSize } from "@material-ui/core/Grid";
import TextInput, { TextInputProps } from "../TextInput/TextInput";
import Typography from "@material-ui/core/Typography";

enum WeightUnitType {
  Lb = "LB",
  Kg = "KG",
}
export type WeightUnitValue = `${WeightUnitType}`;

export type FormTotalWeightProps = {
  totalWeight?: number;
  weightUnit?: WeightUnitValue;
  onChange?: (value: {
    totalWeight?: number;
    weightUnit?: WeightUnitValue;
  }) => void;
  onChangeWeight?: (value?: number) => void;
  onChangeUnit?: (unit: WeightUnitValue) => void;
  /**
   * @deprecated use labelSuffix instead
   */
  summaryDisplayed?: boolean;
  labelSuffix?: ReactNode;
  gutter?: GridProps["spacing"];
  columnSize?: [GridSize, GridSize];
} & Omit<TextInputProps, "value" | "onChange">;

const useStyles = makeStyles(
  () => ({
    label: {
      display: "flex",
      alignItems: "baseline",
      whiteSpace: "nowrap",
    },
    summary: {
      lineHeight: 1.2,
      marginLeft: "0.25rem",
    },
  }),
  { name: "MuiFormTotalWeight" }
);

const FormTotalWeight = ({
  // layout
  gutter = 1,
  columnSize = [6, 6],
  // -------------
  // functionality
  totalWeight,
  weightUnit,
  onChange,
  onChangeWeight,
  onChangeUnit,
  labelSuffix,
  summaryDisplayed,
  ...props
}: FormTotalWeightProps) => {
  const styles = useStyles();
  const { label, ...restProps } = props;
  return (
    <Grid container spacing={gutter}>
      <Grid item xs={columnSize[0]}>
        <TextInput
          InputLabelProps={{ className: styles.label }}
          label={
            <>
              {label ? label : "Total Weight"}
              {summaryDisplayed && totalWeight && weightUnit && (
                <Typography className={styles.summary}>
                  • Weight: {totalWeight} {weightUnit.toLowerCase()}
                </Typography>
              )}
              {labelSuffix && (
                <Typography className={styles.summary}>
                  {labelSuffix}
                </Typography>
              )}
            </>
          }
          margin={"normal"}
          fullWidth
          {...restProps}
          value={totalWeight ?? ""}
          onChange={(event) => {
            onChange?.({
              totalWeight: Number(event.target.value) || undefined,
              weightUnit,
            });
            onChangeWeight?.(Number(event.target.value) || undefined);
          }}
        />
      </Grid>
      <Grid item xs={columnSize[1]}>
        <FormControl fullWidth margin={"normal"}>
          <FormLabel>&nbsp;</FormLabel>
          <ButtonGroup fullWidth color="primary">
            <Button
              className={cx(weightUnit === WeightUnitType.Lb && "Ptx-selected")}
              onClick={() => {
                onChange?.({ weightUnit: WeightUnitType.Lb, totalWeight });
                onChangeUnit?.(WeightUnitType.Lb);
              }}
            >
              lb
            </Button>
            <Button
              className={cx(weightUnit === WeightUnitType.Kg && "Ptx-selected")}
              onClick={() => {
                onChange?.({ weightUnit: WeightUnitType.Kg, totalWeight });
                onChangeUnit?.(WeightUnitType.Kg);
              }}
            >
              kg
            </Button>
          </ButtonGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormTotalWeight;
