import React, { ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { portexColor } from "../theme/color";
import { CustomColor } from "../constant";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: (props: Pick<StatBannerProps, "palette" | "classes">) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 24,
      paddingRight: 24,
      position: "relative",
      borderRadius: 4,
      overflow: "hidden",
      minHeight: 78,
      backgroundColor: palette.background.paper,
      boxShadow: `inset 0 0 0 1px ${palette.grey["300"]}`,
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        left: 0,
        width: 8,
        top: 0,
        bottom: 0,
        backgroundColor: (portexColor as any)[`${props.palette}500`] ?? "",
      },
    }),
    fullWidth: {
      width: "100%",
    },
  }),
  { name: "MuiStatBanner" }
);

export type StatBannerClassKey = keyof ReturnType<typeof useStyles>;
export type StatBannerClasses = Partial<Record<StatBannerClassKey, string>>;

export type StatBannerProps = {
  label: ReactNode;
  value: ReactNode;
  palette?: CustomColor;
  classes?: StatBannerClasses;
  fullWidth?: boolean;
} & JSX.IntrinsicElements["div"];

const StatBanner = ({
  label,
  value,
  palette,
  className,
  fullWidth,
  classes,
  ...props
}: StatBannerProps) => {
  const styles = useStyles({ palette, classes });
  return (
    <div
      className={cx(styles.root, className, fullWidth && styles.fullWidth)}
      {...props}
    >
      <Typography align="left">
        <b>{label}</b>
      </Typography>
      <Typography variant={"h4"} align="right">
        <b>{value}</b>
      </Typography>
    </div>
  );
};

export default StatBanner;
