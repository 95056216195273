import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "inline-block",
      ...theme.typography.prehead,
    },
  }),
  { name: "PtxPrehead" }
);

export type PreheadProps = JSX.IntrinsicElements["span"];

const Prehead = ({ className, ...props }: PreheadProps) => {
  const { root } = useStyles();
  return <span {...props} className={cx(root, className)} />;
};

export default Prehead;
