import React, { PropsWithChildren, ReactElement } from "react";
import cx from "clsx";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    root: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      padding: "1rem 0",
      margin: "-0.25rem",
      "& > *": {
        padding: "0.25rem",
      },
    },
    child: {
      flexGrow: 1,
      display: "flex",
      "& > *": {
        flex: 1,
      },
    },
    small: {
      paddingLeft: "0.5rem",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
    },
    medium: {
      paddingLeft: "1rem",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    },
    big: {
      paddingLeft: "1.75rem",
    },
    none: {
      padding: 0,
    },
    alignStart: {
      alignSelf: "flex-start",
    },
    label: ({ labelWidth }: { labelWidth: string | number }) => ({
      "& > :first-child": {
        minWidth: labelWidth,
      },
    }),
  }),
  { name: "MuiDataRow" }
);

export type DataRowProps = {
  label: string | ReactElement;
  labelWidth?: string | number;
  labelAlignStart?: boolean;
  labelProps?: TypographyProps;
  spacing?: "none" | "small" | "medium" | "big";
} & JSX.IntrinsicElements["div"];

const DataRow = ({
  label,
  labelWidth = 160,
  labelAlignStart,
  labelProps,
  spacing = "big",
  className,
  children,
  ...props
}: PropsWithChildren<DataRowProps>) => {
  const styles = useStyles({ labelWidth });
  return (
    <div
      className={cx(styles.root, styles.label, styles[spacing], className)}
      {...props}
    >
      <Typography
        {...labelProps}
        className={cx(
          labelAlignStart && styles.alignStart,
          labelProps?.className
        )}
      >
        {label}
      </Typography>
      <div className={styles.child}>{children}</div>
    </div>
  );
};

export default DataRow;
