import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={334}
      height={189}
      viewBox="0 0 334 189"
      {...props}
    >
      <g data-name="Group 5110" transform="translate(-617 -291)">
        <g
          data-name="Rectangle 12194"
          transform="translate(617 291)"
          fill="#fff"
          stroke="#c1c8d0"
        >
          <rect width={334} height={189} rx={4} stroke="none" />
          <rect x={0.5} y={0.5} width={333} height={188} rx={3.5} fill="none" />
        </g>
        <path
          data-name="Rectangle 12195"
          d="M621 291h78v189h-78a4 4 0 01-4-4V295a4 4 0 014-4z"
          fill="#17222f"
        />
        <rect
          data-name="Rectangle 12196"
          width={47}
          height={10}
          rx={2}
          transform="translate(633 300)"
          fill="#265df1"
        />
        <rect
          data-name="Rectangle 12197"
          width={47}
          height={10}
          rx={2}
          transform="translate(633 326)"
          fill="#363f4b"
        />
        <rect
          data-name="Rectangle 12202"
          width={38}
          height={10}
          rx={2}
          transform="translate(633 374)"
          fill="#363f4b"
        />
        <rect
          data-name="Rectangle 12198"
          width={47}
          height={10}
          rx={2}
          transform="translate(633 342)"
          fill="#363f4b"
        />
        <rect
          data-name="Rectangle 12201"
          width={38}
          height={10}
          rx={2}
          transform="translate(633 390)"
          fill="#363f4b"
        />
        <rect
          data-name="Rectangle 12199"
          width={47}
          height={10}
          rx={2}
          transform="translate(633 358)"
          fill="#363f4b"
        />
        <rect
          data-name="Rectangle 12200"
          width={38}
          height={10}
          rx={2}
          transform="translate(633 406)"
          fill="#363f4b"
        />
        <g data-name="Group 5106">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <g data-name="Group 5116" transform="translate(0 68)">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <g data-name="Group 5109" transform="translate(0 34)">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <g data-name="Group 5114" transform="translate(0 102)">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <g data-name="Group 5107" transform="translate(0 17)">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <g data-name="Group 5115" transform="translate(0 85)">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <g data-name="Group 5108" transform="translate(0 51)">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <g data-name="Group 5113" transform="translate(0 119)">
          <rect
            data-name="Rectangle 12203"
            width={234}
            height={13}
            rx={2}
            transform="translate(708 326)"
            fill="#eceef1"
          />
          <rect
            data-name="Rectangle 12204"
            width={88}
            height={2}
            rx={1}
            transform="translate(713 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12205"
            width={54}
            height={2}
            rx={1}
            transform="translate(804 332)"
            fill="#acb0b5"
          />
          <rect
            data-name="Rectangle 12207"
            width={21}
            height={2}
            rx={1}
            transform="translate(915 332)"
            fill="#265df1"
          />
        </g>
        <rect
          data-name="Rectangle 12208"
          width={54}
          height={2}
          rx={1}
          transform="translate(708 303)"
          fill="#acb0b5"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
