import React from "react";
import cx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";
import MuiButton, {
  ButtonTypeMap,
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

interface ExtraButtonProps {
  loading?: boolean;
  loadingElement?: React.ReactNode;
  state?: "error" | "success";
}

export type ButtonProps = ExtraButtonProps & MuiButtonProps;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      "&$loading": {
        color: "transparent",
      },
    },
    loader: {
      display: "flex",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      opacity: 0,
      color: theme.palette.grey[500],
    },
    loading: {
      "& $loader": {
        opacity: 1,
      },
    },
    error: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.08),
      },
      "&.MuiButton-contained": {
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        "&:hover": {
          backgroundColor: fade(theme.palette.error.main, 0.8),
        },
      },
      "&.MuiButton-outlined": {
        borderColor: theme.palette.error.main,
      },
    },
    success: {
      color: theme.palette.success.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.success.main, 0.08),
      },
      "&.MuiButton-contained": {
        backgroundColor: theme.palette.success.main,
        color: "#fff",
        "&:hover": {
          backgroundColor: fade(theme.palette.success.main, 0.8),
        },
      },
      "&.MuiButton-outlined": {
        borderColor: theme.palette.success.main,
      },
    },
  }),
  { name: "PorButon" }
);

const Button = React.forwardRef(function Button(
  { children, loading, loadingElement, disabled, state, ...props },
  ref
) {
  const classes = useStyles(props);
  const indicatorColor = (function getIndicatorColor() {
    if (props.color === "primary" || props.color === "secondary") {
      return props.color;
    }
    return "inherit";
  })();
  return (
    <MuiButton
      ref={ref}
      {...props}
      classes={{
        ...props.classes,
        root: cx(
          classes.root,
          loading && classes.loading,
          state && classes[state],
          props.classes?.root
        ),
      }}
      disabled={disabled || loading}
    >
      {children}
      {loading && (
        <div className={cx(classes.loader)}>
          {loadingElement || (
            <CircularProgress size="1rem" color={indicatorColor} />
          )}
        </div>
      )}
    </MuiButton>
  );
}) as ExtendButtonBase<ButtonTypeMap<ExtraButtonProps>>;

export default Button;
