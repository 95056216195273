import React, { PropsWithChildren, ReactElement } from "react";
import cx from "clsx";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<
  Theme,
  Pick<LocationProps, "gutter" | "connectorSnapped">
>(
  ({ palette }) => ({
    root: {
      "& + &": {
        "& $dot": {
          borderRadius: 0,
        },
      },
      "&:last-of-type": {
        "& $sub": {
          paddingBottom: 0,
        },
      },
    },
    head: {
      display: "flex",
      alignItems: "center",
    },
    dotContainer: (props) => ({
      width: props.gutter,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
      position: "relative",
    }),
    dot: {
      width: 8,
      height: 8,
      borderRadius: 8,
      backgroundColor: palette.text.primary,
    },
    square: {
      borderRadius: 0,
    },
    sub: (props) => ({
      position: "relative",
      paddingTop: 4,
      paddingLeft: props.gutter,
      paddingBottom: "1.5rem",
    }),
    connected: (props) => ({
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        width: 2,
        backgroundColor: palette.grey["300"],
        left: `calc(calc(${
          typeof props.gutter === "number" ? `${props.gutter}px` : props.gutter
        } / 2) - 1px)`,
        top: props.connectorSnapped ? -8 : 0,
        bottom: props.connectorSnapped ? -8 : 0,
        zIndex: 0,
      },
    }),
    light: {
      textTransform: "uppercase",
      color: palette.grey["500"],
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  }),
  { name: "Location" }
);

export type LocationProps = {
  className?: string;
  light?: boolean;
  label: string | ReactElement;
  gutter?: number | string;
  connected?: boolean;
  connectorSnapped?: boolean;
  dotShape?: "circle" | "square";
};

const Head = ({ className, ...props }: TypographyProps) => {
  const classes = useStyles({ gutter: 0 });
  return <Typography className={cx(classes.light, className)} {...props} />;
};

const Location = ({
  className,
  label,
  light,
  children,
  gutter = "2rem",
  dotShape = "circle",
  connected,
  ...props
}: PropsWithChildren<LocationProps>) => {
  const classes = useStyles({ gutter, ...props });
  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.head}>
        <div className={classes.dotContainer}>
          <div className={cx(classes.dot, classes[dotShape])} />
        </div>
        {typeof label === "string" ? (
          <Typography className={cx(light && classes.light)}>
            {label}
          </Typography>
        ) : (
          label
        )}
      </div>
      <div className={cx(classes.sub, connected && classes.connected)}>
        {children}
      </div>
    </div>
  );
};

Location.Head = Head;

export default Location;
