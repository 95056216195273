import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "../theme/color";

function ArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={16}
      viewBox="0 0 24 24"
      width={16}
      fill="currentColor"
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
    </svg>
  );
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "inline-block",
    },
    label: {
      ...theme.typography.body2,
    },
    value: {
      fontSize: 32,
      fontWeight: "bold",
    },
    change: {
      display: "flex",
      alignItems: "center",
    },
    positive: {
      color: theme.palette.success.main,
    },
    negative: {
      color: theme.palette.error.main,
    },
    icon: {
      marginRight: "0.5rem",
      borderRadius: 20,
      lineHeight: 0,
      padding: "4px",
      transition: "0.3s",
    },
    iconPositive: {
      backgroundColor: green[100],
      transform: "rotate(-45deg)",
    },
    iconNegative: {
      backgroundColor: red[100],
      transform: "rotate(45deg)",
    },
  }),
  { name: "Statistic" }
);

export type StatisticProps = {
  label?: React.ReactNode;
  value?: React.ReactNode;
  percentage?: number;
} & Omit<JSX.IntrinsicElements["div"], "ref">;

const Statistic = React.forwardRef<HTMLDivElement, StatisticProps>(
  function Statistic({ label, value, percentage, ...props }, ref) {
    const classes = useStyles();
    return (
      <div ref={ref} {...props}>
        <div className={classes.label}>{label}</div>
        <div className={classes.value}>{value}</div>
        {percentage && percentage !== 0 && (
          <div
            className={cx(classes.change, {
              [classes.positive]: percentage > 0,
              [classes.negative]: percentage < 0,
            })}
          >
            <div
              className={cx(classes.icon, {
                [classes.iconPositive]: percentage > 0,
                [classes.iconNegative]: percentage < 0,
              })}
            >
              <ArrowRight />
            </div>
            <div>{percentage.toString().replace("-", "")}%</div>
          </div>
        )}
      </div>
    );
  }
);

export default Statistic;
