import React from "react";
import cx from "clsx";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormLabel, { FormLabelProps } from "@material-ui/core/FormLabel";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment, {
  InputAdornmentProps,
} from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { useTextInputStyles } from "../TextInput/TextInput.styles";
import { SvgKeyboardArrowDown, SvgClear } from "../PortexIcons";

type PickedSelectProps =
  | "displayEmpty"
  | "IconComponent"
  | "native"
  | "defaultValue"
  | "value"
  | "onChange"
  | "onFocus"
  | "onBlur"
  | "inputRef"
  | "placeholder"
  | "autoComplete"
  | "autoFocus";

export type SelectInputProps = {
  label?: string;
  emptyOption?: string;
  horizontal?: boolean;
  InputLabelProps?: FormLabelProps;
  SelectProps?: Omit<SelectProps, PickedSelectProps>;
  InputAdornmentProps?: InputAdornmentProps;
  startIcon?: React.ReactElement;
  highlight?: boolean;
  large?: boolean;
  onClear?: () => void;
  clearIcon?: React.ReactNode;
} & Omit<
  FormControlProps,
  "hiddenLabel" | "size" | "variant" | "onChange" | "onFocus" | "onBlur"
> &
  Pick<SelectProps, PickedSelectProps>;

const SelectInput = ({
  id,
  large,
  label,
  children,
  className,
  highlight,
  horizontal,
  InputLabelProps,
  displayEmpty,
  native,
  autoFocus,
  autoComplete,
  placeholder,
  defaultValue: defaultValueProp,
  value,
  inputRef,
  onChange,
  onFocus,
  onBlur,
  onClear, // custom prop, not React.EventHandler
  clearIcon,
  SelectProps,
  startIcon,
  IconComponent,
  InputAdornmentProps,
  ...props
}: SelectInputProps) => {
  const textInput = useTextInputStyles();
  const defaultValue = defaultValueProp ?? (placeholder ? "" : undefined);
  const isEmpty = placeholder && !(value ?? defaultValue);
  const isClearable = (onClear || clearIcon) && !isEmpty;
  const hasStartIcons = startIcon && isClearable;
  return (
    <FormControl
      className={cx(horizontal && "Por-horizontal", className)}
      {...props}
    >
      {label && (
        <FormLabel
          htmlFor={id}
          {...InputLabelProps}
          className={cx(textInput.formLabel, InputLabelProps?.className)}
        >
          {label}
        </FormLabel>
      )}
      <Select
        {...SelectProps}
        {...{
          displayEmpty: !!placeholder || displayEmpty,
          native,
          autoFocus,
          autoComplete,
          placeholder,
          inputRef,
          defaultValue,
          value,
          onChange,
          onFocus,
          onBlur,
        }}
        variant="outlined"
        className={cx(
          textInput.selectInput,
          textInput.outlinedRoot,
          highlight && textInput.selectInputHighlight,
          isEmpty && textInput.placeholder,
          hasStartIcons && textInput.selectStartIcons
        )}
        classes={{
          ...SelectProps?.classes,
          icon: cx(textInput.selectIcon, SelectProps?.classes?.icon),
          select: cx(
            textInput.outlinedInput,
            large && textInput.outlinedInputLarge,
            SelectProps?.classes?.select
          ),
        }}
        IconComponent={IconComponent || SvgKeyboardArrowDown}
        {...((startIcon || isClearable) && {
          startAdornment: (
            <InputAdornment
              {...InputAdornmentProps}
              classes={{
                ...InputAdornmentProps?.classes,
                positionStart: cx(
                  textInput.adornmentStart,
                  InputAdornmentProps?.classes?.positionStart
                ),
              }}
              position="start"
            >
              {startIcon}
              {isClearable && (
                <IconButton onClick={onClear} size="small">
                  {clearIcon || <SvgClear fontSize="small" />}
                </IconButton>
              )}
            </InputAdornment>
          ),
        })}
      >
        {placeholder && (
          <MenuItem style={{ display: "none" }} value="">
            {placeholder}
          </MenuItem>
        )}
        {children}
      </Select>
    </FormControl>
  );
};
SelectInput.displayName = "SelectInput";

export default SelectInput;
