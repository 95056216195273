import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { portexColor } from "../theme/color";
import { CustomColor } from "../constant";
import { OverridableComponent } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    textDecoration: "initial",
  },
  mode: (props: Pick<ModeLaneBannerProps, "palette">) => ({
    background: (portexColor as any)[`${props.palette}500`],
    color: "#fff",
    padding: theme.spacing(0.75, 1.5),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    ...theme.typography.body1,
    fontWeight: "bold",
  }),
  lane: (props: Pick<ModeLaneBannerProps, "palette">) => ({
    background: (portexColor as any)[`${props.palette}100`],
    color: (portexColor as any)[`${props.palette}500`],
    padding: theme.spacing(0.75, 1.5),
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    ...theme.typography.body1,
    fontWeight: "bold",
  }),
}));

export type ModeLaneBannerProps = {
  mode: React.ReactNode;
  lanes: Array<string>;
  laneSeparator?: React.ReactNode;
  palette?: CustomColor;
};

const ModeLaneBanner = React.forwardRef(function ModeLaneBanner(
  {
    component: Component = "div",
    mode,
    lanes,
    laneSeparator = " — ",
    palette = "blue",
    className,
    ...props
  },
  ref
) {
  const classes = useStyles({ palette });
  return (
    <Component ref={ref} {...props} className={cx(classes.root, className)}>
      <div className={classes.mode}>{mode}</div>
      <div className={classes.lane}>
        {lanes.map((aLane, index) => (
          <React.Fragment key={index}>
            {aLane}
            {index < lanes.length - 1 && laneSeparator}
          </React.Fragment>
        ))}
      </div>
    </Component>
  );
}) as OverridableComponent<
  ModeLaneBannerProps,
  JSX.IntrinsicElements["div"],
  HTMLDivElement
>;

export default ModeLaneBanner;
