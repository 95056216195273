import React, { PropsWithChildren } from "react";
import Box from "@material-ui/core/Box";
import { blue } from "../theme/color";

export const RadioIcon = ({
  checked,
  size = 28,
  children,
}: PropsWithChildren<{
  checked?: boolean;
  size?: number;
}>) => (
  <Box
    className={checked ? "RadioIcon-checked" : "RadioIcon-unchecked"}
    border={`1px solid`}
    borderColor="primary.main"
    width={size}
    height={size}
    borderRadius={size}
    p={"5px"}
    {...(checked && {
      bgcolor: blue["100"],
    })}
  >
    {children}
  </Box>
);

export const RadioIconChecked = () => (
  <RadioIcon checked>
    <Box
      className="RadioIconChecked"
      borderRadius={28}
      bgcolor="primary.main"
      width={"100%"}
      height={"100%"}
    />
  </RadioIcon>
);
