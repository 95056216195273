import React from "react";

import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import SelectInput, { SelectInputProps } from "../SelectInput/SelectInput";

enum TruckLoadType {
  Ftl = "FTL",
  Ltl = "LTL",
  Drayage = "DRAYAGE",
}

export type TruckLoadValue = `${TruckLoadType}`;

const defaultOptions = [
  { id: TruckLoadType.Ftl, label: "Full Truckload", color: "primary" as const },
  {
    id: TruckLoadType.Drayage,
    label: "Drayage",
    color: "secondary" as const,
    initial: "DR",
  },
  // { id: TruckLoadType.Ltl, label: "Less than Truckload", color: "default" as const },
];

function FormLoadType<
  ID = TruckLoadValue,
  T extends {
    id: ID;
    label: string;
    color?: ChipProps["color"];
    initial?: string;
  } = {
    id: ID;
    label: string;
  }
>({
  ui,
  options = (defaultOptions as unknown) as T[],
  value,
  onChange,
  ...props
}: {
  ui: "list" | "select";
  options?: Array<T>;
  value?: ID;
  onChange?: (id: ID, option: T) => void;
} & Omit<SelectInputProps, "value" | "onChange">) {
  if (ui === "select") {
    return (
      <SelectInput
        fullWidth
        label="Load Type"
        margin="normal"
        {...props}
        value={value ?? ""}
        onChange={(event) => {
          const result = options.find(({ id }) => id === event.target.value)!;
          onChange?.(result.id, result);
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={(option.id as unknown) as string}
            value={(option.id as unknown) as string}
          >
            {option.label}
          </MenuItem>
        ))}
      </SelectInput>
    );
  }
  if (ui === "list") {
    return (
      <>
        <Box px={2} py={1}>
          <Typography variant={"subtitle1"}>Select Load</Typography>
        </Box>
        {options.map((option) => (
          <ListItem
            key={(option.id as unknown) as string}
            button
            onClick={() => onChange?.(option.id, option)}
          >
            <ListItemIcon>
              <Chip
                label={option.initial ?? option.id}
                color={option.color}
                style={{ width: "100%" }}
              />
            </ListItemIcon>
            &nbsp; &nbsp;
            <Typography>{option.label}</Typography>
          </ListItem>
        ))}
      </>
    );
  }
  return null;
}

export default FormLoadType;
