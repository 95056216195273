import * as React from "react";

const SvgUnchecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="MuiSvgIcon-root"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    <path fill="none" className="innerBox" d="M19 5v14H5V5h14" />
  </svg>
);

export default SvgUnchecked;
