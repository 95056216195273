import React from "react";
import { LayoutMenu } from "./Layout";
import {
  SvgInsight,
  SvgLane,
  SvgPartner,
  SvgShipment,
  SvgQuote,
  SvgSetting,
  SvgBenchmarking,
} from "../PortexIcons";

export const getShipperMenus = (props?: Omit<LayoutMenu, "label" | "icon">) =>
  [
    {
      label: "Quotes",
      icon: <SvgQuote />,
    },
    {
      label: "Shipments",
      icon: <SvgShipment />,
    },
    {
      label: "Lanes",
      icon: <SvgLane />,
    },
    {
      label: "Benchmarking",
      icon: <SvgBenchmarking />,
    },
    {
      label: "Insights",
      icon: <SvgInsight />,
    },
    {
      label: "Partners",
      icon: <SvgPartner />,
    },
    {
      label: "Settings",
      icon: <SvgSetting />,
    },
  ].map<LayoutMenu>((item) => ({ ...item, ...props }));
