import { makeStyles } from "@material-ui/core/styles";

export const useAutocompleteStyles = makeStyles(({ shadows }) => ({
  paper: {
    boxShadow: shadows[8],
  },
  inputRoot: {
    paddingLeft: "6px !important",
    paddingTop: "6px !important",
    paddingBottom: "6px !important",
  },
  input: {
    padding: "6px 8px !important",
    "&:first-child": {
      marginLeft: -2,
    },
  },
}));

export const useAutocompleteOneLineStyles = makeStyles(({ shadows }) => ({
  paper: {
    boxShadow: shadows[8],
  },
  inputRoot: {
    paddingLeft: "6px !important",
    paddingTop: "6px !important",
    paddingBottom: "6px !important",
    flexWrap: "nowrap",
  },
  input: {
    padding: "6px 8px !important",
    "&:first-child": {
      marginLeft: -2,
    },
  },
  line: {
    display: "flex",
    overflow: "auto",
    maxWidth: "100%",
    "& > *": {
      whiteSpace: "nowrap",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const useAutocompleteTooltipStyles = makeStyles(() => ({
  tooltipPlacementBottom: {
    marginTop: 8,
  },
}));
