import * as React from "react";

function SvgComponent({
  color = "#16aa65",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      {...props}
    >
      <g transform="translate(.012)">
        <circle
          data-name="Ellipse 92"
          cx={14}
          cy={14}
          r={14}
          transform="translate(-.012)"
          fill={color}
        />
        <g fill="#fff">
          <path
            data-name="Path 2549"
            d="M8.675 16.002a1.192 1.192 0 101.192 1.192 1.192 1.192 0 00-1.192-1.192z"
          />
          <path
            data-name="Path 2550"
            d="M12.24 16.002a1.192 1.192 0 101.192 1.192 1.193 1.193 0 00-1.192-1.192z"
          />
          <path
            data-name="Path 2551"
            d="M18.885 16.002a1.192 1.192 0 101.192 1.192 1.193 1.193 0 00-1.192-1.192z"
          />
          <path
            data-name="Path 2552"
            d="M20.647 13.046l-1.192-2.384h-2.922v4.2h-.653V8.277H6.287v8.347h.612a1.866 1.866 0 013.554 0h.012a1.866 1.866 0 013.554 0h3.092a1.866 1.866 0 013.553 0h1.178v-2.9z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
