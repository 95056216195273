import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const Context = React.createContext<
  Pick<StopProps, "markVariant" | "connectorGap">
>({
  markVariant: "circular",
});

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    root: {
      display: "grid",
      gridTemplateColumns: "fit-content(100px) 32px 1fr",
      gridAutoFlow: "row",
      background: palette.background.paper,
    },
    preMark: {
      gridColumn: "1",
      justifySelf: "flex-end",
      ...theme.typography.prehead,
    },
    mark: {
      gridColumn: "2",
      placeSelf: "center",
      width: 8,
      height: 8,
      backgroundColor: palette.text.primary,
      zIndex: 1,
    },
    markCircular: {
      borderRadius: 8,
    },
    markRectangular: {
      borderRadius: 0,
    },
    stopHead: {
      gridColumn: "3",
    },
    stopBody: {
      gridColumn: "3",
      paddingTop: 4,
      paddingBottom: "1.5rem",
      ...theme.typography.body1,
    },
    connector: {
      gridColumn: "2",
      width: 2,
      justifySelf: "center",
      backgroundColor: palette.grey["300"],
    },
    gapNone: {
      marginTop: "-12px",
      marginBottom: "-12px",
    },
  };
});

interface StopProps {
  preMark?: React.ReactNode;
  head: React.ReactNode;
  children: React.ReactNode;
  markVariant?: "circular" | "rectangular";
  connectorGap?: "none";
  disableConnector?: boolean;
}

const Stop = ({
  preMark,
  head,
  children,
  disableConnector,
  connectorGap: connectorGapProp,
  markVariant: markVariantProp,
}: StopProps) => {
  const classes = useStyles();
  const ctx = React.useContext(Context);
  const connectorGap =
    typeof connectorGapProp === "undefined"
      ? ctx.connectorGap
      : connectorGapProp;
  const markVariant =
    typeof markVariantProp === "undefined" ? ctx.markVariant : markVariantProp;
  return (
    <>
      {preMark && <div className={classes.preMark}>{preMark}</div>}
      <div
        className={cx(classes.mark, {
          [classes.markCircular]: markVariant === "circular",
          [classes.markRectangular]: markVariant === "rectangular",
        })}
      />
      <div className={classes.stopHead}>{head}</div>
      {!disableConnector && (
        <div
          className={cx(classes.connector, {
            [classes.gapNone]: connectorGap === "none",
          })}
        />
      )}
      <div className={classes.stopBody}>{children}</div>
    </>
  );
};

type Data = {
  body: Array<{ label?: React.ReactNode; value: React.ReactNode }>;
} & Omit<StopProps, "children">;

type Children = React.ReactChild | Array<React.ReactChild>;
export type DeliveryProps = {
  children?: Children;
  data?: Array<Data>;
} & Partial<Pick<StopProps, "markVariant" | "connectorGap">> &
  Omit<JSX.IntrinsicElements["div"], "children">;

const Delivery = ({
  markVariant = "circular",
  connectorGap,
  children,
  data,
  className,
  ...props
}: DeliveryProps) => {
  const classes = useStyles();
  const size = React.Children.count(children);
  return (
    <Context.Provider value={{ markVariant, connectorGap }}>
      <div {...props} className={cx(classes.root, className)}>
        {children &&
          React.Children.map(children, (child, index) =>
            typeof child === "string" || typeof child === "number"
              ? child
              : React.cloneElement(child, {
                  disableConnector: index === size - 1,
                })
          )}
        {data &&
          data.map(({ body, ...item }, index) => (
            <Stop
              disableConnector={index === data.length - 1}
              {...item}
              head={
                typeof item.head === "string" ? (
                  <Typography>
                    <b>{item.head}</b>
                  </Typography>
                ) : (
                  item.head
                )
              }
            >
              {body.map(({ label, value }, bodyIndex) => (
                <Typography gutterBottom={bodyIndex !== body.length - 1}>
                  {label && <b>{label}:</b>} {value}
                </Typography>
              ))}
            </Stop>
          ))}
      </div>
    </Context.Provider>
  );
};

Delivery.Stop = Stop;

export default Delivery;
