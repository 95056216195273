import * as React from "react";

const SvgServiceDeferredChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
  >
    <g id="deferred-checked" transform="translate(14334 -18350)">
      <circle
        id="Ellipse_285"
        data-name="Ellipse 285"
        cx="26.5"
        cy="26.5"
        r="26.5"
        transform="translate(-14334 18350)"
        fill="#265df1"
      />
      <path
        id="Icon_material-access-time"
        data-name="Icon material-access-time"
        d="M16.314,3A13.328,13.328,0,1,0,29.655,16.328,13.321,13.321,0,0,0,16.314,3Zm.013,23.99A10.662,10.662,0,1,1,26.99,16.328,10.659,10.659,0,0,1,16.328,26.99Zm.666-17.326h-2v8l7,4.2,1-1.639-6-3.558Z"
        transform="translate(-14323.578 18360.172)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgServiceDeferredChecked;
