import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.492}
      height={21.766}
      viewBox="0 0 18.492 21.766"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.56 21.623a1.106 1.106 0 001.111-.014l4.854-2.917 4.868 2.915a1.1 1.1 0 001.134 0l5.431-3.256a1.112 1.112 0 00.535-.948V4.834a1.109 1.109 0 00-.561-.962 1.133 1.133 0 00-1.111.014l-4.854 2.915-1.9-1.134-.773 2.111 1.667 1v9.988l-3.4-2.041v-5.876a2.525 2.525 0 01-1.235.346H6.29a2.575 2.575 0 01-.743-.109v5.632L2.22 18.712v-10l1.07-.641-.438-1.046-1.263-.519-1.052.631a1.113 1.113 0 00-.535.949v12.58a1.109 1.109 0 00.561.962zM12.969 8.774l3.305-1.99v9.994l-3.305 1.988z"
      />
      <path
        fill="currentColor"
        d="M.953 4.275l3.3 1.36 1.36 3.3a.709.709 0 001.317-.016L9.84.95A.706.706 0 009.176 0a.684.684 0 00-.243.043l-7.954 2.9a.7.7 0 00-.453.651.711.711 0 00.427.678z"
      />
    </svg>
  );
}

export default SvgComponent;
