import React, { ReactNode, forwardRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box, { BoxProps } from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

export type SidebarUserProps = {
  avatar?: ReactNode;
  name: string;
  company?: string;
} & BoxProps;

const SidebarUser = forwardRef<HTMLDivElement, SidebarUserProps>(
  ({ avatar, name, company, ...props }, ref) => (
    <Box
      // @ts-expect-error
      ref={ref}
      mt={"auto"}
      bgcolor={"#111C27"}
      pl={2}
      py={3}
      display={"flex"}
      {...props}
    >
      <Avatar
        variant={"rounded"}
        style={{ placeSelf: "center" }}
        {...(typeof avatar === "string" && { src: avatar })}
      >
        {typeof avatar === "object" && avatar}
      </Avatar>
      <Box color={"#fff"} ml={1.5} minWidth={0}>
        <Typography variant={"body2"} noWrap>
          <b>{name}</b>
        </Typography>
        <Typography variant={"body2"} noWrap>
          {company}
        </Typography>
      </Box>
      <Box ml="auto" color={"#eee"} lineHeight={0}>
        <KeyboardArrowRight
          fontSize="large"
          htmlColor="rgba(255,255,255,0.4)"
        />
      </Box>
    </Box>
  )
);

export default SidebarUser;
