import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box, { BoxProps } from "@material-ui/core/Box";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  title: {
    marginRight: "auto",
  },
  close: {
    marginRight: -8,
  },
}));

export type DrawerHeaderProps = {
  icon?: ReactNode;
  title: ReactNode;
  onClose?: IconButtonProps["onClick"];
} & BoxProps;

export const DrawerHeader = ({
  title,
  onClose,
  icon,
  ...props
}: DrawerHeaderProps) => {
  const classes = useStyles();
  return (
    <Box p={2} display="flex" alignItems="center" {...props}>
      {icon}
      {icon && <Box width="0.5rem" />}
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      {onClose && (
        <IconButton onClick={onClose} className={classes.close}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
};
