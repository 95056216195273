import * as React from "react";

const SvgCapacityGuaranteeChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={53} height={53} {...props}>
    <g transform="translate(-14573 -8932)">
      <circle
        data-name="Ellipse 263"
        cx={26.5}
        cy={26.5}
        r={26.5}
        transform="translate(14573 8932)"
        fill="#265df1"
      />
      <path
        data-name="Icon awesome-boxes"
        d="M14611.187 8959.395h-3.485v4.183l-1.395-.932-1.394.928v-4.179h-3.486a.7.7 0 0 0-.7.7v8.366a.7.7 0 0 0 .7.7h9.76a.7.7 0 0 0 .7-.7v-8.369a.7.7 0 0 0-.7-.697Zm-16.731-2.789h9.76a.7.7 0 0 0 .7-.7v-8.36a.7.7 0 0 0-.7-.7h-3.486v4.183l-1.394-.928-1.394.928v-4.183h-3.486a.7.7 0 0 0-.7.7v8.363a.7.7 0 0 0 .7.697Zm2.789 2.789h-3.486v4.183l-1.395-.932-1.394.928v-4.179h-3.483a.7.7 0 0 0-.7.7v8.366a.7.7 0 0 0 .7.7h9.76a.7.7 0 0 0 .7-.7v-8.369a.7.7 0 0 0-.703-.697Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgCapacityGuaranteeChecked;
