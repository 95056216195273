import React, { useCallback, memo } from "react";
import TextInput, { TextInputProps } from "../TextInput/TextInput";

/**
 * check if given string is valid positive float number format.
 * @param input string
 * @returns true if input is valid positive float number string.
 */
export const validFloatNumber = (input: string): boolean => {
  return !!input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/);
};

export interface FloatInputProps
  extends Omit<TextInputProps, "type" | "onChange" | "onKeyPress"> {
  onChange?: (value: number) => void;
  decimalPlace?: number;
}

const noop = () => {
  return;
};

const FloatInput: React.FC<FloatInputProps> = memo(
  ({ value, onChange = noop, decimalPlace, ...props }) => {
    const handleChange = useCallback(
      ({ target: { value } }: React.ChangeEvent<{ value: string }>) => {
        const parts = value.split(".");
        let newValue = Number(value);
        if (decimalPlace && parts.length === 2) {
          const newValueStr = parts[0] + "." + parts[1].slice(0, decimalPlace);
          newValue = parseFloat(newValueStr);
        }
        onChange?.(newValue);
      },
      [decimalPlace, onChange]
    );

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);
      if (!validFloatNumber(keyValue)) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault();
      }
    };

    return (
      <TextInput
        type="number"
        value={value || ""}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        onKeyDownCapture={handleKeyDown}
        {...props}
      />
    );
  }
);

FloatInput.displayName = "FloatInput";
export default FloatInput;
