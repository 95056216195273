import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19.042}
      height={17.137}
      viewBox="0 0 19.042 17.137"
      {...props}
    >
      <path
        d="M10.473 7.617A3.81 3.81 0 016.785 4.76H0v-1.9h6.785a3.809 3.809 0 113.688 4.76zm5.712-4.76h2.856v1.9h-2.856zM5.712 17.137a3.81 3.81 0 01-3.688-2.856H0v-1.9h2.024a3.809 3.809 0 113.688 4.76zm5.712-2.856h7.617v-1.9h-7.616zm-3.808-.952a1.9 1.9 0 11-1.9-1.9 1.9 1.9 0 011.901 1.9zm4.76-9.521a1.9 1.9 0 11-1.9-1.9 1.9 1.9 0 011.901 1.9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
