import React from "react";
import TextInput, { TextInputProps } from "../TextInput/TextInput";
import Validator from "./numberValidator";
import Projector from "./numberProjector";

export type NumberInputProps = {
  /**
   * If true, error will not appear on invalid input
   */
  disableError?: boolean;
  /**
   * If true, empty input will display error (`disableError` must NOT be true)
   */
  disableEmpty?: boolean;
  /**
   * If true, initially display '0' to the input (if value is 0)
   */
  displayZero?: boolean;
  /**
   * If `true`, only the valid value (pass the validator) from user input will reflect the change on screen.
   */
  onlyValidInput?: boolean;
  value?: number;
  onValidChange?: (newValue: number) => void;
  validator?:
    | keyof typeof Validator
    | ((
        value: string
      ) => { isValid: boolean; numberValue: number; error: string });
  projector?:
    | keyof typeof Projector
    | { parse: (value: string) => string; format: (value: string) => string };
} & Omit<TextInputProps, "value" | "defaultValue" | "validator">;

const useIsFirstMount = () => {
  const isFirstMount = React.useRef(true);
  React.useEffect(() => {
    isFirstMount.current = false;
  }, []);
  return isFirstMount.current;
};

const NumberInput = ({
  disableEmpty,
  disableError,
  onlyValidInput,
  projector,
  value,
  displayZero,
  onValidChange,
  validator = "Float",
  ...props
}: NumberInputProps) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const isFirstMount = useIsFirstMount();
  const [stringVal, setStringVal] = React.useState(
    value === 0 && !displayZero ? "" : value?.toString() ?? ""
  );
  const [focused, setFocused] = React.useState(false);
  const [error, setError] = React.useState("");
  const valueRef = React.useRef(value);

  const getDisplayedValue = React.useCallback(
    (text: string) => {
      if (!projector) return text;
      if (typeof projector === "string") {
        return Projector[projector].format(text);
      }
      return projector.format(text);
    },
    [projector]
  );
  const getInternalValue = React.useCallback(
    (text: string) => {
      if (!projector) return text;
      if (typeof projector === "string") {
        return Projector[projector].parse(text);
      }
      return projector.parse(text);
    },
    [projector]
  );

  React.useEffect(() => {
    if (!focused && value !== valueRef.current) {
      const newValue = value?.toString() ?? "";
      setStringVal(newValue);
    }
  }, [value, valueRef, focused]);

  React.useEffect(() => {
    if (!focused && value === 0) {
      setStringVal(displayZero ? "0" : "");
    }
  }, [focused, displayZero, value]);

  React.useEffect(() => {
    if (focused && projector === "Currency") {
      setTimeout(() => {
        if (inputRef.current) {
          const cursorStart = inputRef.current.selectionStart;
          if (projector === "Currency") {
            const commaIndexes: number[] = [];
            getDisplayedValue(inputRef.current.value)
              .split("")
              .forEach((t, index) => {
                if (t === ",") {
                  commaIndexes.push(index);
                }
              });

            const secondCommaIndex = commaIndexes[1];
            if (
              cursorStart &&
              cursorStart >= secondCommaIndex &&
              cursorStart !== inputRef.current.value.length
            ) {
              inputRef.current.setSelectionRange(
                cursorStart - 1,
                cursorStart - 1
              );
            }
          }
        }
      }, 0);
    }
  }, [focused, projector, getDisplayedValue]);

  React.useEffect(() => {
    const result =
      typeof validator === "function"
        ? validator(stringVal)
        : Validator[validator](stringVal);

    if (result.isValid || (stringVal === "" && !disableEmpty)) {
      if (!isFirstMount) {
        onValidChange?.(stringVal === "" ? 0 : result.numberValue);
      }
      setError("");
    } else {
      setError(result.error);
    }
  }, [disableEmpty, validator, stringVal, isFirstMount, onValidChange]);

  React.useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return (
    <TextInput
      inputRef={inputRef}
      error={!disableError && !!error}
      helperText={!disableError ? error || "\u00A0" : undefined}
      {...props}
      value={
        !focused && !error
          ? getDisplayedValue(stringVal || "")
          : getInternalValue(stringVal || "")
      }
      onFocus={(event) => {
        props.onFocus?.(event);
        setFocused(true);
        if (event.target.value === "0") {
          setStringVal("");
        }
      }}
      onBlur={(event) => {
        props.onBlur?.(event);
        setFocused(false);
        if (value === 0 && displayZero) {
          setStringVal("0");
        }
      }}
      onChange={(event) => {
        props.onChange?.(event);
        const newValue = event.target.value;
        if (onlyValidInput && newValue) {
          const result =
            typeof validator === "function"
              ? validator(newValue)
              : Validator[validator](newValue);

          if (result.isValid) {
            setStringVal(newValue);
          }
        } else {
          setStringVal(newValue);
        }
      }}
    />
  );
};

export default NumberInput;
