import React, { ReactElement, ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { CustomColor } from "../constant";
import { portexColor } from "../theme/color";

const useStyles = makeStyles(
  ({ palette }) => ({
    label: {
      marginBottom: 6,
    },
    heading: (props: { palette?: CustomColor }) => ({
      color: (portexColor as any)[`${props.palette}500`] ?? "",
    }),
    headingEmphasized: {
      fontSize: "1.75rem",
    },
    metadata: {
      marginTop: 4,
      color: palette.grey["600"],
    },
    metadataEmphasized: {
      color: palette.grey["900"],
    },
  }),
  { name: "TextInfo" }
);

type Heading =
  | { heading: NonNullable<ReactNode>; renderHeading?: undefined }
  | {
      heading?: undefined;
      renderHeading: () => ReactElement;
    };

export type TextInfoProps = {
  label: NonNullable<ReactNode>;
  metadata?: ReactNode;
  palette?: CustomColor;
  emphasized?: boolean;
} & Pick<TypographyProps, "align"> &
  Heading;

const TextInfo = ({
  label,
  metadata,
  align,
  palette,
  emphasized = false,
  ...props
}: TextInfoProps) => {
  const styles = useStyles({ palette });
  return (
    <>
      <Typography className={styles.label} align={align}>
        {label}
      </Typography>
      {props.heading && (
        <Typography
          className={cx(styles.heading, emphasized && styles.headingEmphasized)}
          align={align}
          variant={"h6"}
        >
          {typeof props.heading === "string" ? (
            <b>{props.heading}</b>
          ) : (
            props.heading
          )}
        </Typography>
      )}
      {props.renderHeading?.()}
      {metadata && (
        <Typography
          className={cx(
            styles.metadata,
            emphasized && styles.metadataEmphasized
          )}
          align={align}
          variant={"body2"}
        >
          {metadata}
        </Typography>
      )}
    </>
  );
};

export default TextInfo;
