import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles";
import { purple } from "../theme/color";

export const useBrokerControlStyles = makeStyles(() => ({
  root: {
    borderRadius: 4,
    backgroundColor: purple["100"],
    padding: "4px 20px 4px 8px",
  },
  label: {
    fontWeight: "bold",
    color: purple["500"],
  },
}));

export const useBrokerSwitchStyles = makeStyles(() => ({
  root: {
    padding: "6px 8px",
    width: 64,
  },
  switchBase: {
    top: 6,
    left: 6,
    padding: 0,
    backgroundColor: purple["100"],
    "&.Mui-checked": {
      transform: "translateX(24px)",
      "& + $track": {
        opacity: 1,
      },
    },
  },
  colorSecondary: {
    "&.Mui-checked": {
      "& $thumb": {
        backgroundColor: purple["500"],
      },
      "& + $track": {
        backgroundColor: fade(purple["500"], 0.24),
      },
    },
    "& $thumb": {
      backgroundColor: purple["100"],
    },
  },
  thumb: {
    width: 26,
    height: 26,
    boxShadow: "none",
    border: "2px solid",
    borderColor: purple["500"],
  },
  track: {
    border: "2px solid",
    borderColor: purple["500"],
    borderRadius: 24,
    backgroundColor: "#fff",
    opacity: 1,
  },
}));
