import React, { ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

const useStyles = makeStyles(
  () => ({
    root: {
      padding: "1rem 1.25rem",
      "&:not(:last-of-type)": {
        paddingRight: 0,
      },
      flexShrink: 1,
    },
    head: {
      flexBasis: 200,
    },
  }),
  { name: "MuiSummaryCell" }
);

export type SummaryCellProps = {
  head?: boolean;
  children: ReactNode;
  TypographyProps?: TypographyProps;
} & JSX.IntrinsicElements["div"];

const SummaryCell = ({
  children,
  head,
  className,
  TypographyProps,
  ...props
}: SummaryCellProps) => {
  const styles = useStyles();
  return (
    <div className={cx(styles.root, head && styles.head)} {...props}>
      {typeof children === "string" ? (
        <Typography {...TypographyProps}>
          {head ? <b>{children}</b> : children}
        </Typography>
      ) : (
        children
      )}
    </div>
  );
};

export default SummaryCell;
