import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),
      color: "#fff",
    },
    label: {
      ...theme.typography.body2,
      fontSize: 12,
      fontWeight: 500,
      textTransform: "uppercase",
      marginBottom: theme.spacing(0.75),
    },
    divider: {
      backgroundColor: theme.palette.grey[700],
      marginBottom: theme.spacing(0.5),
    },
    value: {
      ...theme.typography.h6,
      fontWeight: 700,
    },
    extra: {
      ...theme.typography.body2,
      fontSize: 12,
    },
  }),
  { name: "DataPoint" }
);

export type DataPointProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  extra?: React.ReactNode;
} & Omit<JSX.IntrinsicElements["div"], "ref">;

const DataPoint = React.forwardRef<HTMLDivElement, DataPointProps>(
  ({ label, value, extra, className, ...props }, ref) => {
    const classes = useStyles();
    return (
      <div ref={ref} className={cx(classes.root, className)} {...props}>
        <div className={classes.label}>{label}</div>
        <Divider className={classes.divider} />
        <div className={classes.value}>{value}</div>
        <div className={classes.extra}>{extra}</div>
      </div>
    );
  }
);

export default DataPoint;
