import * as React from "react";

const SvgDirectSailing = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={53} height={53} {...props}>
    <g transform="translate(-14410 -8836)">
      <circle
        data-name="Ellipse 257"
        cx={26.5}
        cy={26.5}
        r={26.5}
        transform="translate(14410 8836)"
        fill="#e9eefe"
      />
      <path
        data-name="Icon ionic-md-fastforward"
        d="m14453.287 8862.5-15.3-10.266v20.532Zm-31.5-10.266v20.532l15.3-10.266Z"
        fill="#265df1"
      />
    </g>
  </svg>
);

export default SvgDirectSailing;
