import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      color="#fff"
      {...props}
    >
      <g transform="translate(-2)" fill="currentColor">
        <path d="M7.958 15.656a1.334 1.334 0 101.333 1.333 1.334 1.334 0 00-1.333-1.333z" />
        <path d="M11.946 15.656a1.334 1.334 0 101.333 1.333 1.334 1.334 0 00-1.333-1.333z" />
        <path d="M19.38 15.656a1.334 1.334 0 101.337 1.333 1.334 1.334 0 00-1.337-1.333z" />
        <path d="M17.391 16.358h-3.459a2.083 2.083 0 00-3.972 0h-.014a2.086 2.086 0 00-3.977 0h-.678v-1.982h11.457v-4.7h3.269l1.334 2.67 1.334.76v3.248h-1.319a2.083 2.083 0 00-3.972 0z" />
        <rect
          width={11}
          height={5}
          rx={2.5}
          transform="translate(4.488 8.201)"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
