import React from "react";
import cx from "clsx";
import ButtonBase, { ButtonBaseTypeMap } from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
import { OverridableComponent } from "../types";
import { portexColor } from "../theme/color";
import { CustomColor } from "../constant";
import SvgDone from "../PortexIcons/SvgDone";

const Context = React.createContext<{ palette?: CustomColor }>({
  palette: "blue",
});

export type ButtonTileProps = {
  palette?: CustomColor;
  fullWidth?: boolean;
  checked?: boolean;
} & Omit<ButtonBaseTypeMap["props"], "tabIndex">;

export const useButtonTileStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: "4px",
      border: "2px solid",
      flexDirection: "column",
      color: theme.palette.grey[500],
      "& svg": { color: "inherit" },
      "& circle": { fill: "none" },
      "& path:not([fill])": { fill: "currentColor" },
      "& path[fill*='#fff']": { fill: "currentColor" },
      "&.Mui-disabled": {
        pointerEvents: "initial",
        backgroundColor: theme.palette.grey[50],
        borderColor: theme.palette.grey[200],
        color: theme.palette.grey[500],
        "&:hover": {
          backgroundColor: theme.palette.grey[50],
        },
        "& svg": { color: theme.palette.grey[500] },
        "& $bar": {
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.grey[500],
        },
      },
    },
    palette: (props: { palette?: CustomColor; checked?: boolean }) => ({
      color: (portexColor as any)[`${props.palette}500`] ?? "",
      ...(props.checked && {
        backgroundColor: (portexColor as any)[`${props.palette}100`] ?? "",
      }),
      "&:hover": {
        backgroundColor: (portexColor as any)[`${props.palette}100`] ?? "",
      },
    }),
    fullWidth: {
      width: "100%",
    },
    bar: (props: { palette?: CustomColor }) => ({
      padding: "0.5rem",
      display: "flex",
      gap: "0.5rem",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: (portexColor as any)[`${props.palette}500`] ?? "",
      color: "#fff",
      alignSelf: "stretch",
      fontWeight: 600,
    }),
    check: {
      backgroundColor: "currentColor",
      width: 28,
      height: 28,
      borderRadius: 20,
      position: "absolute",
      top: "0.5rem",
      right: "0.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > svg": {
        color: "#fff",
        fontSize: "1.25rem",
      },
    },
  }),
  { name: "PorButtonTile" }
);

export const ButtonTileBar = ({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) => {
  const { palette } = React.useContext(Context);
  const styles = useButtonTileStyles({ palette });
  return <div className={cx(styles.bar, className)} {...props} />;
};

const ButtonTile = React.forwardRef(
  (
    { palette = "blue", fullWidth, checked = false, children, ...props },
    ref
  ) => {
    const styles = useButtonTileStyles({ palette, checked });
    return (
      <Context.Provider value={{ palette }}>
        <ButtonBase
          ref={ref as React.MutableRefObject<HTMLButtonElement>}
          className={cx(
            styles.root,
            styles.palette,
            {
              [styles.fullWidth]: fullWidth,
            },
            props.className
          )}
          {...props}
        >
          {children}
          {checked && (
            <div className={styles.check}>
              <SvgDone />
            </div>
          )}
        </ButtonBase>
      </Context.Provider>
    );
  }
) as OverridableComponent<
  ButtonTileProps,
  JSX.IntrinsicElements["button"],
  HTMLButtonElement
>;

export default ButtonTile;
