import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import NotificationsActive from "@material-ui/icons/NotificationsActive";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      padding: "3px 10px",
      display: "inline-flex",
      alignItems: "center",
      borderRadius: 24,
      backgroundColor: palette.error.main,
      color: palette.common.white,
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "0.75rem",
    },
    icon: {
      color: palette.common.white,
      fontSize: 16,
      marginRight: 4,
      marginLeft: -4,
      transform: "rotate(-30deg)",
    },
  }),
  { name: "UrgentTag" }
);

export type UrgentTagProps = JSX.IntrinsicElements["div"];

const UrgentTag = ({ children, className, ...props }: UrgentTagProps) => {
  const classes = useStyles();
  return (
    <div {...props} className={cx(classes.root, className)}>
      <NotificationsActive className={classes.icon} />
      {children}
    </div>
  );
};

export default UrgentTag;
