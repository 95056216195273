import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: "flex",
      alignItems: "center",
      padding: "1.25rem",
      "& > :not(:first-child)": {
        marginLeft: "0.75rem",
      },
    },
    line: {
      borderBottom: "1px solid",
      borderBottomColor: palette.grey["200"],
    },
    content: {
      padding: "1.25rem",
      paddingTop: 0,
      display: "flex",
      flexDirection: "column",
    },
    lastToEdge: {
      "& > :last-child:not(:first-child)": {
        marginLeft: "auto",
      },
    },
  }),
  { name: "MuiSummaryItem" }
);

export type SummaryItemProps = {
  line?: boolean;
  content?: boolean;
  lastToEdge?: boolean;
} & JSX.IntrinsicElements["div"];

const SummaryItem = ({
  line,
  content,
  lastToEdge,
  children,
  className,
  ...props
}: SummaryItemProps) => {
  const styles = useStyles();
  return (
    <div
      className={cx(
        content ? styles.content : styles.root,
        line && styles.line,
        lastToEdge && styles.lastToEdge,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default SummaryItem;
