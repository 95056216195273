import { makeStyles } from "@material-ui/core/styles";
import { red } from "../theme/color";

export const useTextInputStyles = makeStyles(
  ({ palette, ...theme }) => ({
    formControl: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      display: "inline-flex",
      flexDirection: "column",
    },
    formLabel: {
      color: palette.text.primary,
      fontSize: "1rem",
      marginBottom: "0.75em",
      fontWeight: "bold",
      lineHeight: 1.2,
      "&.Mui-focused, &.Mui-error": {
        color: palette.text.primary,
      },
    },
    highlight: {
      "& $notchedOutline": {
        borderWidth: 2,
        borderColor: red["500"],
      },
    },
    notchedOutline: {},
    outlinedRoot: {
      padding: 0,
      backgroundColor: palette.common.white,
      "&.Mui-disabled": {
        "& svg": {
          color: palette.grey[500],
        },
      },
      "& > textarea": {
        lineHeight: 1.5,
      },
    },
    search: {
      backgroundColor: "#F7F8F9",
    },
    outlinedAdornEnd: {
      paddingRight: 12,
    },
    outlinedInput: {
      padding: 12,
      height: "auto",
      minHeight: "auto",
      lineHeight: 1.15,
    },
    outlinedInputLarge: {
      padding: `${15 / 16}rem 1rem`,
    },
    inputAdornedStart: {
      paddingLeft: "2.5rem",
    },
    adornmentStart: {
      position: "absolute",
      left: 8,
    },
    adornmentEnd: {},
    selectInput: {
      // workaround for select, cannot pass classes to OutlinedInput
      // https://github.com/mui-org/material-ui/blob/v4.11.2/packages/material-ui/src/Select/Select.js#L21
      // https://github.com/mui-org/material-ui/blob/v4.11.2/packages/material-ui/src/Select/Select.js#L85
      "& .MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: "2.5rem",
      },
      "& $outlinedInput": {
        paddingRight: 36,
      },
      "& $outlinedInput:focus": {
        backgroundColor: "unset",
      },
      "& svg:not(.MuiSvgIcon-root)": {
        fontSize: "1rem",
        marginTop: -8,
        marginBottom: -8,
        verticalAlign: "middle",
      },
    },
    selectInputHighlight: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 2,
        borderColor: red["500"],
      },
    },
    selectIcon: {
      top: "calc(50% - 0.75rem)",
    },
    selectStartIcons: {
      "& .MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: "4rem",
      },
    },
    placeholder: {
      color: palette.grey[500],
    },
  }),
  { name: "TextInput" }
);
