import React from "react";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import SummaryHead from "./SummaryHead";
import SummaryCell from "./SummaryCell";
import SummaryRow from "./SummaryRow";
import SummaryItem from "./SummaryItem";

export type SummaryProps = PaperProps;

const Summary = ({
  children,
  ...props
}: React.PropsWithChildren<SummaryProps>) => {
  return (
    <Paper variant={"outlined"} {...props}>
      {children}
    </Paper>
  );
};

Summary.Head = SummaryHead;
Summary.Row = SummaryRow;
Summary.Cell = SummaryCell;
Summary.Item = SummaryItem;

export default Summary;
