import React, { ReactNode, useRef } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Menu, { MenuProps } from "@material-ui/core/Menu";

import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { PaperProps } from "@material-ui/core/Paper";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      borderRadius: 4,
      padding: "7px 12px",
      display: "inline-flex",
      justifyContent: "space-between",
      minWidth: 200,
      maxWidth: "100%",
      border: "1px solid",
      borderColor: palette.grey["300"],
      boxShadow: `0 1px 1px ${palette.grey["200"]}`,
      backgroundColor: palette.background.paper,
      cursor: "pointer",
    },
    menuContainer: {
      borderRadius: 4,
      marginTop: 8,
      border: "1px solid",
      borderColor: palette.grey["300"],
      boxShadow: `0 3px 8px ${palette.grey["200"]}`,
    },
    checkbox: {
      marginRight: 8,
    },
  }),
  { name: "Dropdown" }
);

export type DropdownRef = {
  open: () => void;
  close: () => void;
};

export type DropdownProps = {
  anchorOrigin?: Partial<MenuProps["anchorOrigin"]>;
  transformOrigin?: Partial<MenuProps["transformOrigin"]>;
  className?: string;
  style?: React.CSSProperties;
  placeholder: string;
  onOpen?: (elm: HTMLDivElement) => void;
  onClose?: () => void;
  dropdownRef?: React.MutableRefObject<DropdownRef | undefined>;
  children: ((props: { onClose: () => void }) => ReactNode) | ReactNode;
  PaperProps?: PaperProps;
};

const Dropdown = ({
  className,
  style,
  placeholder,
  children,
  onOpen,
  onClose,
  dropdownRef,
  anchorOrigin,
  transformOrigin,
  PaperProps,
}: DropdownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const cachedAnchorEl = useRef(anchorEl);
  const styles = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    cachedAnchorEl.current = event.currentTarget;
    setAnchorEl(event.currentTarget);
    onOpen?.(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };
  if (dropdownRef && "current" in dropdownRef) {
    dropdownRef.current = {
      open: () => {
        if (cachedAnchorEl.current) {
          setAnchorEl(cachedAnchorEl.current);
          onOpen?.(cachedAnchorEl.current);
        }
      },
      close: handleClose,
    };
  }
  return (
    <>
      <div
        className={cx(styles.root, className)}
        style={style}
        onClick={handleClick}
      >
        <Typography>{placeholder}</Typography>
        {Boolean(anchorEl) ? (
          <KeyboardArrowUp color={"action"} />
        ) : (
          <KeyboardArrowDown color={"action"} />
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          ...PaperProps,
          className: cx(styles.menuContainer, PaperProps?.className),
          style: {
            minWidth: cachedAnchorEl.current?.clientWidth,
            ...PaperProps?.style,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
          ...transformOrigin,
        }}
      >
        {typeof children === "function"
          ? children({ onClose: handleClose })
          : children}
      </Menu>
    </>
  );
};

export default Dropdown;
