import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import History from "@material-ui/icons/History";
import LocationOn from "@material-ui/icons/LocationOn";

import { LocationPicker } from "./LocationPicker";

export type LocationValue<T> = {
  pickup: T | null;
  delivery: T | null;
};
export type ShippingLocatorProps<T> = {
  value: LocationValue<T>;
  onChange: (value: LocationValue<T>) => void;
  onInputChange?: (value: string) => void;
  options: T[];
};

const useStyles = makeStyles(({ palette }) => ({
  control: {
    position: "relative",
    border: "1px solid",
    borderColor: palette.grey["500"],
    borderRadius: 4,
    backgroundColor: "#fff",
    padding: "0.625rem 0",
  },
}));

export enum LocationOptionTypeEnum {
  Warehouse = "warehouse",
  City = "city",
}

export type LocationOption = {
  id: string | number;
  name?: string;
  recent?: boolean;
  type: `${LocationOptionTypeEnum}`;
};

export const ShippingLocator = <T extends LocationOption>({
  value,
  onChange,
  options,
  onInputChange,
}: ShippingLocatorProps<T>) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const getIcon = (recent?: boolean) => {
    if (recent) return <History color="disabled" />;
    return <LocationOn color="disabled" />;
  };
  const groupLabel = {
    [LocationOptionTypeEnum.Warehouse]: "Warehouse on platform",
    [LocationOptionTypeEnum.City]: "Or select a city",
  };
  const sharedProps = {
    options,
    getOptionLabel: (option: T) => option.name || `Location ${option.id}`,
    renderOption: (option: T) => (
      <>
        {getIcon(option.recent)}
        {option.name || `Location ${option.id}`}
      </>
    ),
    getOptionSelected: (option: T, value1: T) => option.id === value1.id,
    groupBy: (option: T) => option.type,
    renderGroup: (group: string) => groupLabel[group as LocationOption["type"]],
  };
  return (
    <div className={classes.control} ref={setAnchorEl}>
      <LocationPicker
        openOnFocus
        anchorEl={anchorEl}
        placeholder="Type a Pickup Location"
        value={value.pickup}
        onInputChange={(_event, value) => onInputChange?.(value)}
        onChange={(_event, newValue) => {
          if (!newValue) {
            onChange({ pickup: null, delivery: null });
          } else {
            onChange({ ...value, pickup: newValue });
          }
        }}
        {...sharedProps}
      />
      {value.pickup && (
        <LocationPicker
          InputBaseProps={{
            autoFocus: true,
          }}
          openOnFocus
          anchorEl={anchorEl}
          placeholder="Type a Delivery Location"
          value={value.delivery}
          onInputChange={(_event, value) => onInputChange?.(value)}
          onChange={(_event, newValue) => {
            onChange({ ...value, delivery: newValue });
          }}
          {...sharedProps}
        />
      )}
    </div>
  );
};
