export const Currency = {
  format: (value: string) => {
    const [before, after] = value.split(".");
    const currency = before.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return after ? [currency, after].join(".") : currency;
  },
  parse: (value: string) => {
    return value.replace(/,/g, "");
  },
};

export default {
  Currency,
};
