import React from "react";
import cx from "clsx";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export enum VolumeFormat {
  Volume = "VOLUME",
  Dimensions = "DIMENSIONS",
}
export type VolumeFormatValue = `${VolumeFormat}`;

export type FormVolumeFormatProps = {
  value?: VolumeFormatValue;
  onChange?: (value: VolumeFormatValue) => void;
} & Omit<FormControlProps, "onChange">;

const FormVolumeFormat = ({
  value,
  onChange,
  ...props
}: FormVolumeFormatProps) => (
  <FormControl margin="normal" fullWidth {...props}>
    <FormLabel>Volume Format</FormLabel>
    <ButtonGroup fullWidth color={"primary"}>
      <Button
        className={cx(value === VolumeFormat.Volume && "Ptx-selected")}
        onClick={() => onChange?.(VolumeFormat.Volume)}
      >
        Volume
      </Button>
      <Button
        className={cx(value === VolumeFormat.Dimensions && "Ptx-selected")}
        onClick={() => onChange?.(VolumeFormat.Dimensions)}
      >
        Dimensions
      </Button>
    </ButtonGroup>
  </FormControl>
);

export default FormVolumeFormat;
