export const blue = {
  900: "#000f33",
  800: "#011b66",
  700: "#002999",
  600: "#0137cc",
  500: "#0045ff", // #265DF1
  400: "#336aff",
  300: "#668fff",
  200: "#98b5ff",
  100: "#e9eefe",
} as const;

export const grey = {
  900: "#17222f",
  800: "#2d333b",
  700: "#434d58",
  600: "#5a6677",
  500: "#708093",
  400: "#8d99a8",
  300: "#b7bfc9",
  200: "#DDE1E6",
  100: "#e2e6e9",
  50: "#F7F8F9",
  base: "#cfd7e0",
} as const;

export const purple = {
  500: "#8024fa",
  300: "#A666FC",
  100: "#f2e5ff",
} as const;

export const cyan = {
  500: "#008bf1",
  100: "#e5f3fe",
} as const;

export const red = {
  500: "#e25050",
  100: "#FCEDEE",
} as const;

export const green = {
  500: "#16aa65",
  100: "#e7f6ef",
} as const;

export const yellow = {
  500: "#ffbf12",
  100: "#fff9e6",
} as const;

export const orange = {
  500: "#FF6262",
  100: "#ffecec",
} as const;

export const gold = {
  500: "#e28a06",
  400: "#fdad09",
  100: "#fff8df",
} as const;

export const portexColor = {
  blue900: blue["900"],
  blue800: blue["800"],
  blue700: blue["700"],
  blue600: blue["600"],
  blue500: blue["500"],
  blue400: blue["400"],
  blue300: blue["300"],
  blue200: blue["200"],
  blue100: blue["100"],
  grey900: grey["900"],
  grey800: grey["800"],
  grey700: grey["700"],
  grey600: grey["600"],
  grey500: grey["500"],
  grey400: grey["400"],
  grey300: grey["300"],
  grey200: grey["200"],
  grey100: grey["100"],
  grey50: grey["50"],
  purple500: purple["500"],
  purple300: purple["300"],
  purple100: purple["100"],
  cyan500: cyan["500"],
  cyan100: cyan["100"],
  red500: red["500"],
  red100: red["100"],
  green500: green["500"],
  green100: green["100"],
  yellow500: yellow["500"],
  yellow100: yellow["100"],
  orange500: orange["500"],
  orange100: orange["100"],
  gold500: gold["500"],
  gold400: gold["400"],
  gold100: gold["100"]
} as const;
