import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fontSize="1.5rem"
      viewBox="0 0 16.321 16.321"
      color="#fff"
      {...props}
    >
      <path
        d="M16.133 11.999a.643.643 0 01-.5.269 1.432 1.432 0 01-.725-.134l-1.7-.826.744 1.594c.153.326-.038.634-.439.71a.928.928 0 01-.592-.1.787.787 0 01-.4-.384l-1.3-2.784-2.4-1.152v2.554l2.06 2.3a.749.749 0 010 1 .6.6 0 01-.9 0L8.798 13.74v1.67a.827.827 0 01-.725.9.659.659 0 01-.515-.269.974.974 0 01-.21-.634V13.69l-1.2 1.344a.6.6 0 01-.9 0 .749.749 0 010-1l2.128-2.341V9.274l-2.289 1.19-1.221 2.8a.826.826 0 01-.382.384.853.853 0 01-.572.1.5.5 0 01-.458-.692l.706-1.613-1.717.884a.957.957 0 01-1.221-.116.5.5 0 010-.518 1.153 1.153 0 01.515-.48l1.639-.844-1.846-.27a.5.5 0 01-.454-.69.921.921 0 01.382-.384.853.853 0 01.572-.1l3.243.461 2.365-1.21-2.312-1.11-3.3.557a.853.853 0 01-.572-.1.873.873 0 01-.4-.384.492.492 0 01.42-.71l1.888-.326-1.717-.826c-.5-.23-.725-.672-.553-1a.613.613 0 01.5-.269 1.432 1.432 0 01.725.134l1.679.806-.73-1.53a.492.492 0 01.42-.71.853.853 0 01.572.1.87.87 0 01.4.384l1.278 2.726 2.48 1.19V4.666L5.298 2.323a.769.769 0 01-.191-.5.685.685 0 01.191-.5.586.586 0 01.9 0l1.2 1.344V.899a.827.827 0 01.725-.9.659.659 0 01.515.269.974.974 0 01.21.634v1.69l1.183-1.306a.586.586 0 01.9 0 .769.769 0 01.191.5.716.716 0 01-.191.5l-2.06 2.3v2.477l2.4-1.248 1.2-2.746a.821.821 0 01.4-.4.853.853 0 01.572-.1c.382.058.592.365.439.692l-.687 1.556 1.621-.826a.957.957 0 011.221.116.5.5 0 010 .518 1.153 1.153 0 01-.515.48l-1.679.864 1.908.269c.382.058.592.365.439.692a.821.821 0 01-.4.4.853.853 0 01-.572.1l-3.319-.48-2.27 1.172 2.4 1.152 3.243-.538a.928.928 0 01.592.1.787.787 0 01.4.384.5.5 0 01-.42.71l-1.85.308 1.641.788c.437.23.685.671.494.978z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
