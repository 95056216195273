import React, {
  PropsWithChildren,
  PropsWithoutRef,
  ReactElement,
  RefAttributes,
} from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import SvgFlame from "../PortexIcons/SvgFlame";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: "inline-flex",
      alignItems: "center",
      borderRadius: 4,
      padding: "2px 8px",
    },
    filled: {
      backgroundColor: palette.grey[100],
    },
    outlined: {
      border: "1px solid",
      borderColor: palette.grey["200"],
      backgroundColor: palette.background.paper,
    },
    hazardous: {
      color: palette.error.main,
      marginLeft: 4,
    },
    flame: {
      marginLeft: 6,
    },
    group: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      margin: -4,
    },
    item: {
      padding: 4,
    },
    delete: {
      marginLeft: 4,
      marginRight: -5,
      borderRadius: 2,
      color: palette.text.secondary,
      "&:hover, &:focus": {
        color: palette.text.primary,
      },
    },
  }),
  { name: "CommodityTag" }
);

export type CommodityTagProps = {
  variant?: "filled" | "outlined";
  isHazardous?: boolean;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  deleteIcon?: ReactElement;
} & JSX.IntrinsicElements["div"];

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="1.25em"
      width="1.25em"
      fontSize="inherit"
      fill="currentColor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  );
}

interface CommodityTagComponent
  extends React.ForwardRefExoticComponent<
    PropsWithoutRef<
      PropsWithChildren<CommodityTagProps> & RefAttributes<HTMLDivElement>
    >
  > {
  Group: typeof Group;
}

const CommodityTag = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<CommodityTagProps>
>(function CommodityTag(
  {
    isHazardous,
    className,
    children,
    onDelete,
    deleteIcon,
    variant = "outlined",
    ...props
  },
  ref
) {
  const styles = useStyles();
  return (
    <div
      ref={ref}
      className={cx(styles.root, styles[variant], className)}
      {...props}
    >
      {children}
      {isHazardous && (
        <>
          <SvgFlame className={styles.flame} />
          <span className={styles.hazardous}>Hazardous</span>
        </>
      )}
      {typeof onDelete === "function" && (
        <ButtonBase onClick={onDelete} className={styles.delete}>
          {deleteIcon ?? <DeleteIcon />}
        </ButtonBase>
      )}
    </div>
  );
}) as CommodityTagComponent;

const Group = ({
  children,
  className,
  ...props
}: JSX.IntrinsicElements["div"]) => {
  const styles = useStyles();
  return (
    <div className={cx(styles.group, className)} {...props}>
      {React.Children.map(children, (child) => (
        <div className={styles.item}>{child}</div>
      ))}
    </div>
  );
};

CommodityTag.Group = Group;

export default CommodityTag;
