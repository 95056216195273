import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import SelectInput, { SelectInputProps } from "../SelectInput/SelectInput";

enum PackagingType {
  Bags = "Bags",
  Bales = "Bales",
  Boxes = "Boxes",
  Bundles = "Bundles",
  Containers = "Containers",
  Crates = "Crates",
  Not = "Not",
  Packaged = "Packaged",
  Other = "Other",
  Rolls = "Rolls",
  Totes = "Totes",
}

export type PackagingValue = `${PackagingType}`;

const defaultOptions = Object.entries(PackagingType).map(([_, value]) => ({
  id: value,
  label: value,
}));

function FormPackagingType<
  ID = PackagingValue,
  T extends {
    id: ID;
    label: string;
  } = {
    id: ID;
    label: string;
  }
>({
  options = (defaultOptions as unknown) as T[],
  value,
  onChange,
  ...props
}: {
  options?: Array<T>;
  value?: ID;
  onChange?: (id: ID, option: T) => void;
} & Omit<SelectInputProps, "value" | "onChange">) {
  return (
    <SelectInput
      fullWidth
      label="Item Type"
      margin="normal"
      {...props}
      value={value ?? ""}
      onChange={(event) => {
        const result = options.find(({ id }) => id === event.target.value)!;
        onChange?.(result.id, result);
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={(option.id as unknown) as string}
          value={(option.id as unknown) as string}
        >
          {option.label}
        </MenuItem>
      ))}
    </SelectInput>
  );
}

export default FormPackagingType;
