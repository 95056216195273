import React, { forwardRef, ReactNode } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormLabel, { FormLabelProps } from "@material-ui/core/FormLabel";
import OutlinedInput, {
  OutlinedInputProps,
} from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment, {
  InputAdornmentProps,
} from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";

import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import Search from "@material-ui/icons/Search";
import { useTextInputStyles } from "./TextInput.styles";

const useStyles = makeStyles(
  ({ palette }) => {
    return {
      clear: {
        padding: 8,
        borderRadius: 4,
        marginRight: -8,
        color: palette.text.secondary,
        "&:hover, &:focus": {
          color: palette.text.primary,
          backgroundColor: palette.action.hover,
        },
      },
    };
  },
  { name: "TextInput" }
);

type PickedOutlinedInputProps =
  | "inputProps"
  | "defaultValue"
  | "value"
  | "onChange"
  | "onFocus"
  | "onBlur"
  | "multiline"
  | "rows"
  | "rowsMax"
  | "rowsMin"
  | "type"
  | "inputRef"
  | "placeholder"
  | "autoComplete"
  | "autoFocus";

export type TextInputProps = {
  label?: ReactNode;
  subLabel?: ReactNode;
  search?: boolean;
  highlight?: boolean;
  onClear?: () => void;
  InputProps?: Omit<OutlinedInputProps, PickedOutlinedInputProps>;
  InputLabelProps?: FormLabelProps;
  InputAdornmentProps?: InputAdornmentProps;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  helperText?: string;
  large?: boolean;
  horizontal?: boolean;
} & Omit<
  FormControlProps,
  "hiddenLabel" | "size" | "variant" | "onChange" | "onFocus" | "onBlur"
> &
  Pick<OutlinedInputProps, PickedOutlinedInputProps>;
const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  (
    {
      id,
      label,
      subLabel,
      search,
      highlight,
      large,
      horizontal,
      onClear,
      InputProps,
      InputLabelProps,
      InputAdornmentProps,
      inputProps,
      autoFocus,
      autoComplete,
      placeholder,
      inputRef,
      defaultValue,
      value,
      onChange,
      onFocus,
      onBlur,
      multiline,
      rows,
      rowsMax,
      rowsMin,
      type,
      className,
      startIcon,
      endIcon,
      helperText,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const textInput = useTextInputStyles();
    const shouldShowStartIcon = (search && startIcon !== null) || !!startIcon;
    return (
      <FormControl
        ref={ref}
        className={cx(horizontal && "Por-horizontal", className)}
        {...props}
      >
        {label && (
          <FormLabel
            htmlFor={id}
            {...InputLabelProps}
            className={cx(textInput.formLabel, InputLabelProps?.className)}
          >
            {label}
          </FormLabel>
        )}
        {subLabel &&
          (typeof subLabel === "string" ? (
            <Typography gutterBottom>{subLabel}</Typography>
          ) : (
            subLabel
          ))}
        <OutlinedInput
          {...InputProps}
          {...{
            id,
            defaultValue,
            value,
            inputRef,
            onChange,
            onBlur,
            onFocus,
            autoFocus,
            autoComplete,
            placeholder,
            multiline,
            rows,
            rowsMin,
            rowsMax,
            type,
            inputProps,
          }}
          classes={{
            ...InputProps?.classes,
            root: cx(
              textInput.outlinedRoot,
              highlight && textInput.highlight,
              search && textInput.search,
              InputProps?.classes?.root
            ),
            inputAdornedStart: cx(
              textInput.inputAdornedStart,
              InputProps?.classes?.inputAdornedStart
            ),
            adornedEnd: cx(
              textInput.outlinedAdornEnd,
              InputProps?.classes?.adornedEnd
            ),
            input: cx(
              textInput.outlinedInput,
              large && textInput.outlinedInputLarge,
              InputProps?.classes?.input
            ),
            notchedOutline: cx(
              textInput.notchedOutline,
              InputProps?.classes?.input
            ),
          }}
          {...(shouldShowStartIcon && {
            startAdornment: (
              <InputAdornment
                {...InputAdornmentProps}
                classes={{
                  ...InputAdornmentProps?.classes,
                  positionStart: cx(
                    textInput.adornmentStart,
                    InputAdornmentProps?.classes?.positionStart
                  ),
                }}
                position="start"
              >
                {search && !startIcon ? <Search color="disabled" /> : startIcon}
              </InputAdornment>
            ),
          })}
          {...(search && {
            endAdornment: (
              <IconButton
                aria-label={`clear ${label} input`}
                className={cx(classes.clear)}
                onClick={onClear}
              >
                <BackspaceOutlined fontSize="small" />
              </IconButton>
            ),
          })}
          {...(endIcon && {
            endAdornment: (
              <InputAdornment
                {...InputAdornmentProps}
                classes={{
                  ...InputAdornmentProps?.classes,
                  positionEnd: cx(
                    textInput.adornmentEnd,
                    InputAdornmentProps?.classes?.positionEnd
                  ),
                }}
                position="end"
              >
                {endIcon}
              </InputAdornment>
            ),
          })}
        />
        {helperText && (
          <FormHelperText error={props.error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
TextInput.displayName = "TextInput";

export default TextInput;
