import React, { PropsWithChildren } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "@material-ui/core/utils";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    gapNone: {
      gap: 0,
    },
    gapXs: {
      gap: `${2 / 16}rem`,
    },
    gapSm: {
      gap: `${4 / 16}rem`,
    },
    gapBase: {
      gap: `${8 / 16}rem`,
    },
    gapMd: {
      gap: `${12 / 16}rem`,
    },
    gapLg: {
      gap: `1rem`,
    },
    gapXl: {
      gap: `1.5rem`,
    },
    stacked: (props: StackyProps) => {
      let value = props.breakpoint;
      if (typeof props.breakpoint === "number") {
        value = `${props.breakpoint}px`;
      }
      if (breakpoints.keys.includes(props.breakpoint as Breakpoint)) {
        value = `${breakpoints.values[props.breakpoint as Breakpoint]}px`;
      }
      return {
        "& > *": {
          flexGrow: 1,
          flexBasis: `calc((${value} - 100%) * 999)`,
        },
      };
    },
  }),
  { name: "MuiStacky" }
);

export type StackyClassKey = keyof ReturnType<typeof useStyles>;
export type StackyClasses = Partial<Record<StackyClassKey, string>>;

export type StackyProps = {
  /**
   * the width of the container that its children become stack.
   */
  breakpoint: string | number;

  /**
   * flex-gap of the container (spacing around children)
   */
  gap?: "none" | "xs" | "sm" | "base" | "md" | "lg" | "xl";

  classes?: StackyClasses;
} & JSX.IntrinsicElements["div"];

const Stacky = ({
  children,
  gap = "base",
  breakpoint,
  classes,
  ...props
}: PropsWithChildren<StackyProps>) => {
  const styles = useStyles({ breakpoint, classes });
  return (
    <div
      className={cx(
        styles.root,
        styles[`gap${capitalize(gap)}` as StackyClassKey],
        styles.stacked
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Stacky;
