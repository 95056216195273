import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.327}
      height={20.177}
      viewBox="0 0 18.327 20.177"
      {...props}
    >
      <g
        data-name="Icon feather-box"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          data-name="Path 2669"
          d="M17.327 13.713V6.465a1.812 1.812 0 00-.907-1.568l-6.349-3.624a1.816 1.816 0 00-1.814 0l-6.35 3.624A1.812 1.812 0 001 6.465v7.249a1.812 1.812 0 00.907 1.568l6.349 3.624a1.816 1.816 0 001.814 0l6.349-3.624a1.812 1.812 0 00.907-1.568z"
        />
        <path data-name="Path 2670" d="M1.309 5.131l7.854 4.96 7.854-4.958" />
        <path
          data-name="Path 2671"
          d="M9.164 19.242V9.354"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
