import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { grey, portexColor } from "../theme/color";
import { CustomColor } from "../constant";

export type IconProps = {
  palette?: CustomColor;
  variant?: "filled" | "soft" | "convex" | "contained";
  fontSize?: string | number;
  wrapperSize?: "tiny" | "small" | "big" | "large" | "huge";
} & Omit<SvgIconProps, "children" | "fontSize">;

const useStyles = makeStyles(
  {
    root: (props: IconProps) => ({
      color: ["convex", "filled"].includes(props.variant!)
        ? "#fff"
        : (portexColor as any)[`${props.palette}500`] ?? "",
    }),
    wrapper: {
      flexShrink: 0,
      borderRadius: "2.75rem",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      "&$tiny": {
        width: "18px",
        height: "18px",
        "& > svg": {
          fontSize: "1rem",
        },
      },
      "&$small": {
        width: "2rem",
        height: "2rem",
        "& > svg": {
          fontSize: "1.25rem",
        },
      },
      "&$big": {
        width: "3rem",
        height: "3rem",
        "& > svg": {
          fontSize: "1.5rem",
        },
      },
      "&$large": {
        width: "3.75rem",
        height: "3.75rem",
        "& > svg": {
          fontSize: "2.25rem",
        },
      },
      "&$huge": {
        width: "4.5rem",
        height: "4.5rem",
        "& > svg": {
          fontSize: "3rem",
        },
      },
    },
    filled: (props: IconProps) => ({
      backgroundColor: (portexColor as any)[`${props.palette}500`] ?? "",
      width: "2.5rem",
      height: "2.5rem",
    }),
    contained: (props: IconProps) => ({
      backgroundColor: (portexColor as any)[`${props.palette}500`] ?? "",
      borderRadius: 4,
      color: "#fff",
      padding: "0.25em",
      width: "1.5em",
      height: "1.5em",
    }),
    soft: {
      marginRight: 4,
      marginLeft: -8,
      backgroundColor: grey["100"],
      color: grey["500"],
      borderRadius: 4,
    },
    convex: (props: IconProps) => ({
      backgroundColor: (portexColor as any)[`${props.palette}500`] ?? "",
      boxShadow: "0 3px 6px #00000029",
      border: "4px solid",
      width: "2.75rem",
      height: "2.75rem",
    }),
    tiny: {},
    small: {},
    big: {},
    large: {},
    huge: {},
  },
  { name: "MuiIcon" }
);

export const Icon: React.FunctionComponent<
  IconProps & { as: typeof SvgIcon }
> = React.forwardRef(
  (
    {
      as: Tag,
      variant,
      style,
      wrapperSize,
      ...props
    }: IconProps & { as: typeof SvgIcon },
    ref
  ) => {
    const classes = useStyles({ variant, ...props });
    if (variant === "filled") {
      return (
        <div
          className={cx(
            classes.wrapper,
            classes.filled,
            classes[wrapperSize!],
            props.className
          )}
          style={style}
          // @ts-ignore
          ref={ref}
        >
          {/* @ts-ignore */}
          <Tag {...props} className={classes.root} />
        </div>
      );
    }
    if (variant === "convex") {
      return (
        <div
          className={cx(
            classes.wrapper,
            classes.convex,
            classes[wrapperSize!],
            props.className
          )}
          style={style}
          // @ts-ignore
          ref={ref}
        >
          {/* @ts-ignore */}
          <Tag {...props} className={classes.root} />
        </div>
      );
    }
    return (
      // @ts-ignore
      <Tag
        ref={ref}
        {...props}
        style={style}
        className={cx(
          classes.root,
          variant && classes[variant],
          props.className
        )}
      />
    );
  }
);

export default Icon;
