import React from "react";
import cx from "clsx";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ edgeGutter }: { edgeGutter?: string | number }) => ({
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: `repeat(auto-fit, minmax(0px, 1fr))`,
    position: "relative",
    paddingLeft: edgeGutter,
    paddingRight: edgeGutter,
    marginTop: "2rem",
    "& > .JourneyItem-root:last-child > .JourneyItem-dot": {
      borderRadius: 0,
    },
  }),
  item: {
    position: "relative",
  },
  line: {
    position: "absolute",
    top: 3,
    width: "100%",
    height: 2,
    backgroundColor: theme.palette.grey[300],
    zIndex: 0,
  },
  head: {
    position: "absolute",
    top: "-0.5rem",
    transform: "translateY(-100%)",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  dot: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.grey[900],
    borderRadius: "50%",
  },
  content: {
    margin: "0.5rem 0",
    fontWeight: "bold",
    lineHeight: 1.75,
  },
}));

export type JourneyItemProps = {
  head?: React.ReactNode;
} & JSX.IntrinsicElements["div"];

const JourneyItem = ({
  children,
  className,
  head,
  ...props
}: React.PropsWithChildren<JourneyItemProps>) => {
  const classes = useStyles({});
  return (
    <div {...props} className={cx("JourneyItem-root", classes.item, className)}>
      {head && (
        <Typography component="div" className={classes.head}>
          {head}
        </Typography>
      )}
      <div className={cx("JourneyItem-dot", classes.dot)} />
      <Typography className={classes.content} component="div">
        {children}
      </Typography>
    </div>
  );
};

export type JourneyProps = {
  children: React.ReactNodeArray;
  edgeGutter?: number | string;
} & JSX.IntrinsicElements["div"];

const Journey = ({
  children,
  className,
  edgeGutter = "2rem",
  ...props
}: JourneyProps) => {
  const classes = useStyles({ edgeGutter });

  return (
    <div {...props} className={cx(classes.root, className)}>
      <div className={classes.line} />
      {children}
    </div>
  );
};

Journey.Item = JourneyItem;

export default Journey;
