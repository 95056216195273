import React, { useRef, useState } from "react";

import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";

import Dropdown, { DropdownRef } from "../Dropdown/Dropdown";

const useStyles = makeStyles(
  ({ palette }) => ({
    dropdown: {
      boxShadow: "none",
      paddingTop: 9,
      paddingBottom: 8,
      minWidth: "auto",
      whiteSpace: "nowrap",
      "& p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "&$highlight": {
        borderColor: palette.error.main,
        borderWidth: 2,
      },
    },
    disabled: {
      pointerEvents: "none",
      backgroundColor: "#e2e6e9",
    },
    empty: {
      "& .MuiTypography-root": {
        color: palette.text.secondary,
      },
    },
    checkbox: {
      marginRight: 8,
    },
    form: {
      display: "flex",
      margin: -4,
      padding: "0.25rem 1rem",
    },
    error: {},
    highlight: {},
    input: {
      flex: 1,
      alignSelf: "stretch",
      height: "auto",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      minWidth: 64,
      borderRadius: 4,
      border: "1px solid",
      borderColor: palette.grey["300"],
      "&:hover": {
        borderColor: palette.grey["500"],
      },
      "&.Mui-focused": {
        borderColor: palette.primary.main,
      },
      "&$error": {
        borderColor: palette.error.main,
      },
      "& .MuiSelect-root": {
        paddingRight: 12,
      },
    },
    gutter: {
      width: 8,
      flexShrink: 0,
    },
    separator: {
      alignSelf: "center",
    },
    placeholder: {
      pointerEvents: "none",
      opacity: 0.5,
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
      color: palette.text.primary,
    },
  }),
  { name: "TimeRange" }
);

export type TimeRangeProps<T = unknown> = {
  /**
   * If true, the input is disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * If true, the input is highlight
   * @default false
   */
  highlight?: boolean;
  /**
   * If true, the dropdown disappeared after clicking on the button
   * @default false
   */
  closedAfterSubmit?: boolean;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  placeholders?: { from?: string; to?: string };
  children: React.ReactNode;
  value: { from?: T; to?: T };
  onChange?: (value: { from?: T; to?: T }) => void;
  onSubmit: (value: { from: T; to: T }) => void;
};

const isValid = (value: any) =>
  value !== undefined && value !== null && value !== "";

const TimeRange = <T,>({
  disabled = false,
  highlight = false,
  closedAfterSubmit = false,
  className,
  style,
  placeholder = "Set range",
  placeholders = {},
  children,
  value,
  onChange,
  onSubmit,
}: TimeRangeProps<T>) => {
  const styles = useStyles();
  const [inputError, setInputError] = useState<null | "from" | "to" | "both">(
    null
  );
  const [internal, setInternal] = useState(value);
  const dropdownRef = useRef<DropdownRef>();
  return (
    <Dropdown
      className={cx(
        styles.dropdown,
        {
          [styles.empty]: !value.from && !value.to,
          [styles.disabled]: disabled,
          [styles.highlight]: highlight,
        },
        className
      )}
      style={style}
      dropdownRef={dropdownRef}
      placeholder={placeholder}
      onOpen={() => setInternal(value)}
      anchorOrigin={{
        horizontal: "right",
      }}
      transformOrigin={{
        horizontal: "right",
      }}
      onClose={() => {
        if (isValid(internal.from) && isValid(internal.to)) {
          onSubmit({ from: internal.from!, to: internal.to! });
        }
      }}
    >
      <div className={styles.form}>
        <Select
          value={internal.from ?? ""}
          onChange={(event) => {
            onChange?.({ ...internal, from: event.target.value as T });
            setInputError((current) => {
              if (current === "from") return null;
              if (current === "both") return "to";
              return null;
            });
            setInternal((current) => ({
              ...current,
              from: event.target.value as T,
            }));
          }}
          input={
            <InputBase
              className={cx(styles.input, {
                [styles.error]: inputError === "from" || inputError === "both",
              })}
              {...(placeholders.from &&
                !internal.from && {
                  startAdornment: (
                    <div className={styles.placeholder}>
                      {placeholders.from}
                    </div>
                  ),
                })}
            />
          }
        >
          {children}
        </Select>
        <div className={styles.gutter} />
        <Typography className={styles.separator} color="textSecondary">
          to
        </Typography>
        <div className={styles.gutter} />
        <Select
          value={internal.to ?? ""}
          onChange={(event) => {
            onChange?.({ ...internal, from: event.target.value as T });
            setInputError((current) => {
              if (current === "to") return null;
              if (current === "both") return "from";
              return null;
            });
            setInternal((current) => ({
              ...current,
              to: event.target.value as T,
            }));
          }}
          input={
            <InputBase
              className={cx(styles.input, {
                [styles.error]: inputError === "to" || inputError === "both",
              })}
              {...(placeholders.to &&
                !internal.to && {
                  startAdornment: (
                    <div className={styles.placeholder}>{placeholders.to}</div>
                  ),
                })}
            />
          }
        >
          {children}
        </Select>
        <div className={styles.gutter} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (!isValid(internal.from) && !isValid(internal.to)) {
              setInputError("both");
            } else if (!isValid(internal.from)) {
              setInputError("from");
            } else if (!isValid(internal.to)) {
              setInputError("to");
            } else {
              onSubmit({ from: internal.from!, to: internal.to! });
              if (dropdownRef?.current && closedAfterSubmit) {
                dropdownRef.current.close();
              }
            }
          }}
        >
          Set
        </Button>
      </div>
    </Dropdown>
  );
};

export default TimeRange;
