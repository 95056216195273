import * as React from "react";

function SvgComponent({
  color = "#8021fa",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      {...props}
    >
      <g transform="translate(.012)">
        <circle
          data-name="Ellipse 92"
          cx={14}
          cy={14}
          r={14}
          transform="translate(-.012)"
          fill={color}
        />
        <g fill="#fff">
          <path
            data-name="Path 2549"
            d="M7.958 15.656a1.334 1.334 0 101.333 1.333 1.334 1.334 0 00-1.333-1.333z"
          />
          <path
            data-name="Path 2550"
            d="M11.947 15.656a1.334 1.334 0 101.333 1.333 1.334 1.334 0 00-1.333-1.333z"
          />
          <path
            data-name="Path 2551"
            d="M19.38 15.656a1.334 1.334 0 101.337 1.333 1.334 1.334 0 00-1.337-1.333z"
          />
          <path
            data-name="Subtraction 1"
            d="M17.391 16.358h-3.459a2.083 2.083 0 00-3.972 0h-.014a2.086 2.086 0 00-3.977 0h-.678v-1.982h11.457v-4.7h3.269l1.334 2.67 1.334.76v3.248h-1.319a2.083 2.083 0 00-3.972 0z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
