import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16.557 11.588"
      fontSize="1.5rem"
      color="#fff"
      {...props}
    >
      <path
        d="M.009 4.964h9.929V0h3.31c1.289 1.289 2.021 2.012 3.31 3.31v5.794h-.83a2.485 2.485 0 11-4.969 0H7.444a2.485 2.485 0 11-4.969 0H0v-4.14zm14.893-.975l-2.343-2.334h-.966v3.31h3.31zm-1.655 5.944a.83.83 0 10-.83-.83.831.831 0 00.83.83zm-8.279 0a.83.83 0 10-.83-.83.831.831 0 00.831.83z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
