import React, { ReactElement } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      border: "1px solid",
      borderColor: palette.grey[100],
      backgroundColor: palette.background.paper,
      borderRadius: 4,
      padding: "0.5rem 1rem",
      fontWeight: "bold",
      textAlign: "center",
      display: "inline-block",
    },
    label: {
      textTransform: "uppercase",
      fontSize: 12,
      fontWeight: "bold",
      letterSpacing: "0.6px",
    },
    amount: {
      fontWeight: "bold",
    },
    expiration: {
      color: palette.grey[500],
      fontSize: 12,
      fontWeight: "bold",
    },
    fullWidth: {
      width: "100%",
    },
  }),
  { name: "QuoteLabel" }
);

export type QuoteLabelClassKey = keyof ReturnType<typeof useStyles>;
export type QutoeLabelClasses = Partial<Record<QuoteLabelClassKey, string>>;

export type QuoteLabelProps = {
  amount: string;
  label?: string | ReactElement;
  expiration?: string;
  fullWidth?: boolean;
  classes?: QutoeLabelClasses;
} & JSX.IntrinsicElements["div"];

const QuoteLabel = ({
  label = "Quote Amount",
  amount,
  expiration,
  fullWidth,
  className,
  classes,
  ...props
}: QuoteLabelProps) => {
  const styles = useStyles({ classes });
  return (
    <div
      className={cx(styles.root, fullWidth && styles.fullWidth, className)}
      {...props}
    >
      <Typography className={styles.label}>{label}</Typography>
      <Typography variant="h6" className={styles.amount}>
        {amount}
      </Typography>
      <Typography className={styles.expiration}>{expiration}</Typography>
    </div>
  );
};

export default QuoteLabel;
