import React from "react";
import cx from "clsx";
import ButtonBase, { ButtonBaseTypeMap } from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  SvgPlaneCircleFilled,
  SvgShipCircleFilled,
  SvgTruckCircleFilled,
  SvgTruckLTLGreen,
} from "../PortexIcons";
import { useButtonTileStyles } from "../ButtonTile/ButtonTile";
import { portexColor } from "../theme/color";
import { OverridableComponent } from "../types";

export type ModeTransportProps = {
  mode: "trucking" | "truckingLTL" | "ocean" | "air";
  label?: string | null;
  fullWidth?: boolean;
  enableComingSoon?: boolean;
  iconProps?: Record<string, any>;
  /**
   * @description applies value as equal height/width in px for the <Icon />
   */
  iconSize?: number;
} & Omit<ButtonBaseTypeMap["props"], "tabIndex">;

const IconMap: Record<ModeTransportProps["mode"], React.ElementType> = {
  trucking: SvgTruckCircleFilled,
  truckingLTL: SvgTruckLTLGreen,
  ocean: SvgShipCircleFilled,
  air: SvgPlaneCircleFilled,
};

const labelMap: Record<ModeTransportProps["mode"], React.ReactNode> = {
  trucking: "Trucking - FTL",
  truckingLTL: "Trucking - LTL",
  ocean: "Ocean",
  air: "Air",
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    "&:hover": {
      "& $comingSoon": {
        opacity: 0.8,
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  trucking: {
    borderColor: portexColor.green500,
    color: portexColor.green500,
    "&:hover": {
      backgroundColor: portexColor.green100,
    },
  },
  ocean: {
    borderColor: portexColor.blue500,
    color: portexColor.blue500,
    "&:hover": {
      backgroundColor: portexColor.blue100,
    },
  },
  air: {
    borderColor: portexColor.purple500,
    color: portexColor.purple500,
    "&:hover": {
      backgroundColor: portexColor.purple100,
    },
  },
  label: {
    fontWeight: "bold",
    marginTop: -8,
  },
  comingSoon: {
    height: 0,
    fontWeight: "bold",
    fontSize: "0.625rem",
    opacity: 0,
    transition: "0.4s",
  },
}));

const ModeTransport = React.forwardRef(
  (
    {
      mode,
      enableComingSoon,
      label: labelProp,
      fullWidth = false,
      iconSize = 100,
      iconProps = {},
      children,
      ...props
    },
    ref
  ) => {
    const Icon = IconMap[mode];
    const label = labelProp !== undefined ? labelProp : labelMap[mode];
    const styles = useStyles(props);
    const buttonTileStyles = useButtonTileStyles({
      palette: ({
        trucking: "green",
        truckingLTL: "green",
        ocean: "blue",
        air: "purple",
      } as const)[mode],
    });
    return (
      <ButtonBase
        ref={ref as React.MutableRefObject<HTMLButtonElement>}
        className={cx(
          buttonTileStyles.root,
          styles.root,
          {
            [styles.trucking]: mode === "trucking" || mode === "truckingLTL",
            [styles.ocean]: mode === "ocean",
            [styles.air]: mode === "air",
            [styles.fullWidth]: fullWidth,
          },
          props.className
        )}
        {...props}
      >
        <Icon
          {...iconProps}
          style={{
            width: iconSize,
            height: iconSize,
            padding: mode === "truckingLTL" ? "0px 20px" : undefined,
            ...iconProps.style,
          }}
        />
        {label && (
          <Typography
            variant="body2"
            className={styles.label}
            align="center"
            component="div"
          >
            {label}
          </Typography>
        )}
        {children}
        {enableComingSoon && (
          <Typography
            variant="overline"
            className={styles.comingSoon}
            align="center"
            component="div"
            color="inherit"
          >
            Coming Soon
          </Typography>
        )}
      </ButtonBase>
    );
  }
) as OverridableComponent<
  ModeTransportProps,
  JSX.IntrinsicElements["button"],
  HTMLButtonElement
>;

export default ModeTransport;
