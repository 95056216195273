import * as React from "react";

const SvgCheapestQuoteChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={53} height={53} {...props}>
    <g transform="translate(-14573 -8584)">
      <circle
        data-name="Ellipse 259"
        cx={26.5}
        cy={26.5}
        r={26.5}
        transform="translate(14573 8584)"
        fill="#265df1"
      />
      <g data-name="Group 6218">
        <path
          data-name="Icon material-attach-money"
          d="M14600.051 8608.49c-4.164-1.082-5.5-2.2-5.5-3.943 0-2 1.852-3.393 4.952-3.393 3.265 0 4.475 1.559 4.585 3.852h4.052a7.311 7.311 0 0 0-5.888-6.988V8594h-5.5v3.962c-3.558.77-6.42 3.081-6.42 6.621 0 4.237 3.5 6.346 8.621 7.575 4.585 1.1 5.5 2.715 5.5 4.42 0 1.266-.9 3.283-4.952 3.283-3.778 0-5.264-1.687-5.466-3.852H14590c.22 4.017 3.228 6.273 6.75 7.025v3.98h5.5v-3.943c3.577-.679 6.42-2.751 6.42-6.511.002-5.209-4.455-6.988-8.619-8.07Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default SvgCheapestQuoteChecked;
