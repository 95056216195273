import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";

const useStyles = makeStyles(
  ({ palette }) => ({
    block: {
      display: "flex",
      alignItems: "center",
    },
    line: {
      height: 2,
      backgroundColor: palette.grey["300"],
      flexGrow: 1,
      marginLeft: 4,
      marginRight: 4,
      borderRadius: 4,
    },
  }),
  { name: "Place" }
);

export type PlaceProps = {
  connected?: boolean;
};

const Place = ({ connected }: PlaceProps) => {
  const styles = useStyles();
  return (
    <div className={styles.block}>
      <LocationOn />
      {connected && <div className={styles.line} />}
    </div>
  );
};

export default Place;
