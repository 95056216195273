import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19.042}
      height={17.137}
      viewBox="0 0 19.042 17.137"
      {...props}
    >
      <path
        d="M7.617 0h3.808a1.9 1.9 0 011.9 1.9v.952h3.808a1.9 1.9 0 011.9 1.9v10.481a1.9 1.9 0 01-1.9 1.9H1.9a1.9 1.9 0 01-1.9-1.9V4.76a1.9 1.9 0 011.9-1.9h3.812V1.9A1.9 1.9 0 017.617 0zM1.9 4.76h15.237v4.76H1.9zm0 10.473v-3.808h6.665v.952h1.9v-.952h6.665v3.808zM11.425 1.9v.952H7.617V1.9z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
